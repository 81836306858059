import React from 'react';
import { Drawer, DrawerProps } from '@blueprintjs/core';

import { Main } from '@/app/atoms/Main/Main';
import { MainColumn } from '@/app/atoms/MainColumn/MainColumn';
import { OppDetailsSource } from '@/app/organisms/OppDetailsSource/OppDetailsSource';
import { LinkTag } from '@/app/atoms/LinkTag/LinkTag';
import { useDeviceWidth } from '@/app/hooks/useDeviceWidth';

type OppSourceDrawerProps = {
  id: string;
  sourceId: string;
  subject?: string;
} & Partial<DrawerProps>;

export const OppSourceDrawer = ({ id, sourceId, subject, ...rest }: OppSourceDrawerProps) => {
  const { isMobile } = useDeviceWidth();
  const size = isMobile ? '100%' : '70%';
  return (
    <Drawer
      lazy
      size={size}
      isOpen={!!sourceId}
      title={
        <div>
          <LinkTag to={`/opportunities/${id}/sources/${sourceId}`} target="_blank">
            {subject}
          </LinkTag>
        </div>
      }
      className="overflow-y-scroll"
      {...rest}
    >
      <Main>
        <MainColumn columnSpan={12}>
          <OppDetailsSource oppId={id} oppSourceId={sourceId} />
        </MainColumn>
      </Main>
    </Drawer>
  );
};
