import { useState } from 'react';
import { DropTargetMonitor, useDrop } from 'react-dnd';
import { NativeTypes } from 'react-dnd-html5-backend';
import { useGetCurrentUserQuery } from '@/api/currentUserApi';
import { useCreateWorkspaceAttachmentsMutation } from '@/api/workspacesAttachmentsApi';
import { useActiveStorageOnDrop } from '@/app/hooks/useActiveStorageOnDrop';
import { OnAttachArgs } from '@/app/hooks/useOnBulkAttach';

type DropItem = { files: File[] };

export const useUploadWorkspaceAttachments = ({ workspaceId }: { workspaceId: string }) => {
  const [formState, setFormState] = useState<'idle' | 'uploading'>('idle');
  const { data: currentUser } = useGetCurrentUserQuery();
  const [createAttachments, createAttachmentsMeta] = useCreateWorkspaceAttachmentsMutation();

  const isUploading = formState === 'uploading' || createAttachmentsMeta.isLoading;

  const handleBulkAttach = async (attachments: OnAttachArgs[]) => {
    await createAttachments({ id: workspaceId, attachments: attachments.map(a => a.signedId) });
    setFormState('idle');
  };

  const handleInitialize = () => {
    setFormState('uploading');
  };

  const { onDrop } = useActiveStorageOnDrop({
    uploaderId: workspaceId,
    onBulkAttach: handleBulkAttach,
    onInitialize: handleInitialize
  });

  const [{ canDrop, isOver }, ref] = useDrop(
    () => ({
      accept: [NativeTypes.FILE],
      drop(item: DropItem) {
        onDrop(item.files);
      },
      canDrop(_item: DropItem) {
        return !currentUser?.compliancePreventUploads && !isUploading;
      },
      collect: (monitor: DropTargetMonitor<DropItem>) => {
        return { isOver: monitor.isOver(), canDrop: monitor.canDrop() };
      }
    }),
    [currentUser?.compliancePreventUploads, isUploading]
  );

  const isDragActive = canDrop && isOver;

  return {
    isUploading,
    isDragActive,
    ref,
    onDrop
  };
};
