const optimize = (url: string) => `${url}-/preview/
-/quality/smart/
-/format/auto/`;

export const DEFAULT_LOGO_IMAGE = optimize('https://ucarecdn.com/1aecb8ac-d5a1-4d25-9eb4-3f2b53946934/');
export const WHITE_LOGO_IMAGE = optimize('https://ucarecdn.com/8c3a6174-a2b0-4523-8852-feadfda7417b/');
export const BLACK_LOGO_IMAGE = optimize('https://ucarecdn.com/d1471eba-df52-40b9-8f3a-3802421b2a49/');
export const DASHBOARD_IMAGE = optimize('https://ucarecdn.com/88bb6090-1f90-4705-ba93-bcd0124b3323/');
export const WORKLIST_IMAGE = optimize('https://ucarecdn.com/41fa5a17-b415-47f5-9fec-00b92a0b39ba/');
export const SEARCH_FEEDS_IMAGE = optimize('https://ucarecdn.com/60c087cc-d303-48b7-b720-08fc58d438ec/');
export const CONTRACT_MANAGEMENT_IMAGE = optimize('https://ucarecdn.com/3b93b330-75e7-479f-8e23-dafbd4c925c3/');
export const CALENDAR_IMAGE = optimize('https://ucarecdn.com/149ce0fa-7f28-47c2-a77c-ae4f7e129924/');
export const PARTNERSHIPS_IMAGE = optimize('https://ucarecdn.com/39f1a633-8ec9-488a-a3cc-36325213795e/');
export const APPROVE_LOGIN_IMAGE = optimize('https://ucarecdn.com/baf1da70-776e-4bea-a097-e9151f156831/');
// TESTIMONIAL AUTHOR IMAGES
export const JENNIFER_GALLAGHER_IMAGE = optimize('https://ucarecdn.com/89abcd57-c83d-44bc-975e-9d9bef506716/');
export const BRITT_MOWERY_IMAGE = optimize('https://ucarecdn.com/fd741101-2971-4ee6-9bf8-564f564a1b61/');
export const PETE_HOLCOMB_IMAGE = optimize('https://ucarecdn.com/f948c6b1-47f1-4295-a2be-435c606e658b/');
export const SCOTT_WELLES_IMAGES = optimize('https://ucarecdn.com/1a4c3b9b-21ce-4bc2-ae8d-b3da7381218a/');
export const KRYSTAL_KLEIN_IMAGE = optimize('https://ucarecdn.com/dfc8228b-3de2-4b93-9d6c-e5b2debabbd5/');
export const ALAN_JAYNES_IMAGE = optimize('https://ucarecdn.com/3ce1a24a-c50b-4878-818f-41b9f6bbc9a8/');
export const TYLER_MATHIS_IMAGE = optimize('https://ucarecdn.com/36ec5382-e5d8-4a60-9533-ece86056cda0/');
export const AMBER_ROWELL_IMAGE = optimize('https://ucarecdn.com/ab27bc90-5eca-431a-9578-7f54c9e88ca2/');
// TEAM HEADSHOTS
export const MIKE_HEADSHOT = optimize('https://ucarecdn.com/7b1f1f3a-eafc-4f43-bb34-4b1e6e7a7a8e/');
export const NICK_HEADSHOT = optimize('https://ucarecdn.com/a852d91d-15f9-46e0-a714-93c2b40af97f/');
export const JON_HEADSHOT = optimize('https://ucarecdn.com/51288726-cdc7-4bed-9f9d-2ae8a31530af/');
export const RACHEL_HEADSHOT = optimize('https://ucarecdn.com/e6219e65-0058-40e0-ba7b-a4f647552213/');
export const DAVID_HEADSHOT = optimize('https://ucarecdn.com/7adce435-fedd-4987-b95f-e1f79a38a750/');
export const OLIVER_HEADSHOT = optimize('https://ucarecdn.com/0d72ac4f-d94a-4d1f-b3d0-0e1e1914aefb/');
export const TYLER_HEADSHOT = optimize('https://ucarecdn.com/2cc0923d-68bb-4c02-a9ad-227a32b0d9d2/');
export const CARSON_HEADSHOT = optimize('https://ucarecdn.com/dd0b09d0-9ab2-48fa-a057-e02f5c964e66/');
export const RYAN_HEADSHOT = optimize('https://ucarecdn.com/5d0ab0ac-673a-41d3-b214-5ceff0e8e119/');
