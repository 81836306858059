import React from 'react';
import { Icon, Tooltip } from '@blueprintjs/core';

export type LlmGeneratedIndicatorProps = {
  icon: 'generate' | 'predictive-analysis';
};

export const LlmGeneratedIndicator = ({ icon = 'generate' }: LlmGeneratedIndicatorProps) => {
  const content =
    icon === 'generate'
      ? 'This content was generated using a Large Language Model (LLM).'
      : 'This value was predicted using a Large Language Model (LLM).';

  return (
    <Tooltip className="cursor-help" content={content} placement="top">
      <Icon icon={icon} />
    </Tooltip>
  );
};
