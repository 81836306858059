import React, { useState } from 'react';
import { Button, Menu, MenuItem, Popover, IconName } from '@blueprintjs/core';
import { formatTime, ACTIVITY_LOG } from '@/app/lib/dates';
import { AvatarWithName } from '@/app/molecules/AvatarWithName/AvatarWithName';
import { Card, CardBody } from '@/app/atoms/Card/Card';
import { CommentShow, OrganizationTeam } from '@/types/__generated__/GovlyApi';
import { Reactions } from '@/app/molecules/Reactions/Reactions';
import { ReactionsStoreProvider } from '@/app/molecules/Reactions/useReactionsStore';
import { ReactionsButton } from '@/app/molecules/Reactions/ReactionsButton';
import { cn } from '@/app/lib/cn';

type CommentProps = React.PropsWithChildren<{
  comment: Pick<CommentShow, 'id' | 'createdAt' | 'content' | 'organizationUser' | 'meta' | 'guestEmail'>;
  editor: React.ReactElement;
  onRemove: () => void;
  isAuthor?: boolean;
}>;

type CommenterProps = {
  avatarColor?: string;
  name?: string;
  email: string;
  initials?: string;
  avatar?: { thumbUrl: string } | null;
  organizationTeams?: OrganizationTeam[];
  icon?: IconName;
};

const DELETED_ORGANIZATION_USER = {
  avatarColor: '#293742',
  name: 'User Removed',
  email: '[user removed]',
  icon: 'person' as IconName
};

const getCommenter = (
  comment: Pick<CommentShow, 'id' | 'createdAt' | 'content' | 'organizationUser' | 'meta' | 'guestEmail'>
): CommenterProps => {
  if (comment.organizationUser) {
    return {
      avatarColor: comment.organizationUser.avatarColor,
      name: comment.organizationUser.name,
      email: comment.organizationUser.email,
      initials: comment.organizationUser.initials,
      avatar: comment.organizationUser.avatar,
      organizationTeams: comment.organizationUser.organizationTeams
    };
  }

  if (comment.guestEmail) {
    return {
      avatarColor: '#293742',
      name: comment.guestEmail,
      email: comment.guestEmail,
      icon: 'envelope' as IconName
    };
  }

  return DELETED_ORGANIZATION_USER;
};

export const Comment = ({ comment, editor, onRemove, isAuthor, children }: CommentProps) => {
  const [isEditing, setEditing] = useState(false);
  const [deleted, setDeleted] = useState(false);

  const { id: commentId } = comment;
  const { avatarColor, avatar, name, email, initials, icon, organizationTeams } = getCommenter(comment);

  return (
    <Card className={cn('relative', deleted ? 'hidden' : '')}>
      <ReactionsStoreProvider commentId={comment.id}>
        <CardBody data-test="comment">
          <div className="flex justify-between">
            <AvatarWithName
              initials={initials}
              imgSrc={avatar?.thumbUrl}
              avatarColor={avatarColor}
              id={commentId}
              name={name}
              email={email}
              teams={organizationTeams}
              subtext={formatTime(comment.createdAt, ACTIVITY_LOG)}
              icon={icon as IconName | undefined}
            />

            <div className="flex items-center gap-x-1">
              <ReactionsButton />
              {isAuthor && (
                <Popover
                  content={
                    <Menu key="menu" large>
                      <MenuItem
                        icon="edit"
                        text="Edit"
                        onClick={() => setEditing(true)}
                        data-test="edit-comment-button"
                      />
                      <MenuItem
                        icon="trash"
                        text="Delete"
                        onClick={() => {
                          setDeleted(true);
                          onRemove();
                        }}
                        data-test="delete-comment-button"
                      />
                    </Menu>
                  }
                >
                  <Button minimal icon="more" data-test="comment-menu-button" />
                </Popover>
              )}
            </div>
          </div>
          <div className="space-y-2">
            {isEditing ? (
              React.cloneElement(editor, { afterSubmit: () => setEditing(false) })
            ) : (
              <div className="prose prose-sm">
                {comment.meta?.deliveryMessage && <small>{comment.meta?.deliveryMessage}</small>}
                <div dangerouslySetInnerHTML={{ __html: comment.content ?? '' }} />
              </div>
            )}
            {children}

            <Reactions />
          </div>
        </CardBody>
      </ReactionsStoreProvider>
    </Card>
  );
};
