import { TrixEditor } from 'trix';
import { isTrixEditorEvent } from '@/app/lib/trix';

const isValidURL = (url: string) => {
  const input = document.createElement('input');
  input.type = 'url';
  input.required = true;
  input.value = url;
  return input.checkValidity();
};

const getHrefAtRange = (editor: TrixEditor, range: number[]) => {
  return editor.getDocument().getCommonAttributesAtRange(range).href;
};

const getURLsWithRanges = (editor: TrixEditor) => {
  const pattern = new RegExp(`(https?://\\S+\\.\\S+)\\s`, 'ig');
  let match;
  const results = [];
  const string = editor.getDocument().toString();
  while ((match = pattern.exec(string))) {
    const url = match[1];
    if (isValidURL(url)) {
      const position = match.index;
      const range = [position, position + url.length];
      results.push({ url, range });
    }
  }
  return results;
};

const autoLink = (event: Event) => {
  if (!isTrixEditorEvent(event)) {
    return;
  }

  const editor = event.target.editor;
  const results = [];
  for (const { url, range } of Array.from(getURLsWithRanges(editor))) {
    if (getHrefAtRange(editor, range) !== url) {
      const currentRange = editor.getSelectedRange();
      editor.setSelectedRange(range);
      if (editor.canActivateAttribute('href')) {
        editor.activateAttribute('href', url);
      }
      results.push(editor.setSelectedRange(currentRange));
    }
  }
};

export const trixAutoLink = (event: Event) => {
  if (!isTrixEditorEvent(event)) {
    return;
  }

  event.target.addEventListener('trix-render', (e: Event) => autoLink(e));
};
