import React from 'react';
import { useParams, useSearchParams } from 'react-router';
import type { DrawerNavTab, NavItem } from '@/app/organisms/AuthenticatedNav/AuthenticatedNavItemsUtils';
import { useGetOppQuery } from '@/api/oppsApi';
import { DRAWER_PARAMS } from '@/app/organisms/AppDrawer/constants';

export type OppAttachmentsTabProps = {
  node: NavItem | DrawerNavTab;
  index: number;
};

export const OppHistoryTagContent = (_: OppAttachmentsTabProps) => {
  const params = useParams();
  const [searchParams] = useSearchParams();
  const id = params.id ?? searchParams.get(DRAWER_PARAMS.id) ?? '';
  const { data: { oppSources } = {} } = useGetOppQuery({ id });
  const count = oppSources?.length ?? 0;
  return count > 0 ? <span>{count}</span> : null;
};
