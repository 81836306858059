import { OrganizationUserCurrentUser } from '@/types/__generated__/GovlyApi';

type WelcomeMessageArgs = {
  user?: OrganizationUserCurrentUser;
};

const standardWelcomeMessage = (user: WelcomeMessageArgs['user']) => () => {
  return `Hi ${user?.firstName || 'there'}! I'm here to help you with the opportunity linked to this Workspace. Think of me as your assistant for streamlining tasks and helping you work more efficiently. You can ask me to assist you with things like summarizing the opportunity or attachments, list out the products in this opportunity, or help with building a quote. Let me know what you need to get started.`;
};

// TODO get better UX for switching between threads
// const refreshWelcomeMessage = (user: WelcomeMessageArgs['user']) => {
//   let callNum = 0;

//   return () => {
//     const evenCall = `Hi ${user?.firstName || 'there'}! I'm your workspace assistant, ready to help you manage this opportunity. Need help with tasks, summaries, or quotes?`;
//     const oddCall = `Hi ${user?.firstName || 'there'}! How can I help you with this workspace today? I can assist with opportunity details, products, or quote building.`;
//     const message = callNum % 2 === 0 ? evenCall : oddCall;
//     callNum++;
//     return message;
//   };
// };

export const makeWelcomeMessageFactory = (user: WelcomeMessageArgs['user']) => ({
  standard: standardWelcomeMessage(user),
  refresh: standardWelcomeMessage(user)
});
