import React from 'react';
import { Tag } from '@blueprintjs/core';

import { hasComments, hasReactions, hasMentions, mentionDecorator } from '@/app/lib/activityMessageDecorator';
import { InfoTooltip } from '@/app/molecules/InfoTooltip/InfoTooltip';
import { useGetCurrentUserQuery } from '@/api/currentUserApi';
import { formatTime, DATETIME_24_SHORT } from '@/app/lib/dates';
import { Activity } from '@/types/__generated__/GovlyApi';

type OppWorkspaceUpdateTagsProps = {
  updates: (Activity & { customData?: { isNew?: boolean } })[];
};

export const OppWorkspaceUpdateTags = ({ updates }: OppWorkspaceUpdateTagsProps) => {
  const { data: { email = '' } = {} } = useGetCurrentUserQuery();

  const messages = updates.map(
    update =>
      `${update.createdAt ? `${formatTime(update.createdAt, DATETIME_24_SHORT)}: ` : ''}${mentionDecorator(
        update,
        email
      )}`
  );

  const tooltipUpdates = messages.map((message, i) => <blockquote key={i}>{message}</blockquote>);
  const updateTagContent = updates?.some(update => update?.customData?.isNew) ? 'New' : 'Updated';

  const updateTag = () => <Tag intent="success">{updateTagContent}</Tag>;

  const additionalTag = () => {
    if (hasMentions(updates, email)) {
      return <Tag intent="danger">New Mention</Tag>;
    } else if (hasComments(updates)) {
      return <Tag intent="warning">New Comment</Tag>;
    } else if (hasReactions(updates)) {
      return <Tag intent="warning">New Reaction</Tag>;
    } else {
      return null;
    }
  };

  const updateTags = () => (
    <div className="flex flex-wrap gap-1">
      {updateTag()}
      {additionalTag()}{' '}
    </div>
  );

  return (
    <InfoTooltip target={updateTags()} shouldTrack trackedEventName={`Worklist ${updateTagContent}`}>
      {updateTags()}
      {tooltipUpdates}
    </InfoTooltip>
  );
};
