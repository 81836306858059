import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router';
import { Dialog } from '@blueprintjs/core';

import { SurveyForm, Survey } from '@/app/organisms/SurveyForm/SurveyForm';
import { successToast, errorToast } from '@/app/lib/toaster';
import { trackError } from '@/app/lib/errorHelpers';

const SURVEY_IDS = {
  'foia-request': {
    development: '018f72c6-c648-0000-ac41-578cd1226d16',
    staging: '018f781c-45ae-0000-74dc-860fdac9c1c0',
    production: '018f73cf-3bdb-0000-1f4a-fa164e5aac6e'
  },
  'contract-vehicle-interest': {
    development: '018d6c18-9765-0000-2b45-bd49186bdcf4',
    staging: '018d8114-c123-0000-e30f-9203e725d971',
    production: '018d8116-8ec6-0000-714f-e7555b4dfd44'
  }
};

export type SurveyModalProps = {
  type: string;
  afterSubmit?: () => void;
  afterClose?: () => void;
  surveyResponsePrefix?: string;
};

export const SurveyModal = ({ type, afterSubmit, afterClose, surveyResponsePrefix }: SurveyModalProps) => {
  const [_searchParams, setSearchParams] = useSearchParams();
  const posthog = window.posthog;
  const [survey, setSurvey] = useState<Survey | null>(null);
  const [isShowing, setIsShowing] = useState(true);

  useEffect(() => {
    posthog?.getSurveys(surveys => {
      const activeSurvey = surveys.find(survey =>
        Object.values(SURVEY_IDS[type as keyof typeof SURVEY_IDS]).includes(survey.id)
      );
      if (activeSurvey) {
        setSurvey(activeSurvey);
        posthog?.capture('survey shown', { $survey_id: activeSurvey.id });
      } else {
        errorToast('Oops! Something went wrong.');
        trackError(new Error(`Unable to load survey for ${type}`), { action: 'SurveyModal' });
        setSearchParams({});
      }
    });
  }, [posthog, type, setSearchParams]);

  const handleClose = () => {
    setIsShowing(false);
    if (afterClose) setTimeout(afterClose, 100); // allow modal close smoothly before clearing parent state
  };

  if (!type || !survey) return null;

  const { appearance } = survey;
  return (
    <Dialog isOpen={isShowing} onClose={handleClose}>
      <SurveyForm
        survey={survey}
        handleSubmit={({ $survey_response, ...values }) => {
          $survey_response = surveyResponsePrefix ? `<<${surveyResponsePrefix}>>${$survey_response}` : $survey_response;
          posthog?.capture('survey sent', { $survey_id: survey?.id, $survey_response, ...values });
          successToast(
            `${appearance?.thankYouMessageHeader} ${appearance?.thankYouMessageDescription ? appearance.thankYouMessageDescription : ''}`.trim()
          );
          setIsShowing(false);
          if (afterSubmit) afterSubmit();
          handleClose();
        }}
        handleCancel={() => {
          posthog?.capture('survey dismissed', { $survey_id: survey?.id });
          handleClose();
        }}
      />
    </Dialog>
  );
};
