import React, { useRef, useState } from 'react';
import { Button, ButtonProps } from '@blueprintjs/core';

import { cn } from '@/app/lib/cn';
import { useEffectOnce } from '@/app/hooks/useEffectOnce';

export type ExpanderProps = {
  children?: React.ReactNode;
  className: string;
  minHeight: number;

  expandLabel?: string;
  collapseLabel?: string;

  expandButtonProps?: ButtonProps;
};

/// measures it's children's height, and then collapses to a minHeight
/// allowing expanding back to the measuredHeight with a nice animation
///
/// starts with height=auto
/// measures the expander's height in an effect
/// sets the min if bigger, otherwise doesn't do anything

export const Expander = (props: ExpanderProps) => {
  const { children, className, minHeight } = props;

  const expanderRef = useRef<HTMLDivElement>(null);
  const [height, setHeight] = useState<number | 'auto'>('auto');
  const [measuredHeight, setMeasuredHeight] = useState<number | null>(null);

  const showExpander = height !== 'auto';
  const expandLabel = height === minHeight ? props.expandLabel || 'Show More' : props.collapseLabel || 'Show Less';

  let expandButtonProps = props.expandButtonProps;
  expandButtonProps = expandButtonProps ?? {
    small: true,
    outlined: true,
    intent: 'primary'
  };

  useEffectOnce(() => {
    if (expanderRef.current) {
      const expanderHeight = expanderRef.current.getBoundingClientRect().height;
      setMeasuredHeight(expanderHeight);

      // if we're expandable, collapse by default on load
      if (expanderHeight > minHeight) {
        setHeight(minHeight);
      }
    }
  });

  const onClick = () => {
    if (measuredHeight === null) {
      return; // we somehow haven't measured yet
    }

    setHeight(height === minHeight ? measuredHeight : minHeight);
  };

  return (
    <>
      <div
        ref={expanderRef}
        style={{ maxHeight: height }}
        className={cn(className, 'overflow-y-hidden transition-[max-height] duration-300 ease-in-out')}
      >
        {children}
      </div>
      {showExpander && (
        <div className="my-4 flex justify-center">
          <Button {...expandButtonProps} onClick={onClick}>
            {expandLabel}
          </Button>
        </div>
      )}
    </>
  );
};
