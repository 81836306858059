import { useCallback } from 'react';
import { usePostHog } from 'posthog-js/react';
import { useCurrentThreadId } from '@/app/organisms/Assistant/AssistantProvider';
import { useUpdateThreadMutation } from '@/api/llmThreadsApi';

export const useSendNewThreadMessage = () => {
  const posthog = usePostHog();
  const [updateThread] = useUpdateThreadMutation();
  const threadId = useCurrentThreadId();

  const sendNewMessage = useCallback(
    async (content: string) => {
      if (!threadId) return;

      try {
        await updateThread({ id: threadId, content }).unwrap();
        posthog.capture('assistant_message_sent', { threadId, message_content: content });
      } catch (err) {
        console.error('Failed to update thread:', err);
      }
    },
    [posthog, threadId, updateThread]
  );

  return sendNewMessage;
};
