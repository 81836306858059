import { useMemo } from 'react';
import Fuse, { IFuseOptions } from 'fuse.js';

const defaultOptions = { tokenize: true, threshold: 0.2 };

export const useFuzzySearch = <T>({
  query,
  data,
  options = {}
}: {
  query: string;
  data: T[];
  options?: IFuseOptions<T>;
}): T[] => {
  const client = useMemo(() => {
    return new Fuse(data, { ...defaultOptions, ...options });
  }, [data, options]);

  return query ? client.search(query).map(r => r.item) : data;
};
