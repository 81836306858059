import React from 'react';
import { Link, NavLink, type LinkProps, NavLinkProps } from 'react-router';
import { match } from 'ts-pattern';

import { cn } from '@/app/lib/cn';

type CommonProps = { overwriteClassName?: boolean };
type TaggedHrefProps = { tag: 'a' } & React.AnchorHTMLAttributes<HTMLAnchorElement> & CommonProps;
type TaggedButtonProps = { tag: 'button' } & React.ButtonHTMLAttributes<HTMLButtonElement> & CommonProps;
type TaggedLinkProps = { tag?: 'Link' } & LinkProps & CommonProps;
type TaggedNavLinkProps = { tag: 'NavLink' } & NavLinkProps & CommonProps;
export type LinkTagProps = TaggedHrefProps | TaggedButtonProps | TaggedLinkProps | TaggedNavLinkProps;

const DEFAULT_CLASSES =
  'text-blue-600 hover:text-blue-900 no-underline visited:text-blue-800 dark:text-blue-400 dark:hover:text-blue-300 dark:visited:text-blue-200 cursor-pointer';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function getClassName<T extends string | ((...args: any[]) => string | undefined)>({
  overwriteClassName,
  className
}: {
  overwriteClassName?: boolean;
  className?: T;
}): string | T {
  if (!overwriteClassName) return cn(DEFAULT_CLASSES, className);
  return className || DEFAULT_CLASSES;
}

export const LinkTag = ({ className: classNameProp, overwriteClassName, ...props }: LinkTagProps) => {
  const className = getClassName({ className: classNameProp, overwriteClassName });

  return match({ ...props, className } as LinkTagProps)
    .with({ tag: 'a' }, props => <a {...props} />)
    .with({ tag: 'button' }, ({ type = 'button', ...props }) => <button {...props} type={type} />)
    .with({ tag: 'NavLink' }, props => <NavLink {...props} />)
    .otherwise(props => <Link {...props} />);
};
