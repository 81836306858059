import { useSearchParams } from 'react-router';
import { useAuthenticatedNavItems } from '@/app/organisms/AuthenticatedNav/useAuthenticatedNavItems';
import { DRAWER_PARAMS, DrawerType } from '@/app/organisms/AppDrawer/constants';

export function useAppDrawerSubNavTab(drawerType: DrawerType) {
  const [searchParams] = useSearchParams();
  const { drawerNavs } = useAuthenticatedNavItems();
  const subnav = drawerNavs.find(nav => nav.drawerType === drawerType);
  const tabs = subnav?.children?.map(x => x.tab) ?? [];
  const rawTab = searchParams.get(DRAWER_PARAMS.tab) ?? '';
  const tab = tabs.includes(rawTab) ? rawTab : tabs[0];

  return tab;
}
