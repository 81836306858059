import React, { useState, useEffect } from 'react';
import { Button, Icon } from '@blueprintjs/core';
import { partition } from 'lodash-es';

import { H3 } from '@/app/atoms/Typography/Typography';
import { Card, CardBody, CardFooter } from '@/app/atoms/Card/Card';
import { successToast } from '@/app/lib/toaster';
import { AvatarWithName } from '@/app/molecules/AvatarWithName/AvatarWithName';
import { useUpdateOutreachRecipientMutation } from '@/api/outreachRecipientsApi';
import { OutreachRecipientShow } from '@/types/__generated__/GovlyApi';

type ShareViaEmailFormInvitesProps = {
  onCancel: () => void;
  inviteables: OutreachRecipientShow[];
};

export const ShareViaEmailFormInvites = ({ onCancel, inviteables }: ShareViaEmailFormInvitesProps) => {
  const [updateOutreachRecipient, { isLoading: isUpdating }] = useUpdateOutreachRecipientMutation();
  const [activeUsers, nonActiveUsers] = partition(inviteables, 'shouldInviteToPartner');
  const [users, setUsers] = useState(activeUsers);
  const [nonUsers, setNonUsers] = useState(nonActiveUsers);
  const [currentlyUpdating, setCurrentlyUpdating] = useState<string | null>(null);

  useEffect(() => {
    const allUsers = [...users, ...nonUsers];
    if (allUsers.every(u => u.invitedToPartnerAt || u.invitedAt)) {
      onCancel();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [users, nonUsers]);

  const InviteSent = () => (
    <span>
      <Icon icon="tick-circle" intent="success" className="mr-2" />
      Invite Sent!
    </span>
  );

  return (
    <div>
      <Card title="Invite Partners" rightElement={onCancel && <Button minimal icon="cross" onClick={onCancel} />}>
        {nonUsers.length > 0 && (
          <CardBody className="space-y-4">
            <div>
              <H3>Invite colleagues to join Govly</H3>
              <p>Expand your Govly network by inviting others to join and collaborate.</p>
            </div>
            <>
              {nonUsers.map(inviteable => (
                <div
                  key={inviteable.id}
                  className="col-span-1 flex flex-wrap items-center justify-between rounded border border-gray-100 p-2"
                >
                  <AvatarWithName
                    initials={inviteable.initials || 'GV'}
                    avatarColor="bg-blue-500"
                    id={inviteable.recipientEmail}
                    email={inviteable.recipientEmail}
                    name={inviteable.recipientName}
                  />
                  {inviteable.invitedAt ? (
                    <InviteSent />
                  ) : (
                    <Button
                      intent="success"
                      onClick={async () => {
                        setCurrentlyUpdating(inviteable.id);
                        const invitedAt = new Date().toISOString();
                        await updateOutreachRecipient({
                          ...inviteable,
                          invitedAt
                        });
                        setNonUsers(nonUsers.map(u => (u.id === inviteable.id ? { ...u, invitedAt } : u)));
                        successToast('Invitation sent!');
                        setCurrentlyUpdating(null);
                      }}
                      loading={currentlyUpdating === inviteable.id}
                      disabled={currentlyUpdating === inviteable.id}
                    >
                      Invite to Govly
                    </Button>
                  )}
                </div>
              ))}
            </>
          </CardBody>
        )}
        {users.length > 0 && (
          <CardBody className="space-y-4">
            <div>
              <H3>Partner with colleagues already in the Govly network</H3>
              <p>
                Invite existing Govly users to partner so you can more easily share and collaborate on opportunities.
              </p>
            </div>
            <>
              {users.map(inviteable => (
                <div
                  key={inviteable.id}
                  className="col-span-1 flex flex-wrap items-center justify-between rounded border border-gray-100 p-2"
                >
                  <AvatarWithName
                    initials={inviteable.recipient?.initials}
                    imgSrc={inviteable.recipient?.avatar?.thumbUrl}
                    avatarColor={inviteable.recipient?.avatarColor}
                    id={inviteable.recipientEmail}
                    email={inviteable.recipientEmail}
                    name={inviteable.recipientName}
                  />
                  {inviteable.invitedToPartnerAt ? (
                    <InviteSent />
                  ) : (
                    <Button
                      intent="primary"
                      onClick={async () => {
                        setCurrentlyUpdating(inviteable.id);
                        const invitedToPartnerAt = new Date().toISOString();
                        await updateOutreachRecipient({
                          ...inviteable,
                          invitedToPartnerAt
                        });
                        setUsers(users.map(u => (u.id === inviteable.id ? { ...u, invitedToPartnerAt } : u)));
                        successToast('Partnership invitation sent!');
                        setCurrentlyUpdating(null);
                      }}
                      loading={currentlyUpdating === inviteable.id}
                      disabled={currentlyUpdating === inviteable.id}
                    >
                      Invite to partner
                    </Button>
                  )}
                </div>
              ))}
            </>
          </CardBody>
        )}
        <CardFooter>{onCancel && <Button text="Skip" disabled={isUpdating} onClick={onCancel} />}</CardFooter>
      </Card>
    </div>
  );
};
