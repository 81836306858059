import React from 'react';

import { LinkTag } from '@/app/atoms/LinkTag/LinkTag';
import { useGetContactQuery } from '@/api/contactsApi';
import { Loading } from '@/app/atoms/Loading/Loading';
import { DrawerType } from '@/app/organisms/AppDrawer/constants';

const ContactPage = React.lazy(() =>
  import('@/app/pages/ContactPage/ContactPage').then(m => ({ default: m.ContactPage }))
);

export const showContactDrawer = ({ drawerType, drawerId }: { drawerType: DrawerType; drawerId?: string }) =>
  ['/contacts'].includes(window.location.pathname) && drawerType === 'contacts' && drawerId;

export const ContactDrawerTitle = ({ id }: { id: string }) => {
  const { data: result, isLoading } = useGetContactQuery({ id });

  return (
    <div>
      {isLoading ? (
        <Loading type="flex-row" />
      ) : (
        <>
          <LinkTag to={`/contacts/${id}`} target="_blank">
            {result?.email}
          </LinkTag>
          <dd className="text-sm text-gray-500">Contact</dd>
        </>
      )}
    </div>
  );
};

export const ContactDrawerBody = ({ id }: { id: string }) => (
  <React.Suspense fallback={<Loading />}>
    <ContactPage id={id} inDrawer />
  </React.Suspense>
);
