import React from 'react';

import { Main } from '@/app/atoms/Main/Main';
import { MainColumn } from '@/app/atoms/MainColumn/MainColumn';
import { Loading } from '@/app/atoms/Loading/Loading';
import { useGetProviderFeedsQuery } from '@/api/providerFeedsApi';

const ManagedFeed = React.lazy(() =>
  import('@/app/organisms/ManagedFeed/ManagedFeed').then(m => ({ default: m.ManagedFeed }))
);

export const showManagedFeedDrawer = ({ drawerType, drawerId }: { drawerType: string; drawerId: string }) =>
  ['/opportunities/searchable_feeds/managed'].includes(window.location.pathname) &&
  drawerType === 'managed_feed' &&
  drawerId;

export type ManagedFeedDrawerTitleProps = {
  id: string;
};

export const ManagedFeedDrawerTitle = ({ id }: ManagedFeedDrawerTitleProps) => {
  const { feed, feedLoading } = useGetProviderFeedsQuery(undefined, {
    selectFromResult: ({ data, isLoading }) => ({
      feed: data?.find(({ publicId }) => publicId === id),
      feedLoading: isLoading
    })
  });

  return (
    <div>
      {feedLoading ? (
        <Loading type="flex-row" />
      ) : (
        <>
          <h2>{feed?.name}</h2>
        </>
      )}
    </div>
  );
};

export const ManagedFeedDrawerBody = () => {
  return (
    <Main className="min-h-screen max-w-full bg-gray-50">
      <MainColumn columnSpan={12} className="order-1">
        <React.Suspense fallback={<Loading />}>
          <ManagedFeed />
        </React.Suspense>
      </MainColumn>
    </Main>
  );
};
