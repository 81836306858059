import React from 'react';
import { Button, Menu, MenuItem, Popover, MenuDivider } from '@blueprintjs/core';
import { useGovlyTableContext } from '@/app/molecules/GovlyTable/GovlyTableContext';
import { useEventTracking } from '@/app/hooks/useEventTracking';

export const GovlyTableColumnVisibilityToggles = () => {
  const { table, id } = useGovlyTableContext();
  const { trackEvent } = useEventTracking();

  const hasHideableColumns = table.getAllColumns().some(c => c.getCanHide());
  if (!hasHideableColumns) return null;

  return (
    <Popover
      content={
        <Menu>
          <MenuDivider title="Toggle columns" />
          {table.getAllColumns().map(c =>
            c.getCanHide() ? (
              <MenuItem
                key={c.id}
                roleStructure="listoption"
                text={c.columnDef.header?.toString()}
                selected={c.getIsVisible()}
                onClick={() => {
                  c.toggleVisibility();
                  trackEvent({
                    object: id,
                    action: 'toggle_column_visibility',
                    properties: { column: c.columnDef.header }
                  });
                }}
                shouldDismissPopover={false}
              />
            ) : null
          )}
        </Menu>
      }
    >
      <Button outlined icon="eye-open">
        View
      </Button>
    </Popover>
  );
};
