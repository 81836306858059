import React from 'react';
import { Button, InputGroup, Tooltip } from '@blueprintjs/core';

export type OppHistoryControlsProps = {
  desc: boolean;
  setDesc: (desc: boolean) => void;
  search: string;
  setSearch: (search: string) => void;
};

export const OppHistoryControls = ({ desc, setDesc, search, setSearch }: OppHistoryControlsProps) => {
  return (
    <div className="w-full flex items-center justify-between lg:justify-end gap-2">
      <InputGroup
        type="text"
        name="search"
        value={search}
        onChange={e => setSearch(e.target.value)}
        className="w-full max-w-xs md:max-w-sm"
        placeholder="Search"
        rightElement={
          search === '' ? undefined : (
            <Button
              icon="small-cross"
              minimal={true}
              onClick={() => setSearch('')}
              aria-label="clear-search"
              role="button"
            />
          )
        }
        leftIcon="search"
      />

      <div className="flex items-center space-x-2">
        <Tooltip content="Sort Order">
          <Button icon={desc ? 'sort-desc' : 'sort-asc'} aria-label="Sort Order" onClick={() => setDesc(!desc)} />
        </Tooltip>
      </div>
    </div>
  );
};
