import React, { useState } from 'react';
import { OppHistoryTimeline } from '../OppHistoryTimeline/OppHistoryTimeline';
import { OppHistoryControls } from '../OppHistoryControls/OppHistoryControls';

export type OppHistoryProps = {
  id: string;
};

export const OppHistory = ({ id }: OppHistoryProps) => {
  const [desc, setDesc] = useState(true);
  const [search, setSearch] = useState('');

  return (
    <div className="px-5 lg:px-0 relative lg:-mt-12">
      <div className="lg:absolute w-full z-50">
        <OppHistoryControls desc={desc} setDesc={setDesc} search={search} setSearch={setSearch} />
      </div>
      <OppHistoryTimeline id={id} desc={desc} search={search} />
    </div>
  );
};
