import React from 'react';
import {
  ButtonGroup,
  Card,
  CardProps,
  EntityTitle,
  EntityTitleProps,
  Icon,
  IconSize,
  Tooltip,
  TooltipProps
} from '@blueprintjs/core';
import { cn } from '@/app/lib/cn';
import { getFileExtensionIconProps } from '@/app/lib/file';

type Props = {
  filename: string;
  url: string;
  extension: string;
  tooltipProps?: Partial<TooltipProps>;
  actionsButtonGroup?: React.ReactNode;
  cardProps?: Partial<CardProps>;
} & Partial<EntityTitleProps>;

export const AttachmentEntityTitle = ({
  filename,
  url,
  extension,
  tooltipProps: { className: tooltipClassName, ...tooltipProps } = {},
  actionsButtonGroup,
  cardProps,
  ...rest
}: Props) => {
  return (
    <Card {...cardProps} compact className={cn('group flex items-center h-[34px]', cardProps?.className)}>
      <div className="flex-grow min-w-0">
        <EntityTitle
          titleURL={url}
          ellipsize
          title={
            <Tooltip
              content={filename}
              hoverOpenDelay={1000}
              className={cn('max-w-[240px] flex', tooltipClassName)}
              {...tooltipProps}
            >
              <span className="truncate w-full">{filename}</span>
            </Tooltip>
          }
          icon={<Icon size={IconSize.LARGE} {...getFileExtensionIconProps(extension)} />}
          {...rest}
        />
      </div>

      <ButtonGroup minimal className="flex-shrink-0">
        {actionsButtonGroup}
      </ButtonGroup>
    </Card>
  );
};
