import React, { useMemo, useState } from 'react';
import { FormGroup, MenuItem, Button, TagInputProps, FormGroupProps } from '@blueprintjs/core';
import { MultiSelect, MultiSelectProps } from '@blueprintjs/select';
import { get as lodashGet, keyBy } from 'lodash-es';

import { FuseOptionKey } from 'fuse.js';
import { cn } from '@/app/lib/cn';
import { LabelSpan } from '@/app/atoms/inputs/LabelSpan/LabelSpan';
import { useFuzzySearch } from '@/app/hooks/useFuzzySearch';
import { LoadingSpinner } from '@/app/atoms/LoadingSpinner/LoadingSpinner';
import { LinkTag } from '@/app/atoms/LinkTag/LinkTag';
import { useFormikInput } from '@/app/hooks/useFormikInput';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type BaseItem = Record<string, any> & { disabled?: boolean; aggregation?: number };

const renderItem = <T extends BaseItem>(
  item: T,
  {
    modifiers,
    handleClick,
    index,
    valueAttr,
    labelAttr,
    withAggregations
  }: {
    modifiers: { matchesPredicate?: boolean; active?: boolean };
    handleClick: (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void;
    index: number;
    valueAttr: string;
    labelAttr: string;
    withAggregations: boolean;
  }
) => {
  if (!modifiers.matchesPredicate) {
    return null;
  }

  return (
    <MenuItem
      active={modifiers.active}
      key={`${lodashGet(item, valueAttr)}-${index}`}
      onClick={handleClick}
      text={lodashGet(item, labelAttr) as string}
      shouldDismissPopover={false}
      label={withAggregations ? item.aggregation?.toLocaleString() || undefined : undefined}
    />
  );
};

export type MultiSelectInputProps<T extends BaseItem> = {
  name: string;
  items: T[];
  searchKeys?: FuseOptionKey<T>[];
  disabled?: boolean;
  tagInputProps?: Partial<Omit<TagInputProps, 'inputValue' | 'onInputChange'>>;
  loading?: boolean;
  hasSelectAll?: boolean;
  submitOnChange?: boolean;
  valueAttr?: string;
  withAggregations?: boolean;
  labelAttr?: string;
} & Partial<MultiSelectProps<T>> &
  FormGroupProps;

export const MultiSelectInput = <T extends BaseItem>({
  name,
  className,
  helperText,
  disabled,
  label,
  labelInfo,
  tagInputProps = {},
  items,
  loading,
  searchKeys = ['label'],
  hasSelectAll,
  submitOnChange,
  withAggregations = false,
  valueAttr = 'value',
  labelAttr = 'label',
  contentClassName = 'mt-2',
  ...rest
}: MultiSelectInputProps<T>) => {
  const { field, meta, onChange } = useFormikInput<string[]>({ name, submitOnChange });

  const [query, setQuery] = useState<string>('');
  const itemValueMap = useMemo(() => keyBy(items, valueAttr), [items, valueAttr]);
  const error = meta.touched && meta.error;
  const intent = error ? 'danger' : undefined;
  const clearButton = field.value.length > 0 ? <Button icon="cross" minimal onClick={() => onChange([])} /> : undefined;

  const selectItem = (item: T) => {
    const newItems = [...field.value, lodashGet(item, valueAttr) as string];

    return onChange(newItems);
  };

  const deselectItem = (index: number) => {
    const deselected = field.value[index];
    const newItems = field.value.filter(v => v !== deselected);

    return onChange(newItems);
  };

  const noResults = loading ? (
    <MenuItem
      disabled
      text={
        <div className="text-center">
          <LoadingSpinner />
        </div>
      }
    />
  ) : (
    <MenuItem disabled text="No results." />
  );

  const results = useFuzzySearch({
    options: { keys: searchKeys },
    data: items.filter(item => !field.value.includes(item[valueAttr] as string)),
    query
  });

  const toggleSelectAll = () => {
    if (field.value?.length !== 0) {
      onChange([]);
    } else {
      const values = items.filter(i => !i.disabled).map(i => i[valueAttr] as string);
      onChange(values);
    }
  };

  return (
    <FormGroup
      className={cn('m-0', className)}
      helperText={helperText}
      disabled={disabled}
      label={
        <div className="flex justify-between">
          <LabelSpan label={label} />
          {hasSelectAll && (
            <LinkTag tag="a" onClick={toggleSelectAll}>
              {field.value?.length ? 'Clear' : 'Select all'}
            </LinkTag>
          )}
        </div>
      }
      labelInfo={labelInfo}
      labelFor={name}
      intent={intent}
      contentClassName={contentClassName}
      {...rest}
    >
      <MultiSelect
        disabled={disabled}
        items={results.slice(0, 200)}
        itemRenderer={(item: T, options) => renderItem(item, { ...options, valueAttr, labelAttr, withAggregations })}
        onQueryChange={setQuery}
        noResults={noResults}
        onItemSelect={selectItem}
        tagRenderer={item => lodashGet(item, labelAttr) as string}
        resetOnSelect
        resetOnQuery={false}
        tagInputProps={{
          fill: true,
          tagProps: { minimal: true },
          onRemove: (_tag, index) => deselectItem(index),
          rightElement: clearButton,
          intent,
          large: true,
          onKeyDown: e => {
            // prevents form reset and delegates submission to formik
            // (selection via enter still works)
            if (e.key === 'Enter') e.preventDefault();
          },
          ...tagInputProps
        }}
        className="ts-popover-block"
        selectedItems={field.value.map(v => itemValueMap[v])}
        {...rest}
      />
      {error ? <small className="text-xs text-red-500">{meta.error}</small> : null}
    </FormGroup>
  );
};
