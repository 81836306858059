import React from 'react';
import { createRoot } from 'react-dom/client';
import {
  Position,
  OverlayToaster,
  Intent,
  ProgressBar,
  Classes,
  ToastProps,
  BlueprintProvider
} from '@blueprintjs/core';
import { get as lodashGet, truncate } from 'lodash-es';

import { cn } from '@/app/lib/cn';

export const AppToaster = OverlayToaster.createAsync(
  { position: Position.TOP },
  {
    // Use createRoot() instead of ReactDOM.render(). This can be deleted after
    // a future Blueprint version uses createRoot() for Toasters by default.
    domRenderer: (toaster, containerElement) =>
      createRoot(containerElement).render(<BlueprintProvider>{toaster}</BlueprintProvider>)
  }
);

type UploadToastInput = {
  key: string;
  amount: number;
  filename: string;
};

export const toast = async (args: ToastProps, key?: string) => (await AppToaster).show(args, key);

export const uploadToast = async ({ key, amount, filename }: UploadToastInput) => {
  const options: ToastProps = {
    icon: 'cloud-upload',
    message: (
      <>
        <ProgressBar
          className={cn({
            [Classes.PROGRESS_NO_STRIPES]: amount >= 100
          })}
          intent={amount < 100 ? Intent.PRIMARY : Intent.SUCCESS}
          value={amount / 100}
        />
        <small className="text-gray-500">
          {truncate(filename, {
            length: 50
          })}
        </small>
      </>
    ),
    timeout: amount < 100 ? 0 : 2000
  };

  (await AppToaster).show(options, key);
};

export const successToast = async (message = 'Changes saved.', options: Partial<ToastProps> = {}) =>
  await toast({
    icon: 'tick',
    intent: Intent.SUCCESS,
    message,
    ...options
  });

type ApiError = {
  response: {
    status: number;
    data: {
      errors: {
        [key: string]: string;
      };
    };
  };
  data: {
    error: string;
    errors: {
      [key: string]: string;
    };
  };
};

function hasResponseDataErrors(e: unknown): e is ApiError {
  return lodashGet(e, 'response.data.errors') !== undefined;
}

function hasDataErrors(e: unknown): e is ApiError {
  return lodashGet(e, 'data.errors') !== undefined;
}

function hasDataError(e: unknown): e is ApiError {
  return lodashGet(e, 'data.error') !== undefined;
}

function hasResponseStatus(e: unknown): e is ApiError {
  return lodashGet(e, 'response.status') !== undefined;
}

export const errorToast = async (e: string | ApiError | unknown) => {
  let message;

  if (typeof e === 'string') {
    message = e;
  } else if (hasResponseDataErrors(e)) {
    message = Object.values(e.response.data.errors).join(', ');
  } else if (hasDataErrors(e)) {
    message = Object.values(e.data.errors).join(', ');
  } else if (hasDataError(e)) {
    message = `Error: ${e.data.error}`;
  } else if (hasResponseStatus(e)) {
    message = `An error occured. Please try again. [${e.response.status}]`;
  } else {
    message = 'An error occured.';
  }

  (await AppToaster).show({
    icon: 'warning-sign',
    intent: Intent.DANGER,
    message
  });
};

export const dangerToast = async (message: string, key?: string) =>
  await toast(
    {
      icon: 'warning-sign',
      intent: Intent.DANGER,
      message: message
    },
    key
  );

export const deleteToast = async (message: string) =>
  await toast({
    icon: 'trash',
    intent: Intent.DANGER,
    message
  });

export const unauthorizedToast = async () => await errorToast('You do not have permissions to perform this action.');

export const formErrorToast = async (status: number) => {
  if (status === 401 || status === 403) return unauthorizedToast();
  await errorToast('There was an error submitting the form. Please try again.');
};
