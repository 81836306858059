import React from 'react';
import { Link } from 'react-router';
import { AnchorButton, Button } from '@blueprintjs/core';

import { cn } from '@/app/lib/cn';
import Logo from '@/images/components/LogoIcon';
import { usePingCurrentUserQuery } from '@/api/currentUserApi';
import { useScrollPosition } from '@/app/hooks/useScrollPosition';

export const MarketingNavbar = () => {
  const { data: currentUser } = usePingCurrentUserQuery();
  const scrollPosition = useScrollPosition();
  const transparent = scrollPosition < 24;

  return (
    <header
      className={cn('z-40 motion-safe:transition-all', {
        'sticky top-0 bg-white shadow': scrollPosition >= 24,
        'relative bg-gray-900': scrollPosition < 24
      })}
    >
      <nav className="mx-auto flex justify-between p-4 sm:px-6 lg:px-8">
        <div className="flex items-center md:gap-x-8">
          <Link to="/" aria-label="Home">
            <Logo className="h-12 w-auto rounded" />
          </Link>
          <div className="hidden items-center lg:flex lg:gap-x-3">
            {['features', 'testimonials', 'pricing', 'FAQs'].map(section => (
              <AnchorButton key={section} href={`/#${section}`} large minimal>
                <span className={cn('text-base font-medium', { 'text-white': transparent })}>
                  {section.charAt(0).toUpperCase() + section.slice(1)}
                </span>
              </AnchorButton>
            ))}
            <Link to="/resources" className="no-underline">
              <Button large minimal>
                <span className={cn('text-base font-medium', { 'text-white': transparent })}>Resources</span>
              </Button>
            </Link>
            <Link to="/vendors" className="no-underline">
              <Button large minimal>
                <span className={cn('text-base font-medium', { 'text-white': transparent })}>Vendors</span>
              </Button>
            </Link>
          </div>
        </div>
        <div className="flex items-center sm:gap-x-5 md:gap-x-8">
          {currentUser ? (
            <Link to="/" className="no-underline">
              <Button large intent="primary">
                <span className="text-base font-medium">Go to dashboard</span>
              </Button>
            </Link>
          ) : (
            <>
              <Link to="/sign_in">
                <Button large minimal>
                  <span className={cn('text-base font-medium', { 'text-white': transparent })}>Sign in</span>
                </Button>
              </Link>
              <div className="hidden sm:block">
                <a href="https://www.govly.com/book-a-demo">
                  <Button large intent="primary">
                    Request a demo
                  </Button>
                </a>
              </div>
            </>
          )}
        </div>
      </nav>
    </header>
  );
};
