import { rootApi } from './rootApi';
import { oppsApi } from './oppsApi';
import { OppInteraction } from '@/types/__generated__/GovlyApi';

const api = rootApi.enhanceEndpoints({ addTagTypes: ['Opp', 'Workspace'] });

const getNewFollowedAt = (followed = false) => (followed ? new Date().toISOString() : undefined);

type BulkUpdateOppInteractions = {
  params: { followed?: boolean; ignored?: boolean; ids?: string[]; notifyIds?: string[] };
  result: void;
};

type CreateOppInteraction = {
  params: { oppId: string; viewed?: boolean; followed?: boolean; ignored?: boolean; async?: boolean };
  result: OppInteraction;
};

export const oppInteractionsApi = api.injectEndpoints({
  endpoints: build => ({
    bulkUpdateOppInteractions: build.mutation<BulkUpdateOppInteractions['result'], BulkUpdateOppInteractions['params']>(
      {
        query: body => ({
          url: `/v2/opp_interactions/bulk_update`,
          method: 'POST',
          body
        }),
        invalidatesTags: ['Workspace']
      }
    ),

    createOppInteraction: build.mutation<CreateOppInteraction['result'], CreateOppInteraction['params']>({
      query: body => ({
        url: `/v2/opp_interactions`,
        method: 'POST',
        body
      }),
      async onQueryStarted({ oppId, ...body }, { dispatch, queryFulfilled, getState }) {
        const patchOppResult = dispatch(
          oppsApi.util.updateQueryData('getOpp', { id: oppId }, draftOpp => {
            if (!draftOpp) return;
            if (!draftOpp.oppInteraction) {
              draftOpp.oppInteraction = {} as OppInteraction;
            }
            draftOpp.oppInteraction.followedAt = getNewFollowedAt(body.followed);
          })
        );

        // The getOpps endpoint from search passes ids as a query param, so we need to find that specific query tag/key
        // to invalidate it.
        const { originalArgs } =
          api.util.selectInvalidatedBy(getState(), ['Opp']).find(({ endpointName }) => endpointName === 'getOpps') ??
          {};

        const patchOppsResult = dispatch(
          oppsApi.util.updateQueryData('getOpps', originalArgs, draftOpps => {
            draftOpps.forEach(draftOpp => {
              if (draftOpp.id === oppId) {
                if (!draftOpp.oppInteraction) {
                  draftOpp.oppInteraction = {} as OppInteraction;
                }
                draftOpp.oppInteraction.followedAt = getNewFollowedAt(body.followed);
              }
            });
          })
        );

        try {
          await queryFulfilled;
        } catch {
          [patchOppResult, patchOppsResult].forEach(result => {
            result.undo();
          });
        }
      },
      invalidatesTags: (result, error, args) =>
        args.async
          ? []
          : [
              ...(result
                ? [
                    { type: 'Workspace' as const, id: `OPP:${result.oppId}` },
                    { type: 'Opp' as const, id: result.oppId }
                  ]
                : []),
              'Workspace'
            ]
    })
  })
});

export const { useBulkUpdateOppInteractionsMutation, useCreateOppInteractionMutation } = oppInteractionsApi;
