import React from 'react';
import { Dialog } from '@blueprintjs/core';
import { useOppWorkspaceStoreRef } from '@/app/organisms/OppWorkspacePageContents/useOppWorkspaceStore';

export const ToolboxDialog = () => {
  const store = useOppWorkspaceStoreRef();
  const toolboxDialogProps = store.use.toolboxDialogProps();

  return (
    <Dialog onClose={() => store.setState({ toolboxDialogProps: { isOpen: false } })} lazy {...toolboxDialogProps} />
  );
};
