import React from 'react';

import { Loading } from '@/app/atoms/Loading/Loading';
import { MultiSelectInput, MultiSelectInputProps } from '@/app/atoms/inputs/MultiSelectInput/MultiSelectInput';
import { LabelTag } from '@/app/organisms/LabelTag/LabelTag';
import { useGetOrganizationLabelsQuery } from '@/api/organizationLabelsApi';

type OrganizationLabelItem = {
  label: JSX.Element;
  value: string;
  name: string;
  description?: string;
};

export const LabelTagMultiSelectInput = function (
  inputProps: Omit<MultiSelectInputProps<OrganizationLabelItem>, 'items' | 'searchKeys'>
) {
  const { data: organizationLabels = [], isLoading: labelsLoading } = useGetOrganizationLabelsQuery();

  if (labelsLoading) {
    return <Loading type="flex-row" />;
  }

  const items: OrganizationLabelItem[] = organizationLabels.map(label => ({
    label: <LabelTag {...label} />,
    value: label.id,
    name: label.name,
    description: label.description
  }));

  return <MultiSelectInput items={items} searchKeys={['name', 'description']} className="py-6" {...inputProps} />;
};
