import { useState, useEffect } from 'react';

const getStorageValue = <T = string>(key: string, defaultValue: T) => {
  const saved = localStorage.getItem(key);

  if (saved) {
    return JSON.parse(saved) as T;
  }

  return defaultValue;
};

export const useLocalStorage = <T = string>(key: string, defaultValue: T) => {
  const [value, setValue] = useState(() => {
    return getStorageValue(key, defaultValue);
  });

  useEffect(() => {
    localStorage.setItem(key, JSON.stringify(value));
  }, [key, value]);

  return [value, setValue] as const;
};
