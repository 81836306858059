import React from 'react';
import { Button, Icon } from '@blueprintjs/core';
import { useLocation } from 'react-router';
import { Card, CardBody } from '@/app/atoms/Card/Card';
import Avatar from '@/app/molecules/Avatar/Avatar';
import { useCurrentUserAttribute } from '@/api/currentUserApi';
import { WorkspaceToolboxCommentButton } from '@/app/organisms/WorkspaceToolbox/WorkspaceToolboxCommentButton';
import { WorkspaceToolboxQuoteButton } from '@/app/organisms/WorkspaceToolbox/WorkspaceToolboxQuoteButton';
import { useOppWorkspaceStoreRef } from '@/app/organisms/OppWorkspacePageContents/useOppWorkspaceStore';
import { cn } from '@/app/lib/cn';
import { useUploadWorkspaceAttachments } from '@/app/organisms/OppWorkspaceAttachmentsCard/useUploadWorkspaceAttachments';
import { FileInputTarget } from '@/app/atoms/FileInputTarget/FileInputTarget';
import { useGoToTab } from '@/app/organisms/WorkspaceToolbox/useGoToTab';

export const WorkspaceToolbox = () => {
  const avatarUrl = useCurrentUserAttribute('avatar.thumbUrl');
  const avatarColor = useCurrentUserAttribute('avatarColor');
  const avatarInitials = useCurrentUserAttribute('initials');

  const workspaceId = useOppWorkspaceStoreRef().use.workspaceId();

  const { ref, isDragActive: isActive, onDrop, isUploading } = useUploadWorkspaceAttachments({ workspaceId });

  const location = useLocation();
  const goToTab = useGoToTab();

  return (
    <Card
      ref={ref}
      className={cn('relative', {
        'outline-dashed outline-blue-500': isActive
      })}
    >
      <CardBody className="relative space-y-4 pb-4">
        <div className="flex items-center gap-2">
          <Avatar color={avatarColor} initials={avatarInitials} imgSrc={avatarUrl} />
          <WorkspaceToolboxCommentButton />
        </div>

        <div className="flex gap-2 justify-center">
          <FileInputTarget onFileChange={onDrop} disabled={isUploading}>
            <Button icon={<Icon icon="paperclip" className="text-purple-500" />} minimal>
              Add attachments
            </Button>
          </FileInputTarget>

          <WorkspaceToolboxQuoteButton />

          <Button
            icon={<Icon icon="lightning" className="text-blue-500" />}
            minimal
            onClick={() => goToTab({ drawerTab: 'Assistant', route: location.pathname + '/assistant' })}
          >
            Ask a question
          </Button>
        </div>
      </CardBody>
    </Card>
  );
};
