import React from 'react';
import { isRouteErrorResponse, useRouteError } from 'react-router';

import { ApplicationError } from '@/app/atoms/ErrorFallback/ApplicationError';
import { NotFound } from '@/app/molecules/NotFound/NotFound';
import { NotAuthorized } from '@/app/molecules/NotAuthorized/NotAuthorized';
import { trackError } from '@/app/lib/errorHelpers';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const errorHasMessage = (error: any): error is { message: string } => {
  return error && typeof error.message === 'string';
};

export const RouteErrorBoundary = () => {
  const error = useRouteError();

  if (errorHasMessage(error)) {
    trackError(new Error(error.message), { action: 'RouteErrorBoundary' });
  }

  if (isRouteErrorResponse(error)) {
    if (error.status === 404) {
      return <NotFound />;
    }
    if (error.status === 401) {
      return <NotAuthorized />;
    }
    if (error.status === 503) {
      return <ApplicationError errorCode="503" />;
    }
    if (error.status === 429) {
      return <ApplicationError errorCode="429" title="Requests Throttled" />;
    }
  }

  return <ApplicationError />;
};
