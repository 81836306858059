import { useCallback } from 'react';
import { useCreateQuoteMutation } from '@/api/quotesApi';
import { successToast, errorToast } from '@/app/lib/toaster';
import { useOppWorkspaceStoreRef } from '@/app/organisms/OppWorkspacePageContents/useOppWorkspaceStore';

export const useConvertWorkspaceAttachmentToQuote = () => {
  const [createQuote, createQuoteMeta] = useCreateQuoteMutation();
  const storeRef = useOppWorkspaceStoreRef();
  const workspaceId = storeRef.useStore(state => state.workspaceId);

  const convertToQuote = useCallback(
    async (signedId: string) => {
      return createQuote({ workspaceId: workspaceId ?? '', file: signedId })
        .then(res => {
          if ('error' in res) {
            throw new Error("We're sorry, something went wrong. Please try again later.");
          }
          storeRef.setState({ shouldPollQuotes: true });
          successToast('Quote conversion started');
        })
        .catch(error => errorToast(error.message));
    },
    [createQuote, storeRef, workspaceId]
  );

  return [convertToQuote, createQuoteMeta] as const;
};
