import React from 'react';
import { Tooltip } from '@blueprintjs/core';

import { cn } from '@/app/lib/cn';

export type LabelSpanProps = {
  label?: React.ReactNode;
  className?: string;
  tooltipContent?: string;
  showEmpty?: boolean;
  labelInfo?: string;
  helperEl?: React.ReactNode;
};

export const LabelSpan = ({ label, showEmpty, className, tooltipContent, labelInfo, helperEl }: LabelSpanProps) => {
  if (!label && !showEmpty) {
    return null;
  }
  if (tooltipContent) {
    return (
      <span
        className={cn(
          'inline-block cursor-help border-b border-dotted border-gray-600 text-gray-600 dark:text-gray-400',
          className,
          {
            invisible: !label
          }
        )}
      >
        <Tooltip lazy content={tooltipContent} position="top" className="m-0">
          {label || 'Label'}
        </Tooltip>
      </span>
    );
  }
  return (
    <span
      className={cn('inline-block border-b border-transparent text-gray-600 dark:text-gray-400', className, {
        invisible: !label
      })}
    >
      {label || 'Label'}
      {labelInfo && <span className="bp5-text-muted ml-1">{labelInfo}</span>}
      {helperEl}
    </span>
  );
};
