import Appsignal from '@appsignal/javascript';
import { plugin as pluginWindowEvents } from '@appsignal/plugin-window-events';
import { plugin as pluginPathDecorator } from '@appsignal/plugin-path-decorator';
import { plugin as pluginBreadcrumbsNetwork } from '@appsignal/plugin-breadcrumbs-network';

/* eslint-disable no-console */
if (process.env.NODE_ENV === 'test') {
  const consoleMethods = ['info', 'error', 'warn'] as const;
  consoleMethods.forEach(method => {
    const orig = console[method];
    console[method] = (...msg: Parameters<typeof orig>) => {
      if (!msg[0]?.startsWith?.('[APPSIGNAL]')) {
        orig(...msg);
      }
    };
  });
}
/* eslint-enable no-console */

const appsignal = new Appsignal({
  key: process.govlyEnv.APP_SIGNAL_FRONTEND,
  revision: process.govlyEnv.APP_REVISION,
  ignoreErrors: [/ResizeObserver/]
});

appsignal.use(pluginPathDecorator());
appsignal.use(pluginBreadcrumbsNetwork());
appsignal.use(pluginWindowEvents());

export { appsignal };
