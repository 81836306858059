import React, { createContext, useContext, useRef } from 'react';
import { createStore } from 'zustand';
import { createStoreSelectors } from '@/app/lib/createSelectors';

type Store = {
  commentId: string;
  isOpen: boolean;
};

type ReactionsStoreProps = {
  commentId: string;
};

export const createReactionsStore = ({ commentId }: ReactionsStoreProps) =>
  createStoreSelectors(
    createStore<Store>()(() => ({
      commentId,
      isOpen: false
    }))
  );

type StoreRef = ReturnType<typeof createReactionsStore>;

export const ReactionsStoreContext = createContext<StoreRef | null>(null);

export const ReactionsStoreProvider = ({ commentId, children }: { commentId: string; children: React.ReactNode }) => {
  const store = useRef(createReactionsStore({ commentId })).current;

  return <ReactionsStoreContext.Provider value={store}>{children}</ReactionsStoreContext.Provider>;
};

export function useReactionsStoreRef(): StoreRef {
  const store = useContext(ReactionsStoreContext);
  if (!store) {
    throw new Error('useReactionsStore must be used within a ReactionsStoreProvider');
  }
  return store;
}
