import { useEffect, useMemo, useState } from 'react';

export function useIntersectionObserver<Element extends HTMLElement>(
  ref: React.MutableRefObject<Element | null>,
  options: IntersectionObserverInit = {}
) {
  const [hasIntersected, setHasIntersected] = useState(false);
  const [isIntersecting, setIntersecting] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(([entry]) => {
      setIntersecting(entry.isIntersecting);
    }, options);

    if (ref.current) {
      observer.observe(ref.current);
    }

    return () => {
      observer.disconnect();
    };
  }, [ref, options]);

  useEffect(() => {
    if (isIntersecting && !hasIntersected) {
      setHasIntersected(true);
    }
  }, [hasIntersected, isIntersecting]);

  return useMemo(() => ({ isIntersecting, hasIntersected }), [hasIntersected, isIntersecting]);
}
