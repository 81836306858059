import { WorkspaceBase } from '@/types/__generated__/GovlyApi';

export type WorkflowStageValue = WorkspaceBase['workflowStage'];
export type WorkflowStageLabel =
  | 'No Bid'
  | 'Qualifying'
  | 'Intend to Bid'
  | 'Quoted'
  | 'Submitted'
  | 'Awarded'
  | 'Not Awarded';

export type WorkflowStage = {
  label: WorkflowStageLabel;
  value: WorkflowStageValue;
  status?: 'complete' | 'current' | 'upcoming';
  intent?: 'success' | 'danger';
};

export const workflowStages: WorkflowStage[] = [
  { label: 'No Bid', value: 'no_bid' },
  { label: 'Qualifying', value: 'qualifying' },
  { label: 'Intend to Bid', value: 'intend_to_bid' },
  { label: 'Quoted', value: 'quoted' },
  { label: 'Submitted', value: 'submitted' },
  { label: 'Awarded', value: 'awarded' },
  { label: 'Not Awarded', value: 'not_awarded' }
];
