import React from 'react';
import { Tooltip } from '@blueprintjs/core';
import { NavLinkButton } from '@/app/molecules/NavLinkButton/NavLinkButton';
import { NavItem } from '@/app/organisms/AuthenticatedNav/AuthenticatedNavItemsUtils';
import { useNavStore } from '@/app/organisms/AuthenticatedNav/useNavStore';

export type BudgetsNavItemProps = {
  node: NavItem;
  index: number;
};

export const BudgetsNavItem = ({ node, index }: BudgetsNavItemProps) => {
  const isExpanded = useNavStore.use.isExpanded();

  return (
    <Tooltip className="w-full" disabled={isExpanded} content={node.label}>
      <NavLinkButton
        key={index}
        to={node.to}
        buttonProps={{
          icon: node.icon,
          text: isExpanded ? node.label : undefined
        }}
        forceCurrent={node.current}
      />
    </Tooltip>
  );
};
