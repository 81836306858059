import { P, match } from 'ts-pattern';
import { rootApi } from './rootApi';
import {
  USASpendingAwardSearch as APIAwardSearch,
  SearchQuery,
  USASpendingAwardIndex,
  USASpendingAwardShow,
  SearchQueryMeta,
  TypeaheadQuery,
  TypeaheadQueryWithAliases,
  USASpendingTransactionIndex,
  USASpendingSubawardIndex,
  TypeaheadItem,
  TypeaheadItemWithAliases
} from '@/types/__generated__/GovlyApi';
import { useSearchPagination } from '@/app/hooks/useSearchPagination';
import {
  AwardSearchFilters,
  RANGE_FIELDS,
  AGENCY_FIELDS,
  CompositeFilter
} from '@/app/hooks/search/useAwardSearchCache';

const api = rootApi.enhanceEndpoints({
  addTagTypes: ['USASpendingAward', 'USASpendingTransaction', 'USASpendingSubaward']
});

type GetAwards = {
  params: { ids: string[] };
  results: USASpendingAwardIndex[];
};

type GetAward = {
  params: { id: string };
  result: USASpendingAwardShow;
};

type GetAwardTransactions = {
  params: { contractAwardUniqueKey: string; page?: number };
  result: {
    results: USASpendingTransactionIndex[];
    meta: {
      currentPage: number;
      per: number;
      total: number;
    };
  };
};

type GetAwardSubawards = {
  params: { contractAwardUniqueKey: string; page?: number };
  result: {
    results: USASpendingSubawardIndex[];
    meta: {
      currentPage: number;
      per: number;
      total: number;
    };
  };
};

type GetAwardContractActivity = {
  params: { contractAwardUniqueKey: string };
  result: USASpendingTransactionIndex[];
};

type USASpendingAwardSearch = {
  params: { query: string; page?: number; per?: number } & AwardSearchFilters;
  result: USASpendingAwardSearch['response'] & { query: USASpendingAwardSearch['params'] };
  response: {
    results: APIAwardSearch[];
    meta: SearchQueryMeta;
  };
};

type USASpendingAwardAggregation = {
  params: { query?: string; aggs: Array<string> | string; size?: number; subAggSize?: number } & AwardSearchFilters;
  result: SearchQuery;
};

type USASpendingAwardTypeahead = {
  params: { query?: string; typeahead: string; filterValues?: Array<string>; view?: 'with_aliases' };
  result: TypeaheadQuery['results'] | TypeaheadQueryWithAliases['results'];
  response: TypeaheadQuery | TypeaheadQueryWithAliases;
};

type USASpendingAwardExport = {
  params: { query: string } & AwardSearchFilters;
  result: void;
};

export const awardsApi = api.injectEndpoints({
  endpoints: build => ({
    getAwards: build.query<GetAwards['results'], GetAwards['params']>({
      query: params => ({
        url: `/v2/usa_spending_awards`,
        params
      }),
      providesTags: ['USASpendingAward']
    }),

    getAward: build.query<GetAward['result'], GetAward['params']>({
      query: ({ id }) => ({
        url: `/v2/usa_spending_awards/${id}`
      }),
      providesTags: (result, err, { id }) => [{ type: 'USASpendingAward', id: result?.id ?? id }]
    }),

    getAwardTransactions: build.query<GetAwardTransactions['result'], GetAwardTransactions['params']>({
      query: ({ contractAwardUniqueKey, ...params }) => ({
        url: `/v2/usa_spending_awards/${contractAwardUniqueKey}/transactions`,
        params
      }),
      providesTags: result => [
        ...(result ? result.results.map(t => ({ type: 'USASpendingTransaction' as const, id: t.id })) : [])
      ]
    }),

    getAwardSubawards: build.query<GetAwardSubawards['result'], GetAwardSubawards['params']>({
      query: ({ contractAwardUniqueKey, ...params }) => ({
        url: `/v2/usa_spending_awards/${contractAwardUniqueKey}/subawards`,
        params
      }),
      providesTags: result => [
        ...(result ? result.results.map(t => ({ type: 'USASpendingSubaward' as const, id: t.id })) : [])
      ]
    }),

    getAwardContractActivity: build.query<GetAwardContractActivity['result'], GetAwardContractActivity['params']>({
      query: ({ contractAwardUniqueKey, ...params }) => ({
        url: `/v2/usa_spending_awards/${contractAwardUniqueKey}/contract_activity`,
        params
      }),
      providesTags: result => [
        ...(result ? result.map(t => ({ type: 'USASpendingTransaction' as const, id: t.id })) : [])
      ]
    }),

    awardSearch: build.mutation<USASpendingAwardSearch['result'], USASpendingAwardSearch['params']>({
      query: body => ({
        url: '/v2/usa_spending_awards/search',
        method: 'POST',
        body: sanitizeFilters(body)
      }),
      transformResponse: (response: USASpendingAwardSearch['response'], _meta, arg) => ({ query: arg, ...response })
    }),

    awardSearchQuery: build.query<USASpendingAwardSearch['result'], USASpendingAwardSearch['params']>({
      query: body => ({
        url: '/v2/usa_spending_awards/search',
        method: 'POST',
        body: sanitizeFilters(body)
      }),
      transformResponse: (response: USASpendingAwardSearch['response'], _meta, arg) => ({ query: arg, ...response })
    }),

    awardAggregation: build.query<USASpendingAwardAggregation['result'], USASpendingAwardAggregation['params']>({
      query: body => ({
        url: `/v2/usa_spending_awards/aggregations`,
        method: 'POST',
        body: sanitizeFilters(body)
      })
    }),

    awardTypeahead: build.query<USASpendingAwardTypeahead['result'], USASpendingAwardTypeahead['params']>({
      query: params => ({
        url: `/v2/usa_spending_awards/typeahead`,
        params
      }),
      transformResponse: (response: USASpendingAwardTypeahead['response'], _meta, _arg) => response.results || []
    }),

    awardExport: build.mutation<USASpendingAwardExport['result'], USASpendingAwardExport['params']>({
      query: body => ({
        url: `/v2/usa_spending_awards/export`,
        method: 'POST',
        body: sanitizeFilters(body)
      })
    })
  })
});

export const AWARD_SEARCH_CACHE_KEY = 'award-search';

export const useAwardSearchPagination = () =>
  useSearchPagination({
    mutation: useAwardSearchMutation,
    cacheKey: AWARD_SEARCH_CACHE_KEY
  });

export const AGENCY_DEPTH_PATTERN = /(:\d+)+$/;

function sanitizeFilters(filters: AwardSearchFilters) {
  return Object.fromEntries(
    Object.entries(filters).map(([key, value]) => {
      if (RANGE_FIELDS.includes(key as (typeof RANGE_FIELDS)[number]) && Array.isArray(value)) {
        const [min, max] = value;
        // undefined/null gets stripped by rails params, so we preserve the tuple structure with an empty string along with #presence in filter_context.rb
        return match({ min, max })
          .with({ min: P.nullish, max: P.nullish }, () => [key, []])
          .with({ min: P.nullish, max: P.number }, ({ max }) => [key, ['', max]])
          .with({ min: P.number, max: P.nullish }, ({ min }) => [key, [min, '']])
          .otherwise(({ min, max }) => [key, [min, max]]);
      }

      if (AGENCY_FIELDS.includes(key as (typeof AGENCY_FIELDS)[number])) {
        const filter = value as CompositeFilter;
        const withDepthRemoved = Object.fromEntries(
          Object.entries(filter).map(([k, v]) => {
            return [k, v.map(s => AgencyDepthUtils.parseValueLabel(s))];
          })
        );

        return [key, withDepthRemoved];
      }

      return [key, value];
    })
  );
}

export const AgencyDepthUtils = {
  getItemValue: (item: TypeaheadItem | TypeaheadItemWithAliases) => `${item.value}:${item.depth ?? 0}`,
  parseValueLabel: (value: string) => value.replace(AGENCY_DEPTH_PATTERN, '')
};

export const {
  useGetAwardsQuery,
  useGetAwardQuery,
  useAwardSearchMutation,
  useAwardSearchQueryQuery,
  useLazyAwardAggregationQuery,
  useAwardAggregationQuery,
  useAwardTypeaheadQuery,
  useAwardExportMutation,
  useGetAwardTransactionsQuery,
  useGetAwardSubawardsQuery,
  useGetAwardContractActivityQuery
} = awardsApi;
