import React from 'react';
import { Icon } from '@blueprintjs/core';

import { AvatarWithName } from '@/app/molecules/AvatarWithName/AvatarWithName';
import { initialFromName } from '@/app/lib/contact';
import { ContactBase } from '@/types/__generated__/GovlyApi';

export type ContactProps = Partial<ContactBase> & {
  firstName?: string;
  lastName?: string;
  initials?: string;
  avatarImgUrl?: string;
};

export const Contact = ({
  email,
  firstName,
  lastName,
  names,
  phoneNumbers = [],
  titles = [],
  initials,
  avatarImgUrl,
  lastActive: _,
  ...rest
}: ContactProps) => {
  let displayName = names?.[0] || '';

  if (firstName || lastName) {
    displayName = `${firstName} ${lastName}`.trim();
  }

  const avatarInitials = initials || initialFromName(displayName);
  return (
    <div className="flex justify-between">
      <AvatarWithName
        initials={avatarInitials}
        name={displayName}
        email={email}
        imgSrc={avatarImgUrl}
        icon="person"
        {...rest}
      />
      <div className="flex items-center gap-x-4">
        {phoneNumbers?.length > 0 && (
          <div className="flex items-center gap-x-2">
            <Icon icon="phone" className="text-gray-500" />
            <span>{phoneNumbers[0]}</span>
          </div>
        )}
        {titles?.length > 0 && (
          <div className="flex items-center gap-x-2">
            <Icon icon="office" className="text-gray-500" />
            <span>{titles[0]}</span>
          </div>
        )}
      </div>
    </div>
  );
};
