import React from 'react';

import { useGetGovernmentEntityQuery } from '@/api/governmentEntitiesApi';
import { Loading } from '@/app/atoms/Loading/Loading';
import { GovernmentEntity } from '@/app/organisms/GovernmentEntity/GovernmentEntity';
import { DrawerType } from '@/app/organisms/AppDrawer/constants';

export const showGovernmentEntityDrawer = ({ drawerType, drawerId }: { drawerType: DrawerType; drawerId: string }) =>
  drawerType === 'entity' && drawerId;

export const GovernmentEntityDrawerTitle = ({ id }: { id: string }) => {
  const { data: { name } = {}, isLoading } = useGetGovernmentEntityQuery({ id });

  if (isLoading) return <Loading type="flex-row" />;

  return <dd>{name}</dd>;
};

export const GovernmentEntityDrawerBody = ({ id }: { id: string }) => {
  return <GovernmentEntity id={id} className="max-w-full min-h-screen bg-gray-50 px-4 pb-20" />;
};
