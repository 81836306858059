import { create } from 'zustand';
import { createSelectors } from '@/app/lib/createSelectors';

type Store = { featureFlags: { [k in keyof typeof process.govlyEnv.featureFlags]?: boolean } };

export const useFeatureFlagsStore = createSelectors(
  create<Store>(() => ({
    featureFlags: {}
  }))
);

export const setFeatureFlag = (flag: keyof typeof process.govlyEnv.featureFlags, value: boolean) => {
  useFeatureFlagsStore.setState(x => ({ ...x, featureFlags: { ...x.featureFlags, [flag]: value } }));
};
