import { useDropzone, DropzoneOptions } from 'react-dropzone';
import { OnAttachArgs } from './useOnBulkAttach';
import { useActiveStorageOnDrop } from '@/app/hooks/useActiveStorageOnDrop';

export type UseUploadArgs = {
  uploaderId: string;
  onAttach?: ({ name, signedId, id }: OnAttachArgs) => Promise<void>;
  onBulkAttach?: (attachments: OnAttachArgs[]) => Promise<void>;
  onInitialize?: (file: File) => void;
} & Omit<DropzoneOptions, 'onError'>;

export const useActiveStorageDropzone = ({
  uploaderId,
  onAttach,
  onBulkAttach,
  onInitialize,
  ...rest
}: UseUploadArgs) => {
  if (!onAttach && !onBulkAttach) {
    throw new Error('onAttach or onBulkAttach must be provided');
  }

  const { onDrop, isBulk } = useActiveStorageOnDrop({ onInitialize, uploaderId, onAttach, onBulkAttach });

  return useDropzone({ onDrop, multiple: isBulk, ...rest });
};
