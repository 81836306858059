import { WorkspaceAttachmentShow } from '@/types/__generated__/GovlyApi';

const fromWorkspaceAttachment = (attachment: WorkspaceAttachmentShow) => {
  const filename = attachment.attachment?.name ?? '';
  const url = attachment.attachment?.link ?? '';
  const extension = filename.split('.').pop() ?? '';

  return { filename, url, extension };
};

export const AttachmentEntityTitleUtils = {
  fromWorkspaceAttachment
};
