import React from 'react';
import { Tab, Tabs } from '@blueprintjs/core';
import { useSearchParams } from 'react-router';
import { Breadcrumbs } from '@/app/molecules/Breadcrumbs/Breadcrumbs';
import { useBreadcrumbs } from '@/app/molecules/Breadcrumbs/useBreadcrumbs';
import { useMetaTags } from '@/app/hooks/useMetaTags';
import { useAppDrawerNavItems } from '@/app/organisms/AuthenticatedNav/useAppDrawerNavItems';
import { useSelectDrawerNavItem } from '@/app/organisms/AuthenticatedNav/useSelectDrawerNavItem';
import { DRAWER_PARAMS } from '@/app/organisms/AppDrawer/constants';
import { useAppDrawerStore } from '@/app/organisms/AppDrawer/useAppDrawerStore';
import { TextLoading } from '@/app/atoms/Loading/Loading';

export const AppDrawerNav = () => {
  const { crumbs, isLoading } = useBreadcrumbs();

  const title = crumbs[crumbs.length - 1]?.text?.toString() ?? 'Govly';
  useMetaTags({ title }, [title]);

  const tabs = useAppDrawerNavItems();
  const selectNavItem = useSelectDrawerNavItem();

  const [searchParams] = useSearchParams();
  const currentTab = searchParams.get(DRAWER_PARAMS.tab) ?? tabs?.[0]?.tab ?? '';

  const rightElement = useAppDrawerStore(state => state.rightElement);

  return (
    <div className="flex justify-between items-center mr-4 gap-4 overflow-auto">
      <div>
        {isLoading ? <TextLoading /> : <Breadcrumbs items={crumbs} className="px-0" />}

        <nav>
          {tabs.length > 0 && (
            <Tabs
              animate={false}
              selectedTabId={currentTab}
              onChange={to => {
                const tabLookup = Object.fromEntries(tabs.map(x => [x.tab, x]) ?? []);
                const node = tabLookup[to];
                selectNavItem(node);
              }}
              className="h-10 font-normal"
              fill
            >
              {tabs.map((item, index) => (
                <Tab
                  key={item.tab}
                  id={item.tab}
                  title={item.tab}
                  icon={item.icon}
                  tagContent={item.TagContent ? <item.TagContent node={item} index={index} /> : null}
                  tagProps={{ minimal: true, ...item.getTagProps?.({ node: item, index }) }}
                />
              ))}
            </Tabs>
          )}
        </nav>
      </div>

      {rightElement}
    </div>
  );
};
