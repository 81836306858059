import React from 'react';
import { Tag, Tooltip } from '@blueprintjs/core';
import { orderBy } from 'lodash-es';

import { cn } from '@/app/lib/cn';
import { Avatar, AvatarProps } from '@/app/molecules/Avatar/Avatar';
import { useGetCurrentUserQuery } from '@/api/currentUserApi';
import { moveItem } from '@/app/lib/arrays';
import { OrganizationUserDisplayable } from '@/types/__generated__/GovlyApi';
import { NotificationIndicatorProps } from '@/app/atoms/NotificationIndicator/NotificationIndicator';
import { notNullish } from '@/app/lib/notNullish';

type AvatarData = Pick<
  OrganizationUserDisplayable,
  'initials' | 'name' | 'organizationName' | 'avatar' | 'avatarColor'
> & {
  active?: boolean;
  notificationProps?: NotificationIndicatorProps;
};

type AvatarListProps = {
  avatarData: AvatarData[];
  avatarProps?: AvatarProps;
  className?: string;
  limit?: number;
  onEdit?: () => void;
};

export const AvatarList = ({ avatarData = [], avatarProps = {}, className, limit = 4, onEdit }: AvatarListProps) => {
  const { data: { name } = {}, isLoading } = useGetCurrentUserQuery();
  if (!avatarData.length || isLoading) return null;

  const orderedList = orderBy(avatarData, ['notificationProps.intent', 'initials'], ['desc', 'asc']);

  if (name && name.length > 0) {
    const i = orderedList.findIndex(f => f.name === name);
    if (i > 0) moveItem(orderedList, i, 0);
  }

  const list = orderedList.slice(0, limit + 1);
  let overflow = null;

  if (orderedList.length - limit > 1) {
    const removed = list.pop();
    const overflowAvatars = [removed, ...orderedList.slice(limit + 1)].filter(notNullish);
    const overflowList = overflowAvatars.map(({ name, organizationName, active }) => (
      <div key={name}>{name + (organizationName ? ' - ' + organizationName : '') + ' ' + (active ? '👍' : '👎')}</div>
    ));

    overflow = {
      avatar: { initials: `+${orderedList.length - limit}` },
      tooltipContent: <div>{overflowList}</div>
    };
  }

  return (
    <div className={cn('relative z-0 flex items-center -space-x-1 overflow-hidden', className)}>
      {list.map(({ organizationName, initials, name, avatar, avatarColor, notificationProps }, i) => (
        <div key={`${initials}-${i}`} className={cn('relative inline-block', `z-${40 - i * 10}`)}>
          <Tooltip content={`${name}${organizationName ? ' - ' + organizationName : ''}`}>
            <Avatar
              initials={initials}
              imgSrc={avatar?.thumbUrl}
              className={avatarColor}
              notificationProps={notificationProps}
              {...avatarProps}
            />
          </Tooltip>
        </div>
      ))}
      {overflow && (
        <Tooltip className="whitespace-pre-line" content={overflow.tooltipContent}>
          <Avatar {...avatarProps} {...overflow.avatar} />
        </Tooltip>
      )}
      {onEdit && (
        <div>
          <Tag minimal round interactive icon="plus" onClick={onEdit} className="ml-2">
            Add followers
          </Tag>
        </div>
      )}
    </div>
  );
};
