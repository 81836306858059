import React, { ComponentProps } from 'react';
import { useLocation } from 'react-router';
import { Breadcrumbs as BlueprintBreadcrumbs, BreadcrumbProps as BlueprintBreadcrumbProps } from '@blueprintjs/core';
import { cn } from '@/app/lib/cn';
import { LinkTag } from '@/app/atoms/LinkTag/LinkTag';

export type BreadcrumbProps = BlueprintBreadcrumbProps & { to?: string; index?: number; isLast?: boolean };

type Props = {
  items?: BreadcrumbProps[];
  className?: string;
};

export const Breadcrumbs = ({ items = [], className }: Props) => {
  const { state } = useLocation();
  let crumbs = items;

  if (!crumbs.length && state?.breadcrumbs) {
    crumbs = [...state.breadcrumbs];
  }

  if (!crumbs?.length) {
    return null;
  }

  return (
    <BlueprintBreadcrumbs
      items={crumbs}
      className={cn('px-4 sm:px-0', className)}
      breadcrumbRenderer={({ text, ..._attrs }) => {
        const { index, ...attrs } = _attrs as BreadcrumbProps;
        const isLast = index === crumbs.length - 1;
        const solo = crumbs.length === 1;

        if ((!isLast && 'to' in attrs && attrs.to) || attrs.href || attrs.onClick) {
          return (
            <LinkTag
              {...(attrs as ComponentProps<typeof LinkTag>)}
              overwriteClassName
              className={cn(
                'text-sm text-gray-500 no-underline hover:text-blue-600 dark:text-gray-400 dark:hover:text-blue-300 lg:text-base',
                {
                  'font-medium': !solo,
                  'text-lg lg:text-lg': solo
                }
              )}
              data-test="breadcrumb"
            >
              {text}
            </LinkTag>
          );
        }

        return (
          <span
            className={cn('text-sm text-gray-900 dark:text-gray-200 lg:text-base', {
              'font-medium': !solo,
              'text-lg lg:text-lg': solo
            })}
            data-test="breadcrumb"
          >
            {text}
          </span>
        );
      }}
    />
  );
};
