import React from 'react';
import { Button } from '@blueprintjs/core';

import { govlyTableCSVExport } from './govlyTableCSVExport';
import { useGovlyTableContext } from './GovlyTableContext';
import { useDeviceWidth } from '@/app/hooks/useDeviceWidth';

export const GovlyTableCSVExportButton = ({ filename }: { filename?: string }) => {
  const { isDesktop } = useDeviceWidth();
  const { table, isLoading } = useGovlyTableContext();

  if (!isDesktop) return null;

  return (
    <Button disabled={isLoading} outlined icon="import" onClick={() => govlyTableCSVExport(table, filename)}>
      Download CSV
    </Button>
  );
};
