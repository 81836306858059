import React, { useRef, forwardRef } from 'react';
import { mergeRefs } from '@blueprintjs/core';

export type FileInputButtonProps = React.InputHTMLAttributes<HTMLInputElement> & {
  onFileChange: (files: File[]) => void;
  children: React.ReactElement<{ onClick?: (e: React.MouseEvent) => void; disabled?: boolean }>;
};

export const FileInputTarget = forwardRef<HTMLInputElement, FileInputButtonProps>(
  ({ onFileChange, children, ...inputProps }, ref) => {
    const inputRef = useRef<HTMLInputElement>(null);
    const mergedRef = ref ? mergeRefs(ref, inputRef) : inputRef;

    return (
      <>
        <input
          {...inputProps}
          className="hidden"
          ref={mergedRef}
          type="file"
          multiple
          onChange={e => onFileChange(e.currentTarget.files ? Array.from(e.currentTarget.files) : [])}
        />
        {React.isValidElement(children) &&
          React.cloneElement(children, {
            disabled: inputProps?.disabled === true || children.props.disabled === true,
            onClick: (e: React.MouseEvent) => {
              e.preventDefault();
              inputRef.current?.click();
              children.props.onClick?.(e);
            }
          })}
      </>
    );
  }
);
FileInputTarget.displayName = 'FileInputTarget';
