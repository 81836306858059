import React from 'react';
import { Tag } from '@blueprintjs/core';

import { useGetOrganizationLabelsQuery } from '@/api/organizationLabelsApi';
import { LabelTag } from '@/app/organisms/LabelTag/LabelTag';
import { OrganizationLabel } from '@/types/__generated__/GovlyApi';

type LabelListProps = {
  labels: OrganizationLabel[];
  labelIds?: string[];
  onAdd?: () => void;
};

export const LabelList = ({ labels, labelIds, onAdd }: LabelListProps) => {
  const { data: organizationLabels = [], isLoading: labelsLoading } = useGetOrganizationLabelsQuery(undefined, {
    skip: !labelIds || !!labels?.length
  });

  let displayLabels = labels || [];

  if (labelIds) {
    displayLabels = organizationLabels.filter(({ id }) => labelIds.includes(id));
  }

  if (!displayLabels?.length && !onAdd) {
    return null;
  }

  return (
    <div className="flex flex-wrap gap-2 w-fit">
      {displayLabels.map(({ id, name, color }) => (
        <LabelTag key={`tagging-${id}`} name={name} color={color} />
      ))}
      {onAdd && (
        <Tag minimal round interactive={!labelsLoading} icon="plus" {...(labelsLoading ? {} : { onClick: onAdd })}>
          Add labels
        </Tag>
      )}
    </div>
  );
};
