import React from 'react';
import { Form, Formik } from 'formik';
import { Button } from '@blueprintjs/core';
import { match } from 'ts-pattern';
import { SurveyQuestionType } from 'posthog-js';
import { PostHog } from 'posthog-js/react';

import { Card, CardBody, CardFooter } from '@/app/atoms/Card/Card';
import { TextAreaInput } from '@/app/atoms/inputs/TextAreaInput/TextAreaInput';

export type SurveyFormProps = {
  survey: Survey;
  handleSubmit: (values: Record<SurveyResponseKey, string>) => void;
  handleCancel: () => void;
};

export const SurveyForm = ({ survey, handleSubmit, handleCancel }: SurveyFormProps) => {
  return (
    <Formik<Record<SurveyResponseKey, string>>
      initialValues={survey.questions.reduce(
        (acc, _, index) => {
          acc[getQuestionKey(index)] = '';
          return acc;
        },
        {} as { [k in SurveyResponseKey]: string }
      )}
      onSubmit={handleSubmit}
    >
      <Form>
        <Card title={survey?.name} rightElement={<Button minimal icon="cross" onClick={handleCancel} />}>
          <CardBody>
            {survey.questions.map((question, index) =>
              match(question)
                .with({ type: SurveyQuestionType.Open }, ({ question, optional }) => (
                  <TextAreaInput
                    key={index}
                    name={getQuestionKey(index)}
                    label={question}
                    labelInfo={optional ? '(optional)' : ''}
                  />
                ))
                .otherwise(() => null)
            )}
          </CardBody>
          <CardFooter>
            <Button intent="primary" type="submit">
              Submit
            </Button>
            <Button onClick={handleCancel}>Cancel</Button>
          </CardFooter>
        </Card>
      </Form>
    </Formik>
  );
};

function getQuestionKey(index: number): SurveyResponseKey {
  return index === 0 ? '$survey_response' : `$survey_response_${index}`;
}

export type Survey = Parameters<PostHog['getActiveMatchingSurveys']>[0] extends (args: infer T) => unknown
  ? T extends Array<infer Survey>
    ? Survey
    : never
  : never;
export type SurveyResponseKey = '$survey_response' | `$survey_response_${number}`;
