import React, { useMemo } from 'react';
import { useParams } from 'react-router';
import { orderBy } from 'lodash-es';

import { match, Pattern } from 'ts-pattern';
import { addSeconds } from 'date-fns';
import { formatEvent, groupMergeableActivities } from './OppWorkspaceTimelineUtils';
import { useGetOppQuery } from '@/api/oppsApi';
import { useGetOppWorkspaceQuery } from '@/api/oppWorkspacesApi';
import { Timeline } from '@/app/molecules/Timeline/Timeline';
import { OppWorkspaceComment } from '@/app/organisms/OppWorkspaceComment/OppWorkspaceComment';
import { QuoteSourceShow } from '@/types/__generated__/GovlyApi';
import { OppWorkspaceQuoteListItem } from '@/app/organisms/OppWorkspaceQuoteForm/OppWorkspaceQuoteListItem';

export const OppWorkspaceTimeline = ({
  oppId: oppIdProp,
  workspaceId: workspaceIdProp,
  quotes
}: {
  oppId?: string;
  workspaceId?: string;
  quotes: QuoteSourceShow[];
}) => {
  const { oppId: oppIdParam, workspaceId: workspaceIdParam } = useParams();
  const oppId = oppIdProp ?? oppIdParam;
  const workspaceId = workspaceIdProp ?? workspaceIdParam;

  const {
    data: { activities: oppActivities = [], organizationOpp: { activities: organizationOppActivities = [] } = {} } = {}
  } = useGetOppQuery({ id: oppId ?? '' }, { skip: !oppId });

  const { data: { activities = [], comments = [] } = {}, isLoading: workspaceLoading } = useGetOppWorkspaceQuery(
    { id: workspaceId ?? '' },
    { skip: !workspaceId }
  );

  const timelineItems = useMemo(() => {
    // The quote creation events happen slightly after the quote creation
    // but we want the quote block to show up as later in time (closer to the top in our case)
    const offsetQuotes: typeof quotes = quotes.map(q => ({
      ...q,
      createdAt: addSeconds(new Date(q.createdAt), 5).toISOString()
    }));

    const sorted = orderBy(
      [...activities, ...oppActivities, ...organizationOppActivities, ...comments, ...offsetQuotes],
      ['createdAt'],
      ['desc']
    );
    const grouped = groupMergeableActivities(sorted);

    return grouped
      .map(event => {
        return match(event)
          .with({ message: Pattern.string.includes('posted a new comment') }, () => undefined)
          .with({ __typename: 'QuoteSourceShow' }, quote => <OppWorkspaceQuoteListItem key={quote.id} quote={quote} />)
          .with({ __typename: 'CommentIndex' }, comment => (
            <OppWorkspaceComment key={comment.id} comment={comment} oppId={oppId ?? ''} />
          ))
          .with({ __typename: 'ActivityExtended' }, event => formatEvent(event))
          .exhaustive();
      })
      .filter(event => event !== undefined);
  }, [quotes, activities, oppActivities, organizationOppActivities, comments, oppId]);

  if (workspaceLoading) {
    return null;
  }

  return <Timeline events={timelineItems} />;
};
