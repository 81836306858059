import React from 'react';
import { ErrorBoundary, ErrorBoundaryProps } from './ErrorBoundary';
import { ApplicationError } from '@/app/atoms/ErrorFallback/ApplicationError';

type ApplicationErrorBoundaryProps = Omit<ErrorBoundaryProps, 'fallback' | 'action'> & {
  fallbackProps?: React.ComponentProps<typeof ApplicationError>;
  action?: string;
};

export const ApplicationErrorBoundary = (props: ApplicationErrorBoundaryProps) => {
  return (
    <ErrorBoundary
      {...props}
      action={props.action ?? 'ApplicationErrorBoundary'}
      fallback={<ApplicationError {...props.fallbackProps} />}
    />
  );
};
