import React from 'react';

export type ImageWithFallbackProps = {
  src: string;
  children?: React.ReactNode;
} & React.ImgHTMLAttributes<HTMLImageElement>;

export const ImageWithFallback = ({ src, children, ...rest }: ImageWithFallbackProps) => {
  const [fallback, setFallback] = React.useState(false);

  const handleFallback = () => {
    setFallback(true);
  };

  if (fallback) {
    return <>{children}</>;
  }

  return <img src={src} onError={handleFallback} {...rest} />;
};
