import { camelCase } from 'lodash-es';

import { rootApi } from './rootApi';
import {
  ContactSearch as APIContactSearch,
  ContactIndex as APIContactIndex,
  SearchQuery,
  SearchQueryMeta
} from '@/types/__generated__/GovlyApi';
import { useSearchPagination } from '@/app/hooks/useSearchPagination';
import { extractAggregations, ExtractAggregationsData } from '@/app/lib/aggregationExtraction';
import { ContactSearchFilters } from '@/app/hooks/search/useContactSearchCache';

const api = rootApi.enhanceEndpoints({ addTagTypes: ['Contact'] });

type GetContacts = {
  params: { ids: string[] };
  results: APIContactIndex[];
};

type GetContact = {
  params: { id: string };
  result: APIContactSearch;
};

type ContactSearch = {
  params: { query: string; page?: number; per?: number } & ContactSearchFilters;
  result: ContactSearch['response'] & { query: ContactSearch['params'] };
  response: {
    results: APIContactSearch[];
    meta: SearchQueryMeta;
  };
};

type ContactAggregation = {
  params: { [key: string]: string | number | boolean };
  result: ReturnType<typeof extractAggregations>;
  response: SearchQuery & ExtractAggregationsData;
};

type ContactExport = {
  params: { [key: string]: string | number | boolean };
  result: void;
};

export const contactsApi = api.injectEndpoints({
  endpoints: build => ({
    getContacts: build.query<GetContacts['results'], GetContacts['params']>({
      query: params => ({
        url: `/v2/contacts`,
        params
      }),
      providesTags: ['Contact']
    }),
    getContact: build.query<GetContact['result'], GetContact['params']>({
      query: ({ id }) => ({
        url: `/v2/contacts/${id}`
      })
    }),
    contactSearch: build.mutation<ContactSearch['result'], ContactSearch['params']>({
      query: body => ({
        url: '/v2/contacts/search',
        method: 'POST',
        body
      }),
      transformResponse: (response: ContactSearch['response'], _meta, arg) => ({ query: arg, ...response })
    }),
    contactAggregation: build.query<ContactAggregation['result'], ContactAggregation['params']>({
      query: params => ({
        url: `/v2/contacts/aggregations`,
        params
      }),
      transformResponse: (response: ContactAggregation['response'], _meta, { aggs }) =>
        extractAggregations(response, camelCase(Array.isArray(aggs) ? aggs[0] : aggs.toString()))
    }),

    contactExport: build.mutation<ContactExport['result'], ContactExport['params']>({
      query: body => ({
        url: `/v2/contacts/export`,
        method: 'POST',
        body
      })
    })
  })
});

export const useContactSearchPagination = () =>
  useSearchPagination({
    mutation: useContactSearchMutation,
    cacheKey: 'contact-search'
  });

export const {
  useGetContactsQuery,
  useGetContactQuery,
  useContactSearchMutation,
  useContactAggregationQuery,
  useContactExportMutation
} = contactsApi;
