export const AcceptedFileTypes = {
  ...Object.fromEntries(
    ['.pdf', '.json', '.doc', '.docx', '.xls', '.xlsx', '.xml', '.json'].map(ext => [
      `application/${ext.slice(1)}`,
      [ext]
    ])
  ),
  'image/*': ['.jpg', '.jpeg', '.png'],
  'text/*': ['.html', '.csv', '.txt', '.xml'],
  'application/vnd.ms-excel': [], // .xls
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': [], // .xlsx
  'application/msword': [], // .doc
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document': [] // .docx
};
