import React, { useState, useEffect, useCallback } from 'react';
import { Callout } from '@blueprintjs/core';
import { differenceInDays, differenceInHours, differenceInMinutes, differenceInSeconds } from 'date-fns';

export const ExpirationCountdownCallout = ({ expirationDate }: { expirationDate: string | undefined }) => {
  const calculateTimeLeft = useCallback(() => {
    if (!expirationDate) {
      return {};
    }

    const now = new Date();
    const expiration = new Date(expirationDate);

    if (expiration > now) {
      return {
        days: differenceInDays(expiration, now),
        hours: differenceInHours(expiration, now) % 24,
        minutes: differenceInMinutes(expiration, now) % 60,
        seconds: differenceInSeconds(expiration, now) % 60
      };
    }

    return {};
  }, [expirationDate]);

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft);

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearInterval(timer);
  }, [calculateTimeLeft]);

  const expired = timeLeft.days === undefined;
  if (expired) {
    return;
  }

  const danger = timeLeft.days === 0 && timeLeft.hours < 1;
  const warning = timeLeft.hours && timeLeft.days < 3;

  const intent = danger ? 'danger' : warning ? 'warning' : 'success';

  return (
    <Callout icon="stopwatch" intent={intent} title="Time Left to Respond">
      <div className="flex space-x-2">
        <span>{timeLeft.days}d</span>
        <span>{timeLeft.hours}h</span>
        <span>{timeLeft.minutes}m</span>
        <span>{timeLeft.seconds}s</span>
      </div>
    </Callout>
  );
};
