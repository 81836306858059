import { useSearchParams, useNavigate } from 'react-router';
import { DRAWER_PARAMS } from '@/app/organisms/AppDrawer/constants';

export const useGoToTab = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const inDrawer = Boolean(searchParams.get(DRAWER_PARAMS.id));
  const navigate = useNavigate();

  return ({ drawerTab, route }: { drawerTab: string; route: string }) => {
    if (inDrawer) {
      setSearchParams({ ...Object.fromEntries(searchParams), [DRAWER_PARAMS.tab]: drawerTab });
    } else {
      navigate(route);
    }
  };
};
