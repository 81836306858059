import React from 'react';
import { useGetWorkspaceAssistantThreadsQuery } from '@/api/workspaceAssistantThreadsApi';
import { useAssistantTypeConfig } from '@/app/organisms/Assistant/AssistantProvider';
import { AssistantThreadHistory, AssistantThreadHistoryItem } from '@/app/organisms/Assistant/AssistantThreadHistory';

export const OppWorkspaceAssistantThreadHistory = () => {
  const { workspaceId } = useAssistantTypeConfig('workspace');
  const { data: threads = [], isLoading } = useGetWorkspaceAssistantThreadsQuery({ workspaceId });

  return (
    <AssistantThreadHistory isLoading={isLoading}>
      {threads.map(thread => (
        <AssistantThreadHistoryItem key={thread.id} thread={thread} />
      ))}
    </AssistantThreadHistory>
  );
};
