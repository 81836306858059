import React from 'react';
import { Link } from 'react-router';
import { Logo } from '@/images/components/Logo';
import { LinkedInLogo } from '@/images/components/LinkedInLogo';
import { SocII } from '@/images/components/SocII';

type Columns = 'company' | 'support' | 'legal';
type FooterContentType = {
  tagline: string;
  copyright: string;
  copyrightYear: string;
  columns: Columns[];
  navigation: { [key in Columns]: { text: string; href?: string; to?: string; rel?: string }[] };
  socials: { name: string; href: string; icon: React.FC<React.SVGProps<SVGSVGElement>> }[];
};

const FooterContent: FooterContentType = {
  tagline: 'We help governments and their suppliers work together to deliver the best products.',
  copyright: 'Govly, Inc. All rights reserved.',
  copyrightYear: '2024',
  columns: ['company', 'support', 'legal'],
  navigation: {
    company: [
      { text: 'About', href: 'https://www.govly.com/about' },
      { text: 'Resources', href: 'https://www.govly.com/resources' },
      { text: 'Vendors', to: '/vendors' },
      { text: 'Solutions', href: 'https://www.govly.com/solutions' },
      { text: 'Careers', href: 'https://www.govly.com/careers' }
    ],
    support: [
      { text: 'Sign In', to: '/sign_in' },
      { text: 'Request a Demo', href: 'https://www.govly.com/book-a-demo' },
      { text: 'Contact', href: 'https://www.govly.com/contact' },
      { text: 'Status', href: 'https://status.govly.com' }
    ],
    legal: [
      {
        text: 'Privacy',
        href: 'https://www.govly.com/privacy',
        rel: 'noopener noreferrer'
      },
      {
        text: 'Terms',
        href: 'https://www.govly.com/terms',
        rel: 'noopener noreferrer'
      }
    ]
  },
  socials: [
    {
      name: 'LinkedIn',
      href: 'https://www.linkedin.com/company/govly/',
      icon: (props: React.SVGProps<SVGSVGElement>) => <LinkedInLogo {...props} />
    }
  ]
};

export const MarketingFooter = () => {
  const { columns, navigation, socials, tagline, copyright, copyrightYear } = FooterContent;

  return (
    <footer className="bg-white" aria-labelledby="footer-heading">
      <div className="md:px-auto mx-8 max-w-7xl py-12 px-4 sm:px-6 md:mx-auto lg:py-10 lg:px-10 2xl:px-0">
        <div>
          <div className="mb-2 space-y-8 md:mb-8 xl:col-span-1">
            <Logo />
            <p className="text-base text-gray-500">{tagline}</p>
          </div>
          <div className="mt-2 grid grid-cols-2 md:flex md:flex-wrap xl:mt-0">
            {columns.map((column, i) => (
              <div key={i} className="mr-6 mt-12 w-1/2 md:mt-0 md:w-1/5">
                <h3 className="text-sm font-semibold uppercase tracking-wider text-gray-400">{column}</h3>
                <ul role="list" className="mt-4 space-y-4">
                  {navigation[column].map(({ text, ...rest }, i) => (
                    <li key={i}>
                      {rest.to ? (
                        <Link
                          to={rest.to}
                          {...rest}
                          className="text-base text-gray-500 no-underline hover:text-gray-900"
                        >
                          {text}
                        </Link>
                      ) : (
                        <a {...rest} className="text-base text-gray-500 no-underline hover:text-gray-900">
                          {text}
                        </a>
                      )}
                    </li>
                  ))}
                </ul>
              </div>
            ))}
          </div>
          <div className="mt-12 flex h-16">
            <SocII className="object-fit" />
          </div>
        </div>
        <div className="mt-12 border-t border-gray-200 pt-8">
          <div className="flex items-stretch justify-between  ">
            <p className="text-base text-gray-400 xl:text-center">
              &copy; {copyrightYear} {copyright}
            </p>
            {socials.map(item => (
              <a key={item.name} href={item.href} className="text-gray-400 hover:text-gray-500">
                <span className="sr-only">{item.name}</span>
                <item.icon className="h-8 w-8" aria-hidden="true" />
              </a>
            ))}
          </div>
        </div>
      </div>
    </footer>
  );
};
