import React from 'react';
import { useParams, useSearchParams } from 'react-router';
import type { DrawerNavTab, NavItem } from '@/app/organisms/AuthenticatedNav/AuthenticatedNavItemsUtils';
import { DRAWER_PARAMS } from '@/app/organisms/AppDrawer/constants';
import { useOppDetailsProviders } from '@/app/pages/OppDetailsProvidersPage/useOppDetailsProvidersPage';

export type OppProvidersTabProps = {
  node: NavItem | DrawerNavTab;
  index: number;
};

export const OppProvidersTagContent = (_: OppProvidersTabProps) => {
  const params = useParams();
  const [searchParams] = useSearchParams();
  const id = params.id ?? searchParams.get(DRAWER_PARAMS.id) ?? '';
  const { providerPartnerships } = useOppDetailsProviders({ id });

  const count = providerPartnerships?.length ?? 0;
  return count > 0 ? <span>{count}</span> : null;
};
