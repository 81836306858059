import { type FetchBaseQueryError } from '@reduxjs/toolkit/query/react';

export function isFetchBaseQueryError(error: unknown): error is FetchBaseQueryError {
  if (typeof error !== 'object' || error === null) return false;

  if (
    'status' in error &&
    (typeof error.status === 'number' || typeof error.status === 'string') &&
    ('data' in error || 'error' in error)
  )
    return true;

  return false;
}

export function hasDataErrors(
  error: FetchBaseQueryError
): error is FetchBaseQueryError & { data: { errors: unknown } } {
  return typeof error.data === 'object' && error.data !== null && 'errors' in error.data;
}

/**
 * Useful for createOrUpdate type mutations, where we want to update if the object has an id, and create if it doesn't.
 */
export function hasId<T extends { id?: string }>(
  obj: T
): obj is T & {
  id: string;
} {
  return typeof obj.id === 'string';
}
