import { rootApi } from './rootApi';
import { WorkspaceBase } from '@/types/__generated__/GovlyApi';

const api = rootApi.enhanceEndpoints({ addTagTypes: ['Workspace', 'Opp', 'Worklist', 'Contact', 'USASpendingAward'] });

type CreateWorkspace = {
  params: { workableType?: string; workableId?: string; oppId?: string; notifyIds?: string[] } & Partial<
    Pick<WorkspaceBase, 'name' | 'privateAccess' | 'workflowStage' | 'workableType' | 'organizationDefault'>
  >;
  result: WorkspaceBase;
};

type UpdateWorkspace = {
  params: { id: string; notifyIds: string[] } & Partial<
    Pick<WorkspaceBase, 'id' | 'name' | 'privateAccess' | 'workflowStage' | 'archivedAt'>
  >;
  result: WorkspaceBase;
};

export const workspacesApi = api.injectEndpoints({
  endpoints: build => ({
    createWorkspace: build.mutation<CreateWorkspace['result'], CreateWorkspace['params']>({
      query: body => ({
        url: `/v2/workspaces`,
        method: 'POST',
        body
      }),
      invalidatesTags: result =>
        result?.workableType === 'Opp'
          ? [
              { type: 'Workspace', id: result.id },
              { type: 'Opp', id: result.workableId }
            ]
          : result?.workableType
            ? [result?.workableType]
            : ['Workspace']
    }),

    updateWorkspace: build.mutation<UpdateWorkspace['result'], UpdateWorkspace['params']>({
      query: ({ id, ...body }) => ({
        url: `/v2/workspaces/${id}`,
        method: 'PATCH',
        body
      }),
      invalidatesTags: result =>
        result?.workableType === 'Opp'
          ? [{ type: 'Workspace', id: result?.id }, { type: 'Opp', id: result?.workableId }, { type: 'Worklist' }]
          : result?.workableType
            ? [result?.workableType]
            : []
    })
  })
});

export const { useCreateWorkspaceMutation, useUpdateWorkspaceMutation } = workspacesApi;
