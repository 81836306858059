import { IconName } from '@blueprintjs/core';
import { match, P } from 'ts-pattern';
import { DATETIME_24_SHORT, formatTime } from '../../lib/dates';
import { OppShow, OppSourceWithSource } from '@/types/__generated__/GovlyApi';

interface LLMChange {
  summaryOfChanges: string;
  summaryOfMostRecentChange: string;
  attachmentsChangedInMostRecentNotice: boolean;
  attachmentsAddedInMostRecentNotice: boolean;
  mostUpToDateAttachments: Array<{ filename: string; checksum: string }>;
  digest: string;
}

export interface OppSourceTimelineEvent {
  icon: IconName;
  iconBgClass: string;
  iconTextClass: string;
  formattedCreatedAt: string;
  summary: string;
  newAttachmentsCount: number;
  updatedAttachmentsCount: number;
}

export const formatOppSourceEvent = (
  oppSource: OppSourceWithSource,
  opp: OppShow
): OppSourceTimelineEvent & OppSourceWithSource => {
  const { createdAt, digest } = oppSource;

  const isFirstSource = opp.oppSources?.[0]?.id === oppSource.id;

  const summary = match({ isFirstSource, fullSummary: opp.llmFullSummary?.summary })
    .with({ isFirstSource: true, fullSummary: P.not(P.nullish) }, ({ fullSummary }) => String(fullSummary))
    .otherwise(() => {
      const llmChange = (opp.llmChanges as LLMChange[] | undefined)?.find(change => change.digest === digest);
      return llmChange?.summaryOfChanges || '';
    });

  const newAttachmentsCount = oppSource.attachmentsHistory?.filter(a => a.status === 'new').length ?? 0;
  const updatedAttachmentsCount = oppSource.attachmentsHistory?.filter(a => a.status === 'updated').length ?? 0;

  const customEventProps: OppSourceTimelineEvent = {
    icon: isFirstSource ? 'document' : 'history',
    iconBgClass: isFirstSource ? 'bg-green-500' : 'bg-blue-500',
    iconTextClass: 'text-white',
    formattedCreatedAt: formatTime(createdAt, DATETIME_24_SHORT) ?? '',
    summary,
    newAttachmentsCount,
    updatedAttachmentsCount
  };

  return {
    ...oppSource,
    ...customEventProps
  };
};

export const getFormattedOppSources = (
  opp: OppShow,
  desc: boolean,
  search: string
): (OppSourceTimelineEvent & OppSourceWithSource)[] => {
  const oppSources = opp.oppSources || [];
  const formattedOppSources = oppSources.map(oppSource => formatOppSourceEvent(oppSource, opp));
  const sortedOppSources = desc ? formattedOppSources : formattedOppSources.reverse();
  const lowercaseSearch = search.toLowerCase();
  const filteredOppSources = search
    ? sortedOppSources.filter(
        oppSource =>
          oppSource.summary.toLowerCase().includes(lowercaseSearch) ||
          oppSource.title?.toLowerCase().includes(lowercaseSearch) ||
          oppSource.noticeType?.toLowerCase().includes(lowercaseSearch)
      )
    : sortedOppSources;

  return filteredOppSources;
};
