import { IconProps } from '@blueprintjs/core';
import { match } from 'ts-pattern';

export const getFileExtensionIconProps = (fileName?: string) => {
  return match<string | undefined, IconProps>(fileName?.split('.').pop())
    .with('pdf', () => ({ icon: 'document', intent: 'danger' }))
    .with('doc', 'docx', () => ({ icon: 'paragraph', intent: 'primary' }))
    .with('csv', 'xlsx', () => ({ icon: 'th', intent: 'success' }))
    .with('mov', 'mp4', () => ({ icon: 'video', intent: 'warning' }))
    .with('png', 'jpg', 'jpeg', 'gif', 'bmp', 'tiff', 'webp', () => ({
      icon: 'media',
      intent: 'warning'
    }))
    .otherwise(() => ({ icon: 'document' }));
};
