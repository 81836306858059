import { useEffect, useState } from 'react';

export const useDeviceWidth = () => {
  const [width, setWidth] = useState(window.innerWidth);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleWindowSizeChange = () => {
    setWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, [handleWindowSizeChange]);

  return {
    width,
    isMobile: width < 768,
    isTablet: width >= 768 && width <= 1024,
    isDesktop: width > 1024
  };
};
