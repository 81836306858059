import { Option } from 'space-monad';
import { createStore } from 'zustand';
import { createStoreSelectors } from '@/app/lib/createSelectors';
import { LlmAssistantMessageBreakout } from '@/types/__generated__/GovlyApi';

export type Message = LlmAssistantMessageBreakout & { isPartial?: boolean };

type Store = {
  threads: { [threadId: string]: Message[] };
  filesProcessing: boolean;
  welcomeMessage: string;
  threadId: string | null;
  isThreadHistoryOpen: boolean;
};

export const createAssistantStore = (state: Partial<Store> = {}) => {
  const store = createStore<Store>()(() => {
    return {
      threads: {},
      filesProcessing: false,
      welcomeMessage: '',
      threadId: null,
      isThreadHistoryOpen: false,
      ...state
    };
  });

  return createStoreSelectors(store);
};

export const toggleIsThreadHistoryOpen = (store: AssistantStoreRef, isOpen?: boolean) =>
  store.setState(state => ({ isThreadHistoryOpen: isOpen ?? !state.isThreadHistoryOpen }));

export const selectThreadHistoryItem = (store: AssistantStoreRef, opts: { threadId: string; isDesktop: boolean }) =>
  store.setState({
    threadId: opts.threadId,
    isThreadHistoryOpen: opts.isDesktop ? true : false
  });

export const createNewThread = async (
  store: AssistantStoreRef,
  payload: {
    welcomeMessage: string;
    createThreadPromise: Promise<{ id: string }>;
  }
) => {
  const { welcomeMessage, createThreadPromise } = payload;
  store.setState({ threadId: null, welcomeMessage });
  const { id } = await createThreadPromise;
  store.setState({ threadId: id });
};

const makeEmptyMessage = (externalId: string): Message => {
  return {
    __typename: 'LlmAssistantMessageBreakout',
    id: '',
    externalId,
    role: 'assistant',
    content: '',
    postedAt: '',
    isPartial: true
  };
};

type ThreadAction = {
  makeEmptyMessage: (externalId: string) => Message;
  removeMessageByExternalId: (externalId: string) => void;
  setMessages: (messages: Message[]) => void;
  updateMessage: (externalId: string, message: Partial<Message>) => void;
  updateMessageDelta: (externalId: string, content: string) => void;
};

export const makeAssistantThreadActions: (store: AssistantStoreRef, threadId: string) => ThreadAction = (
  store,
  threadId
) => ({
  makeEmptyMessage,

  setMessages: (messages: Message[]) => {
    store.setState(state => ({
      threads: { ...state.threads, [threadId]: messages }
    }));
  },

  updateMessageDelta: (externalId: string, content: string) => {
    const state = store.getState();
    const messagesHistory = state.threads[threadId] || [];
    const extant = messagesHistory.find(m => m.externalId === externalId);

    if (extant) {
      store.setState(state => upsertInHistory(state, threadId, externalId, { content: extant.content + content }));
    }
  },

  updateMessage: (externalId: string, message: Partial<Message>) => {
    store.setState(state => upsertInHistory(state, threadId, externalId, message));
  },

  removeMessageByExternalId: (externalId: string) => {
    store.setState(state => {
      const messagesHistory = state.threads[threadId] || [];
      const updatedMessages = messagesHistory.filter(message => message.externalId !== externalId);

      return {
        threads: {
          ...state.threads,
          [threadId]: updatedMessages
        }
      };
    });
  }
});

const upsertInHistory = (state: Store, threadId: string, externalId: string, partialMessage: Partial<Message>) => {
  const messagesHistory = state.threads[threadId] || [];
  const extantIdx = messagesHistory.findIndex(m => m.externalId === externalId);

  if (extantIdx >= 0) {
    const extant = messagesHistory[extantIdx];

    return {
      threads: {
        ...state.threads,
        [threadId]: [
          ...messagesHistory.slice(0, extantIdx),
          { ...extant, ...partialMessage },
          ...messagesHistory.slice(extantIdx + 1)
        ]
      }
    };
  } else {
    return {
      threads: {
        ...state.threads,
        [threadId]: [...messagesHistory, partialMessage as Message]
      }
    };
  }
};

export function getThreadMessages(store: Store, threadId: string | null) {
  return Option(threadId)
    .map(id => store.threads[id])
    .getOrElse([]);
}

export const getThreadActions = (threadId: string | null, store: AssistantStoreRef): ThreadAction => {
  if (!threadId) {
    return {
      updateMessage: () => {},
      updateMessageDelta: () => {},
      removeMessageByExternalId: () => {},
      setMessages: () => {},
      makeEmptyMessage
    };
  }

  return makeAssistantThreadActions(store, threadId);
};

export type AssistantStoreRef = ReturnType<typeof createAssistantStore>;
