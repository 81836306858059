import { useEffect, useState } from 'react';
import { UIMatch, useMatches } from 'react-router';
import { BreadcrumbProps } from '@/app/molecules/Breadcrumbs/Breadcrumbs';

export type CrumbHandle<Data> = { crumbs: (data: Data) => Promise<BreadcrumbProps[]> };

export function makeCrumbHandle<Data>(
  getBreadcrumbs: (d: Data) => Promise<BreadcrumbProps[]>
): CrumbHandle<Data> | undefined {
  return { crumbs: getBreadcrumbs };
}

type CrumbMatch<Data> = UIMatch<Data, CrumbHandle<Data>>;

function isCrumbMatch<Data>(match: UIMatch<unknown>): match is CrumbMatch<Data> {
  return match.handle != null && typeof match.handle === 'object' && 'crumbs' in match.handle;
}

export const useBreadcrumbs = () => {
  const matches = useMatches();
  const [state, setState] = useState<{ crumbs: BreadcrumbProps[]; isLoading: boolean }>({
    crumbs: [],
    isLoading: false
  });

  useEffect(() => {
    const crumbMatches = matches.filter(isCrumbMatch);

    if (crumbMatches.length > 0) {
      setState({ crumbs: [], isLoading: true });

      Promise.all(crumbMatches.map(match => match.handle.crumbs(match.data)))
        .then(crumbs => {
          setState({
            crumbs: crumbs.flat().map((crumb, index) => ({ ...crumb, index })),
            isLoading: false
          });
        })
        .catch(() => {
          /**
           * As a hack to prevent breadcrumbs from loading twice in the case of URL redirects
           * (e.g. when navigating to award page with a unique contract number instead of an id).
           * We don't want to show the loading state twice, so we throw an error here to prevent
           * it from rendering temporarily
           */
        });
    } else {
      setState({ crumbs: [], isLoading: false });
    }
  }, [matches]);

  return state;
};
