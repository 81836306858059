import React, { createContext } from 'react';

type AppLayoutContextValue = {
  appBodyRef: React.RefObject<HTMLDivElement>;
  topNavRef?: React.RefObject<HTMLDivElement>;
};

const AppLayoutContext = createContext<AppLayoutContextValue | undefined>(undefined);

export const useAppLayoutContext = () => {
  const context = React.useContext(AppLayoutContext);
  if (!context) {
    throw new Error('useAppLayoutContext must be used within a AppLayoutProvider');
  }
  return context;
};

export const AppLayoutProvider = ({
  children,
  appBodyRef,
  topNavRef
}: React.PropsWithChildren<AppLayoutContextValue>) => {
  return <AppLayoutContext.Provider value={{ appBodyRef, topNavRef }}>{children}</AppLayoutContext.Provider>;
};
