import React from 'react';

import { MainColumn } from '@/app/atoms/MainColumn/MainColumn';
import { AwardDetails } from '@/app/organisms/AwardDetails/AwardDetails';
import { AwardDetailsAdditional } from '@/app/organisms/AwardDetails/AwardDetailsAdditional';
import { useGetAwardsQuery } from '@/api/awardsApi';
import { WorkableDetailsActions } from '@/app/organisms/WorkableDetailsActions/WorkableDetailsActions';
import { USASpendingAwardIndex } from '@/types/__generated__/GovlyApi';
import { useMetaTags } from '@/app/hooks/useMetaTags';

export type AwardDetailsOverviewContentProps = {
  id: string;
  className?: string;
};

export const AwardDetailsOverviewContent = ({ id }: AwardDetailsOverviewContentProps) => {
  const { indexAward = {} as USASpendingAwardIndex } = useGetAwardsQuery(
    { ids: [id] },
    {
      selectFromResult: ({ data = [] }) => ({
        indexAward: data.find(({ id: awardId }) => awardId === id)
      })
    }
  );

  useMetaTags(
    {
      title: indexAward?.title ? indexAward?.title : 'Award Details'
    },
    [indexAward]
  );

  return (
    <>
      <MainColumn className="lg:hidden">
        <WorkableDetailsActions resource={indexAward} />
        <AwardDetails id={id} />
        <AwardDetailsAdditional id={id} />
      </MainColumn>

      <MainColumn columnSpan={8} className="hidden lg:block">
        <AwardDetails id={id} />
      </MainColumn>

      <MainColumn columnSpan={4} className="hidden lg:block">
        <WorkableDetailsActions resource={indexAward} />
        <AwardDetailsAdditional id={id} />
      </MainColumn>
    </>
  );
};
