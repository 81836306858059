import React from 'react';
import { AnchorButton } from '@blueprintjs/core';
import { useGetAwardQuery } from '@/api/awardsApi';
import { CardList, CardListItem, CardListItemProps } from '@/app/molecules/CardList/CardList';
import { DescriptionListField } from '@/app/molecules/DescriptionListField/DescriptionListField';
import { CardHeading } from '@/app/atoms/Typography/Typography';
import { formatAddress } from '@/app/lib/address';
import { CopyButton } from '@/app/molecules/CopyButton/CopyButton';

const cardListItemProps: CardListItemProps = {
  collapsible: true,
  compact: true,
  defaultIsOpen: true,
  titleRenderer: props => <CardHeading {...props} className="text-base" />,
  className: 'space-y-2'
};

const OFFICER_NUMBERS = [1, 2, 3, 4, 5] as const;

export const AwardDetailsAdditional = ({ id }: { id: string }) => {
  const { data: award, isLoading } = useGetAwardQuery({ id });
  const isIDV = award?.awardOrIdvFlag === 'IDV';

  if (isLoading || !award) return null;

  const popAddress = formatAddress({
    city: award.primaryPlaceOfPerformanceCityName,
    state: award.primaryPlaceOfPerformanceStateName,
    zip: award.primaryPlaceOfPerformanceZip,
    country: award.primaryPlaceOfPerformanceCountryName
  });

  return (
    <CardList>
      <CardListItem title="Additional Award Details" {...cardListItemProps}>
        <DescriptionListField label="Awarding Agency" value={award.awardingAgencyName} />
        <DescriptionListField label="Recipient" value={award.recipientName} />
        <DescriptionListField
          label="Start Date"
          value={award.periodOfPerformanceStartDate}
          tooltipContent={
            <p>
              <b>Start Date</b> is the start date is the date when the period of performance or the official
              commencement of work or services under the award or contract begins. This is when the recipient is
              expected to begin the activities outlined in the award.
            </p>
          }
        />

        <DescriptionListField
          label="Current End Date"
          value={award.periodOfPerformanceCurrentEndDate}
          tooltipContent={
            <p>
              <b>Current End Date</b> is the date on which the award or contract is scheduled to end based on the most
              recent modifications or extensions. It reflects the current understanding of when the period of
              performance will conclude, without considering any unexercised options or future extensions.
            </p>
          }
        />

        <DescriptionListField
          label="Potential End Date"
          value={award.periodOfPerformancePotentialEndDate}
          tooltipContent={
            <p>
              <b>Potential End Date</b> is the latest possible date that the award or contract may be active, including
              all possible extensions, options, and renewals. It represents the maximum duration over which the contract
              or award could be extended if all available options are exercised.
            </p>
          }
        />

        <DescriptionListField label="Place of Performance" value={popAddress.join(', ')} />
        <DescriptionListField label="Procurement Instrument Identifier (PIID)" value={award.awardIdPiid} />
        <DescriptionListField
          innerClassName="w-full"
          label="Unique Key"
          value={
            award.contractAwardUniqueKey ? (
              <span className="flex gap-px items-center">
                <span className="truncate">{award.contractAwardUniqueKey}</span>
                <CopyButton copyText={award.contractAwardUniqueKey} minimal small />
              </span>
            ) : null
          }
        />
        <DescriptionListField label="Parent Award PIID" value={award.parentAwardIdPiid} />
        <DescriptionListField
          label="Parent Award Unique Key"
          value={
            award.parentAwardUniqueKey ? (
              <span className="flex gap-px items-center">
                <AnchorButton
                  small
                  minimal
                  className="text-blue-500 -ml-2"
                  rightIcon="open-application"
                  href={`/awards/${award.parentAwardUniqueKey}`}
                  target="_blank"
                  rel="noopener noreferrer nofollow"
                >
                  <span className="truncate">{award.parentAwardUniqueKey}</span>
                </AnchorButton>
              </span>
            ) : null
          }
          tooltipContent={
            <>
              <p>
                A parent award, or parent indefinite delivery vehicle (IDV), is any award that has other prime awards
                made under it.
              </p>

              <p>The contract summarized on this page is a “child” prime award of the parent IDV indicated here.</p>

              <p>
                Click on the parent award ID to view the summary page of this award&apos;s parent award, which details
                all of that parent award&apos;s “child” and “grandchild” awards.
              </p>
            </>
          }
        />
        <DescriptionListField
          label="Child Awards"
          value={
            isIDV && (
              <AnchorButton
                small
                minimal
                className="text-blue-500 -ml-2"
                rightIcon="open-application"
                href={`/awards?query=${award.contractAwardUniqueKey}`}
                target="_blank"
                rel="noopener noreferrer nofollow"
              >
                View awards under this IDV
              </AnchorButton>
            )
          }
        />
        <DescriptionListField label="NAICS Code" value={award.naicsCode} />
        <DescriptionListField label="PSC Code" value={award.productOrServiceCode} />
        <DescriptionListField label="Award or IDV" value={award.awardOrIdvFlag} />
        <DescriptionListField
          label="Public Link"
          value={
            award.usaspendingPermalink && (
              <AnchorButton
                small
                minimal
                className="text-blue-500 -ml-2"
                rightIcon="open-application"
                href={award.usaspendingPermalink}
                target="_blank"
                rel="noopener noreferrer nofollow"
              >
                View on USASpending.gov
              </AnchorButton>
            )
          }
        />
      </CardListItem>

      <CardListItem {...cardListItemProps} title="Competition" defaultIsOpen={false}>
        <DescriptionListField label="Solicitation Procedure" value={award.solicitationProcedures} />
        <DescriptionListField label="Extent Competed" value={award.extentCompeted} />
        <DescriptionListField
          label="Other Than Full and Open Competition"
          value={award.otherThanFullAndOpenCompetition}
        />
        <DescriptionListField label="Set-Aside Type" value={award.typeOfSetAside} />
        <DescriptionListField label="Number of Offers Received" value={award.numberOfOffersReceived} />
      </CardListItem>

      <CardListItem {...cardListItemProps} title="Executive Compensation" defaultIsOpen={false}>
        {OFFICER_NUMBERS.map(i => {
          const nameAndAmount = [
            award[`highlyCompensatedOfficer${i}Name`],
            award[`highlyCompensatedOfficer${i}Amount`]
          ].filter(Boolean);
          const value = nameAndAmount.join(' - ') || '-';
          return <DescriptionListField key={i} label={`Officer ${i}`} value={value} />;
        })}
      </CardListItem>
    </CardList>
  );
};
