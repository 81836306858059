import React from 'react';

import { EntityTitle, Icon, Tag } from '@blueprintjs/core';
import { CardList, CardListItem, CardListItemProps, CardListProps } from '@/app/molecules/CardList/CardList';
import { CardHeadingSmall } from '@/app/atoms/Typography/Typography';
import { useGetOppQuery } from '@/api/oppsApi';
import { Loading } from '@/app/atoms/Loading/Loading';
import { ScheduledDateTag } from '@/app/molecules/ScheduledDateTag/ScheduledDateTag';
import { ImageWithFallback } from '@/app/atoms/ImageWithFallback/ImageWithFallback';
import { NotAuthorized } from '@/app/molecules/NotAuthorized/NotAuthorized';
import { GOV_DOMAIN } from '@/app/lib/regex';
import { OppDetailsAnalysis } from '@/app/organisms/OppDetailsAnalysis/OppDetailsAnalysis';
import { OppDetailsDescription } from '@/app/organisms/OppDetailsDescription/OppDetailsDescription';

type SourceData = {
  contacts?: {
    email?: string;
  }[];
  buyer?: {
    hierarchy: string[];
  };
};

type SummaryData = {
  title: string;
};

const cardListItemProps: CardListItemProps = {
  collapsible: true,
  compact: false,
  defaultIsOpen: false,
  titleRenderer: props => <CardHeadingSmall {...props} />,
  className: 'space-y-2 pt-0'
};

export const OppDetailsCardList = ({ id, children }: { id: string; children?: CardListProps['children'] }) => {
  const { data: opp, isLoading, isError } = useGetOppQuery({ id });

  const analysis = (opp?.llmFullSummary || {}) as unknown as SummaryData;

  if (isLoading) {
    return <Loading />;
  }

  if (isError || !opp) {
    return <NotAuthorized />;
  }

  const { isForecasted, displayName, respondBy, cancelledAt, awardedAt, title, latestData } = opp;

  const sourceData = (latestData || {}) as unknown as SourceData;

  const domains =
    sourceData.contacts?.map(contact => contact.email?.split('@')[1]).filter(d => d && GOV_DOMAIN.test(d)) ?? [];
  const buyer = sourceData.buyer ?? opp.buyer;

  return (
    <CardList
      title={<Tag>{displayName}</Tag>}
      rightElement={
        isForecasted ? undefined : (
          <ScheduledDateTag respondBy={respondBy} cancelledAt={cancelledAt} awardedAt={awardedAt} />
        )
      }
      wrapperClassName="[&_.bp5-section-header-left]:py-0 *:border-0"
      className="[&>.bp5-tab-panel>*]:rounded-none"
    >
      <CardListItem
        title={title || analysis?.title || displayName}
        className="pt-0"
        titleRenderer={cardListItemProps.titleRenderer}
        defaultIsOpen
      >
        {buyer && buyer.hierarchy?.length && (
          <div className="space-y-4">
            <EntityTitle
              data-test="opp-details-display-name"
              title={buyer.hierarchy.join(', ')}
              subtitle={domains?.[0]}
              icon={
                <ImageWithFallback
                  src={`https://logo.clearbit.com/${domains?.[0]}`}
                  className="h-8 w-8 object-contain shrink-0 rounded-sm"
                  loading="lazy"
                >
                  <Icon icon="office" />
                </ImageWithFallback>
              }
            />
          </div>
        )}
      </CardListItem>

      <OppDetailsAnalysis analysis={analysis} isForecasted={!!isForecasted} />
      <OppDetailsDescription
        description={latestData?.description}
        isForecasted={!!isForecasted}
        hasAnalysis={!!analysis.title}
      />

      {children}
    </CardList>
  );
};
