import { DateRangeShortcut } from '@blueprintjs/datetime';
import {
  addWeeks,
  addMonths,
  addYears,
  subWeeks,
  subMonths,
  subYears,
  startOfDay,
  endOfDay,
  isSameDay,
  parseISO,
  format,
  addDays,
  subDays,
  isPast,
  differenceInMinutes,
  differenceInBusinessDays,
  differenceInCalendarDays,
  formatDistanceToNowStrict
} from 'date-fns';

import { Intent } from '@blueprintjs/core';

export type ISODateString = string;

export const MONTH_WITH_YEAR = 'MMMM yyyy';

export const DATETIME_24_SHORT_WITH_SECONDS = 'MM/dd/yy at HH:mm:ss';
export const DATETIME_24_SHORT = "MM/dd/yy 'at' HH:mm";
export const DATETIME_24_SHORT_WITH_TIMEZONE = "MM/dd/yy 'at' HH:mm O";
export const DATE_SHORT = 'P';
export const TIME_HOUR_AND_MINUTE = 'HH:mm';
export const ACTIVITY_LOG = "MMMM dd 'at' HH:mm";

const now = () => new Date();
export const isoIsPast = (date: ISODateString) => isPast(parseISO(date));
export const isoIsFuture = (date: ISODateString) => !isoIsPast(date);
export const todayStart = () => startOfDay(now());
export const todayEnd = () => endOfDay(now());

export const tomorrowStart = () => startOfDay(addDays(todayStart(), 1));
export const tomorrowEnd = () => endOfDay(tomorrowStart());

export const oneWeekAgo = () => subWeeks(todayStart(), 1);
export const oneMonthAgo = () => subMonths(todayStart(), 1);
export const sixMonthsAgo = () => subMonths(todayStart(), 6);
export const tenYearsAgo = () => subYears(todayStart(), 10);

export const oneYearFromNow = () => addYears(todayEnd(), 1);
export const tenYearsFromNow = () => addYears(todayEnd(), 10);

export const yesterdayStart = () => startOfDay(subDays(todayStart(), 1));
export const yesterdayEnd = () => endOfDay(yesterdayStart());

export const oneYearAgo = () => subYears(todayStart(), 1);
export const twoYearsAgo = () => subYears(todayStart(), 2);
export const fiveYearsAgo = () => subYears(todayStart(), 5);

export const oneWeekFromNow = () => addWeeks(todayEnd(), 1);
export const oneMonthFromNow = () => addMonths(todayEnd(), 1);
export const threeMonthsFromNow = () => addMonths(todayEnd(), 3);
export const sixMonthsFromNow = () => addMonths(todayEnd(), 6);
export const twoYearsFromNow = () => addYears(todayEnd(), 2);
export const fiveYearsFromNow = () => addYears(todayEnd(), 5);

export const futureDateShortcuts = (): DateRangeShortcut[] => {
  return [
    { label: 'Tomorrow', dateRange: [tomorrowStart(), tomorrowEnd()] },
    { label: 'Next 7 days', dateRange: [todayStart(), oneWeekFromNow()] },
    { label: 'Next month', dateRange: [todayStart(), oneMonthFromNow()] },
    { label: 'Next 6 months', dateRange: [todayStart(), sixMonthsFromNow()] },
    { label: 'Next 12 months', dateRange: [todayStart(), oneYearFromNow()] },
    { label: 'Next 2 years', dateRange: [todayStart(), twoYearsFromNow()] },
    { label: 'Next 5 years', dateRange: [todayStart(), fiveYearsFromNow()] }
  ];
};

export const pastDateShortcuts = (): DateRangeShortcut[] => {
  return [
    { label: 'Today', dateRange: [todayStart(), todayEnd()] },
    { label: 'Yesterday', dateRange: [yesterdayStart(), yesterdayEnd()] },
    { label: 'Past 7 days', dateRange: [oneWeekAgo(), todayEnd()] },
    { label: 'Past month', dateRange: [oneMonthAgo(), todayEnd()] },
    { label: 'Past 6 months', dateRange: [sixMonthsAgo(), todayEnd()] },
    { label: 'Past 12 months', dateRange: [oneYearAgo(), todayEnd()] },
    { label: 'Past 2 years', dateRange: [twoYearsAgo(), todayEnd()] },
    { label: 'Past 5 years', dateRange: [fiveYearsAgo(), todayEnd()] }
  ];
};

export const mixedDateShortcuts = (): DateRangeShortcut[] => {
  return [
    { label: 'Past 10 years', dateRange: [tenYearsAgo(), todayEnd()] },
    { label: 'Past 5 years', dateRange: [fiveYearsAgo(), todayEnd()] },
    { label: 'Past 12 months', dateRange: [oneYearAgo(), todayEnd()] },
    { label: 'Past week', dateRange: [oneMonthAgo(), todayEnd()] },
    { label: 'Today', dateRange: [todayStart(), oneMonthFromNow()] },
    { label: 'Next 3 months', dateRange: [todayStart(), threeMonthsFromNow()] },
    { label: 'Next 6 months', dateRange: [todayStart(), sixMonthsFromNow()] },
    { label: 'Next 12 months', dateRange: [todayStart(), oneYearFromNow()] },
    { label: 'Next 5 years', dateRange: [todayStart(), fiveYearsFromNow()] }
  ];
};

export const isToday = (isoString: string | number | Date) => {
  const date = typeof isoString === 'string' ? parseISO(isoString) : new Date(isoString);
  return isSameDay(date, new Date());
};

export const formatTime = (isoString: string | undefined, formatStr = 'P') => {
  if (isoString === undefined || isoString.length < 1) {
    return '';
  }

  const date = parseISO(isoString);
  try {
    return format(date, formatStr);
  } catch {
    return '';
  }
};

export const relativeToNow = (isoString: string) => {
  const time = now();
  const date = parseISO(isoString);
  const businessDays = differenceInBusinessDays(date, time);
  const totalDays = differenceInCalendarDays(date, time);
  const totalMinutes = differenceInMinutes(date, time);
  const relative = formatDistanceToNowStrict(date, { addSuffix: true, roundingMethod: 'floor' });

  return {
    date,
    relative,
    businessDays,
    totalDays,
    totalMinutes
  };
};

type ScheduledDateDecoration = {
  content: string;
  full: string;
  intent?: Intent;
  leftContent?: string;
  relative?: string;
  businesDays?: number;
  totalDays?: number;
  totalMinutes?: number;
  icon?: string;
  minimal?: boolean;
};

type ScheduledDateDecorationArgs = {
  dateString: ISODateString;
  field: 'respondBy' | 'forecastedAt' | 'cancelledAt' | 'awardedAt' | 'postedAt' | 'modifiedAt';
};

export const scheduledDateDecorator = ({ dateString, field }: ScheduledDateDecorationArgs): ScheduledDateDecoration => {
  const baseDecoration = relativeToNow(dateString);
  let decoration: ScheduledDateDecoration = {
    ...baseDecoration,
    full: formatTime(dateString, DATETIME_24_SHORT),
    intent: 'none',
    minimal: false,
    content: formatTime(dateString, DATETIME_24_SHORT)
  };

  switch (field) {
    case 'cancelledAt':
      decoration = {
        ...decoration,
        intent: 'danger',
        minimal: true,
        icon: 'disable',
        leftContent: 'Cancelled'
      };

      break;

    case 'awardedAt':
      decoration = {
        ...decoration,
        intent: 'success',
        minimal: true,
        icon: 'trophy',
        leftContent: 'Awarded'
      };
      break;

    case 'respondBy':
      if (baseDecoration.businessDays < 5 && baseDecoration.businessDays > -1) {
        const urgent = baseDecoration.totalDays < 3;
        decoration = {
          ...decoration,
          intent: urgent ? 'danger' : 'warning',
          minimal: !urgent,
          icon: urgent ? 'high-priority' : 'warning-sign',
          leftContent: 'Expires',
          content: decoration.relative || decoration.content
        };
      } else if (baseDecoration.totalDays > -30 && baseDecoration.totalDays < 1) {
        decoration = {
          ...decoration,
          intent: 'warning',
          icon: 'stopwatch',
          leftContent: 'Expired',
          content: decoration.relative || decoration.content
        };
      } else if (baseDecoration.totalDays <= -30) {
        decoration = {
          ...decoration,
          intent: 'danger',
          minimal: true,
          icon: 'stopwatch',
          leftContent: 'Expired',
          content: formatTime(dateString)
        };
      } else {
        decoration = {
          ...decoration,
          intent: 'success',
          minimal: true,
          icon: 'stopwatch',
          leftContent: 'Expires'
        };
      }
      break;

    case 'forecastedAt':
      decoration = {
        ...decoration,
        intent: baseDecoration.totalDays < 1 ? 'warning' : 'success',
        icon: 'clean',
        leftContent: 'Forecasted',
        minimal: true,
        content: formatTime(dateString, MONTH_WITH_YEAR)
      };

      break;

    case 'postedAt':
      decoration = {
        ...decoration,
        leftContent: 'Posted',
        minimal: true,
        content: baseDecoration.totalDays < -30 ? decoration.content : formatTime(dateString, DATETIME_24_SHORT)
      };

      break;

    case 'modifiedAt':
      decoration = {
        ...decoration,
        leftContent: 'Modified',
        minimal: true,
        content: baseDecoration.totalDays < -30 ? decoration.content : formatTime(dateString, DATETIME_24_SHORT)
      };

      break;
  }

  return decoration;
};
