import React, { Suspense, useEffect } from 'react';
import { RouterProvider } from 'react-router/dom';

import { useFeatureFlagsStore } from './hooks/useFeatureFlagsStore';
import { AppLoading } from '@/app/atoms/AppLoading/AppLoading';
import { router } from '@/app/routes';

export const App = () => {
  useEffect(() => {
    useFeatureFlagsStore.setState({ featureFlags: process.govlyEnv.featureFlags });
  }, []);

  return (
    <Suspense fallback={<AppLoading />}>
      <RouterProvider router={router} />
    </Suspense>
  );
};
