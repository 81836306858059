import { match, Pattern } from 'ts-pattern';
import { IconName } from '@blueprintjs/core';
import { DATETIME_24_SHORT, formatTime } from '../../lib/dates';
import { OrganizationLabelTaggingTimelineEvent } from '@/app/molecules/OrganizationLabelTaggingTimelineEvent/OrganizationLabelTaggingTimelineEvent';
import { ActivityExtended, CommentIndex, QuoteSourceShow } from '@/types/__generated__/GovlyApi';

export type TimelineEvent = {
  id?: string;
  key?: string;
  message: string;
  icon?: IconName;
  collapsible?: boolean;
  iconBgClass?: string;
  iconTextClass?: string;
  onClick?: () => void;
  formattedCreatedAt?: string;
};

type Event = CommentIndex | ActivityExtended | QuoteSourceShow;

export const getEventKey = (event: Event) => {
  return match(event)
    .with(
      { verb: 'update_workable', customData: { removedLabelIds: Pattern.not(Pattern.nullish) } },
      () => 'removedOppLabels'
    )
    .with(
      { verb: 'update_workable', customData: { addedLabelIds: Pattern.not(Pattern.nullish) } },
      () => 'addedOppLabels'
    )
    .with({ verb: 'update_workable' }, () => 'updatedWorkable')
    .with(
      { verb: 'update_workspace', customData: { removedLabelIds: Pattern.not(Pattern.nullish) } },
      () => 'removedWorkspaceLabels'
    )
    .with(
      { verb: 'update_workspace', customData: { addedLabelIds: Pattern.not(Pattern.nullish) } },
      () => 'addedWorkspaceLabels'
    )
    .with({ message: Pattern.string.includes('following') }, () => 'followed')
    .with({ message: Pattern.string.includes("to 'Awarded'") }, () => 'awarded')
    .with({ message: Pattern.string.includes("to 'Intend to Bid'") }, () => 'bidIntended')
    .with({ message: Pattern.string.includes("to 'Quoted'") }, () => 'quoted')
    .with({ message: Pattern.string.includes("to 'Submitted'") }, () => 'submitted')
    .with(
      {
        message: Pattern.union(Pattern.string.includes("to 'Not Awarded'"), Pattern.string.includes("to 'Not Bid'"))
      },
      () => 'noAwardNoBid'
    )
    .otherwise(() => 'default');
};

export const formatEvent = (event: ActivityExtended): TimelineEvent & Event => {
  const { createdAt, message } = event;

  const customEventProps = match<string, Partial<TimelineEvent>>(getEventKey(event))
    .with('removedOppLabels', 'addedOppLabels', () => ({
      icon: 'tag',
      iconBgClass: 'bg-blue-500',
      iconTextClass: 'text-white',
      component: OrganizationLabelTaggingTimelineEvent
    }))
    .with('updatedWorkable', () => ({
      icon: 'changes',
      iconBgClass: 'bg-blue-500',
      iconTextClass: 'text-white'
    }))
    .with('removedWorkspaceLabels', 'addedWorkspaceLabels', () => ({
      icon: 'tag',
      component: OrganizationLabelTaggingTimelineEvent
    }))
    .with('followed', () => ({
      icon: 'person'
    }))
    .with('awarded', () => ({
      icon: 'tick',
      iconBgClass: 'bg-green-500',
      iconTextClass: 'text-white'
    }))
    .with('bidIntended', () => ({
      icon: 'pin'
    }))
    .with('quoted', () => ({
      icon: 'label'
    }))
    .with('submitted', () => ({
      icon: 'clean'
    }))
    .with('noAwardNoBid', () => ({
      icon: 'cross',
      iconBgClass: 'bg-red-500',
      iconTextClass: 'text-white'
    }))
    .otherwise(() => ({
      icon: 'git-commit'
    }));

  return {
    ...event,
    collapsible: true,
    formattedCreatedAt: formatTime(createdAt, DATETIME_24_SHORT) ?? '',
    message: `${message} on ${formatTime(createdAt, DATETIME_24_SHORT)}`,
    ...customEventProps
  };
};

export const mergeActivities = ({ key, a, b }: { key: string; a: ActivityExtended; b: ActivityExtended }) => {
  return match(key)
    .with('removedOppLabels', 'addedOppLabels', 'removedWorkspaceLabels', 'addedWorkspaceLabels', () => {
      return {
        ...a,
        message: `${a.message} and ${b.message}`,
        customData: {
          ...a.customData,
          ...b.customData,
          removedLabelIds: [
            ...((a.customData.removedLabelIds as string[]) ?? []),
            ...((b.customData.removedLabelIds as string[]) ?? [])
          ],
          addedLabelIds: [
            ...((a.customData.addedLabelIds as string[]) ?? []),
            ...((b.customData.addedLabelIds as string[]) ?? [])
          ]
        }
      };
    })
    .otherwise(() => ({
      ...a,
      message: `${a.message} and ${b.message}`
    }));
};

const KEY_MATCHES = {
  removedOppLabels: ['addedOppLabels', 'removedOppLabels'],
  addedOppLabels: ['addedOppLabels', 'removedOppLabels'],
  removedWorkspaceLabels: ['addedWorkspaceLabels', 'removedWorkspaceLabels'],
  addedWorkspaceLabels: ['addedWorkspaceLabels', 'removedWorkspaceLabels']
};

const areKeysCompatible = (key1: string, key2: string) => {
  return KEY_MATCHES[key1 as keyof typeof KEY_MATCHES]
    ? KEY_MATCHES[key1 as keyof typeof KEY_MATCHES].includes(key2)
    : key1 === key2;
};

function activityIsExtended(activity: Event): activity is ActivityExtended {
  return activity.__typename === 'ActivityExtended';
}

export const groupMergeableActivities = (activities: Event[]) => {
  const { activities: groupedActivities, mergeCandidate } = activities.reduce(
    ({ activities, mergeCandidate }, activity) => {
      const key = getEventKey(activity);

      const isMergeable = Object.keys(KEY_MATCHES).includes(key);

      if (isMergeable && !mergeCandidate) {
        return { activities, mergeCandidate: { mergeKey: key, activity } };
      }

      if (
        isMergeable &&
        mergeCandidate &&
        areKeysCompatible(key, mergeCandidate.mergeKey) &&
        doCreatedAtsOverlap(activity, mergeCandidate.activity) &&
        activityIsExtended(activity) &&
        activityIsExtended(mergeCandidate.activity)
      ) {
        const merged = mergeActivities({ key, a: mergeCandidate.activity, b: activity });
        return { activities, mergeCandidate: { mergeKey: key, activity: merged } };
      }

      if (mergeCandidate?.activity) {
        activities.push(mergeCandidate.activity);
      }

      activities.push(activity);

      return { activities, mergeCandidate: undefined };
    },
    { activities: [], mergeCandidate: undefined } as {
      activities: Event[];
      mergeCandidate: { mergeKey: string; activity: Event } | undefined;
    }
  );

  const grouped = groupedActivities.concat(mergeCandidate ? [mergeCandidate.activity] : []);

  return grouped;
};

export const OVERLAP_THRESHOLD = 1000 * 60; // 1 minute

const doCreatedAtsOverlap = <T extends { createdAt: string }>(a: T, b: T) => {
  const createdAtDiff = new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime();
  const hasTimeOverlap = Math.abs(createdAtDiff) <= OVERLAP_THRESHOLD;
  return hasTimeOverlap;
};
