import React from 'react';
import { Tag } from '@blueprintjs/core';
import { Tagging } from '@/types/__generated__/GovlyApi';

export const OppSearchResultTags = ({ tags = [] }: { tags?: Tagging[] }) => {
  if (!tags?.length) return null;

  const groups = tags.reduce(
    (acc, { context, name }) => {
      if (!context || !name) return acc;

      acc[context] = acc[context] || [];
      acc[context].push(name);
      return acc;
    },
    { warning_tags: [] as string[], contract_tags: [] as string[] }
  );

  return (
    <>
      {groups['warning_tags']?.map(tag => (
        <Tag key={tag} minimal icon="warning-sign" intent="warning">
          {tag}
        </Tag>
      ))}
      {groups['contract_tags']?.map(tag => (
        <Tag key={tag} minimal>
          {tag}
        </Tag>
      ))}
    </>
  );
};
