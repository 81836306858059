import React from 'react';
import { CardListItem, CardListItemProps } from '@/app/molecules/CardList/CardList';
import { CardHeadingSmall } from '@/app/atoms/Typography/Typography';
import { useGetOppQuery } from '@/api/oppsApi';
import { OppDetailsDescriptionFieldsPrediction } from '@/app/organisms/OppDetailsDescriptionFieldsPrediction/OppDetailsDescriptionFieldsPrediction';
import { OppDetailsDescriptionFields } from '@/app/organisms/OppDetailsDescriptionFields/OppDetailsDescriptionFields';
import { OppDetailsOrganizationOppContext } from '@/app/organisms/OppDetailsOrganizationOppContext/OppDetailsOrganizationOppContext';

export type OppDetailsAttributesCardProps =
  | {
      id: string;
      title?: string;
    }
  | CardListItemProps;

export const OppDetailsAttributesCard = ({ id, title = 'Details', ...rest }: OppDetailsAttributesCardProps) => {
  const { data } = useGetOppQuery({ id: id ?? '' }, { skip: !id });

  if (!id || !data) return null;

  const body = data.isForecasted ? (
    <OppDetailsDescriptionFieldsPrediction opp={data} />
  ) : (
    <OppDetailsDescriptionFields opp={data} />
  );

  return (
    <CardListItem
      title={title}
      collapsible
      titleRenderer={CardHeadingSmall}
      cardSectionProps={{ padded: false }}
      {...rest}
    >
      {body}
      <OppDetailsOrganizationOppContext id={id} />
    </CardListItem>
  );
};
