import { useEffect, useRef } from 'react';

// eslint-disable-next-line @typescript-eslint/no-invalid-void-type
export function useEffectOnce(effect: () => void | (() => void)): void {
  const hasRun = useRef(false);

  useEffect(() => {
    if (hasRun.current) return;
    hasRun.current = true;
    return effect();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
}
