import React from 'react';
import { Classes, Popover, Tag, TagProps } from '@blueprintjs/core';
import { cn } from '@/app/lib/cn';
import { TeamAvatarWithName } from '@/app/molecules/TeamAvatarWithName/TeamAvatarWithName';
import { OrganizationTeam, OrganizationTeamWithOrganizationUsers } from '@/types/__generated__/GovlyApi';

type InputTeam =
  | OrganizationTeam
  | OrganizationTeamWithOrganizationUsers
  | { name: string; avatarColor: string; memberCount: number };

export type TeamTagProps = {
  team: InputTeam;
} & TagProps;

export const TeamTag = (props: TeamTagProps) => {
  const { team, className } = props;

  return (
    <Popover
      interactionKind="hover"
      popoverClassName={Classes.POPOVER_CONTENT_SIZING}
      placement="bottom"
      content={<TeamAvatarWithName team={team} size="lg" />}
      renderTarget={({ isOpen: _, ...targetProps }) => (
        <Tag {...props} {...targetProps} className={cn(className, targetProps.className)} minimal>
          {team.name}
        </Tag>
      )}
    />
  );
};
