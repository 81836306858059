import React, { useRef } from 'react';
import { FileViewer } from '@/app/molecules/FileViewer/FileViewer';
import { ToolboxDialog } from '@/app/organisms/OppWorkspacePageContents/ToolboxDialog';
import { OppWorkspaceQuoteDrawer } from '@/app/organisms/OppWorkspaceQuoteForm/OppWorkspaceQuoteDrawer';
import {
  createOppWorkspaceStore,
  OppWorkspaceStoreContext
} from '@/app/organisms/OppWorkspacePageContents/useOppWorkspaceStore';

export const OppWorkspaceStoreProvider = ({
  workspaceId,
  children
}: {
  workspaceId: string;
  children: React.ReactNode;
}) => {
  const store = useRef(createOppWorkspaceStore({ workspaceId })).current;
  const fileToView = store.use.fileToView();
  return (
    <OppWorkspaceStoreContext.Provider value={store}>
      {children}

      <OppWorkspaceQuoteDrawer />
      <ToolboxDialog />

      <FileViewer
        isOpen={!!fileToView?.link}
        downloadLink={fileToView?.link ?? ''}
        fileName={fileToView?.name ?? ''}
        fileUrl={fileToView?.link ?? ''}
        onClose={() => store.setState({ fileToView: undefined })}
      />
    </OppWorkspaceStoreContext.Provider>
  );
};
