import React from 'react';
import { Main } from '@/app/atoms/Main/Main';
import { ApplicationError } from '@/app/atoms/ErrorFallback/ApplicationError';

export const BrowserWarning = () => {
  return (
    <Main>
      <div className="col-span-8 col-start-3">
        <ApplicationError title="We don't support this browser.">
          <p className="mt-2 text-base text-gray-500">
            You are visiting this page because we detected an unsupported browser. Your browser does not support
            security features and/or updates that we require.
          </p>
          <p className="mt-2 text-base text-gray-500">
            We highly recommend that you update your browser to Chrome, Firefox, IE Edge, Safari or Opera.
          </p>
        </ApplicationError>
      </div>
    </Main>
  );
};
