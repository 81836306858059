import { useState, useRef, useLayoutEffect, useEffect } from 'react';

const ATTACHMENT_BATCH_DELAY = 1000;

export type OnAttachArgs = { name: string; signedId: string; id: string };

export const useOnBulkAttach = ({
  onBulkAttach
}: {
  onBulkAttach?: (attachments: OnAttachArgs[]) => Promise<void>;
}) => {
  const [attachmentBatch, setAttachmentBatch] = useState<OnAttachArgs[] | null>(null);

  const handleBulkAttachment = (attachment: OnAttachArgs) => {
    setAttachmentBatch(prev => [...(prev ?? []), attachment]);
  };

  const onBulkAttachRef = useRef(onBulkAttach);
  useLayoutEffect(() => {
    onBulkAttachRef.current = onBulkAttach;
  }, [onBulkAttach]);
  const isBulk = Boolean(onBulkAttach);

  useEffect(() => {
    const timeout = setTimeout(async () => {
      if (attachmentBatch) {
        await onBulkAttachRef.current?.(attachmentBatch);
        setAttachmentBatch(null);
      }
    }, ATTACHMENT_BATCH_DELAY);

    return () => clearTimeout(timeout);
  }, [attachmentBatch]);

  return {
    onBulkAttach: handleBulkAttachment,
    isBulk
  };
};
