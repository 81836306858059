import React from 'react';
import { CompoundTag, IconName, Intent, Tooltip } from '@blueprintjs/core';

import { scheduledDateDecorator } from '@/app/lib/dates';
import { cn } from '@/app/lib/cn';

type Props = {
  cancelledAt?: string;
  respondBy?: string;
  awardedAt?: string;
  postedAt?: string;
  modifiedAt?: string;
  large?: boolean;
  isForecasted?: boolean;
  className?: string;
  hideIntents?: Intent[];
};
export const ScheduledDateTag = ({
  cancelledAt,
  respondBy,
  awardedAt,
  postedAt,
  modifiedAt,
  className,
  large = false,
  isForecasted = false,
  hideIntents = []
}: Props) => {
  let dateString: string | undefined;
  let field: 'respondBy' | 'forecastedAt' | 'cancelledAt' | 'awardedAt' | 'postedAt' | 'modifiedAt' | undefined;

  if (cancelledAt) {
    dateString = cancelledAt;
    field = 'cancelledAt';
  } else if (isForecasted && respondBy) {
    dateString = respondBy;
    field = 'forecastedAt';
  } else if (awardedAt) {
    dateString = awardedAt;
    field = 'awardedAt';
  } else if (respondBy) {
    dateString = respondBy;
    field = 'respondBy';
  } else if (postedAt) {
    dateString = postedAt;
    field = 'postedAt';
  } else if (modifiedAt) {
    dateString = modifiedAt;
    field = 'modifiedAt';
  }

  const decoration = dateString && field ? scheduledDateDecorator({ dateString, field }) : undefined;
  const leftContent = decoration?.leftContent ?? 'Expiration';
  const date = decoration?.content ?? 'Unknown';
  const intent = decoration?.intent ?? 'warning';
  const icon = decoration?.icon ? (decoration.icon as IconName) : null;

  if (hideIntents.includes(intent)) return null;

  return (
    <Tooltip
      disabled={!dateString || isForecasted}
      className={cn(className, 'flex')}
      content={
        <div className="flex gap-x-2">
          <span>{decoration?.full}</span>
          {decoration?.relative !== date && <span>({decoration?.relative})</span>}
        </div>
      }
    >
      <CompoundTag
        data-testid={`scheduled-date-tag-${field}`}
        icon={icon}
        large={large}
        leftContent={leftContent}
        intent={intent}
        minimal={decoration?.minimal !== false}
      >
        {date}
      </CompoundTag>
    </Tooltip>
  );
};
