import React from 'react';
import { useParams } from 'react-router';

import { cn } from '@/app/lib/cn';
import { NewTabTooltip } from '@/app/molecules/NewTabTooltip/NewTabTooltip';
import { LinkTag } from '@/app/atoms/LinkTag/LinkTag';
import { useGetCurrentUserQuery } from '@/api/currentUserApi';
import { OppWorkspaceUpdateTags } from '@/app/molecules/OppWorkspaceUpdateTags/OppWorkspaceUpdateTags';
import { Activity } from '@/types/__generated__/GovlyApi';

type OppWorkspaceHeaderProps = {
  oppId: string;
  id?: string;
  name?: string;
  createdByOrganization?: string;
  organizationDefault?: boolean;
  showLink?: boolean;
  visibleId?: string;
  linkOpp?: boolean;
  updates?: Activity[];
  onClick?: (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void;
  path?: string;
  className?: string;
};

export const OppWorkspaceHeader = ({
  id,
  oppId,
  name,
  createdByOrganization,
  organizationDefault,
  showLink,
  visibleId,
  linkOpp,
  updates = [],
  onClick,
  path,
  className
}: OppWorkspaceHeaderProps) => {
  const { id: paramsId } = useParams();
  const hasUpdates = updates.length > 0;
  const { data: { organizationName: currentOrganizationName } = {} } = useGetCurrentUserQuery();
  const ownedByCurrentOrg = createdByOrganization === currentOrganizationName;

  let subheading = 'Workspace';
  if (!id || (organizationDefault && ownedByCurrentOrg)) subheading = 'Default Workspace';

  let link = `/opportunities/${oppId}/workspaces/${id}`;
  if (path) {
    link = path;
  }

  return (
    <div className={cn('trucate max-w-full w-64', className)} data-test="workspace-header-name">
      {id && showLink ? (
        <LinkTag
          target={!paramsId && !onClick ? '_blank' : undefined}
          {...(onClick ? { tag: 'a', onClick } : { to: link })}
        >
          {paramsId || onClick ? (
            <NewTabTooltip title="View Workspace">
              <dt className="whitespace-normal text-base font-semibold">{name || currentOrganizationName}</dt>
            </NewTabTooltip>
          ) : (
            <dt className="whitespace-normal text-base font-semibold">{name || currentOrganizationName}</dt>
          )}
        </LinkTag>
      ) : (
        <dt className="text-base font-semibold">{name || createdByOrganization || currentOrganizationName}</dt>
      )}
      {!hasUpdates ? (
        <dd className="text-sm text-gray-500">{subheading}</dd>
      ) : (
        <dd>
          <OppWorkspaceUpdateTags updates={updates} />
        </dd>
      )}
      {visibleId &&
        (linkOpp ? (
          <LinkTag to={`/opportunities/${oppId}`}>
            <NewTabTooltip title="View Opportunity">
              <dt className="text-sm font-semibold">{visibleId}</dt>
            </NewTabTooltip>
          </LinkTag>
        ) : (
          <dd className="text-sm text-gray-500">{visibleId}</dd>
        ))}
    </div>
  );
};
