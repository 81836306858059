import React from 'react';
import { HTMLTable } from '@blueprintjs/core';
import { flexRender } from '@tanstack/react-table';
import { GovlyTableThSortButton } from './GovlyTableThSortButton';
import { useGovlyTableContext } from '@/app/molecules/GovlyTable/GovlyTableContext';
import { cn } from '@/app/lib/cn';
import { GovlyTableTh } from '@/app/molecules/GovlyTable/GovlyTableTh';
import { GovlyTableThFilterInput } from '@/app/molecules/GovlyTable/GovlyTableThFilterInput';
import { getHeaderText } from '@/app/molecules/GovlyTable/utils';

export function GovlyTableBase<Data>() {
  const { table, isFixedLayout, striped, getRowProps } = useGovlyTableContext<Data>();
  const headerGroups = table.getHeaderGroups();
  const noHeaders = headerGroups.every(hg => hg.headers.every(h => (h.column.columnDef.header ?? '').length === 0));

  return (
    <HTMLTable className={cn('w-full', { 'table-fixed': isFixedLayout, '-mt-1': noHeaders })}>
      <thead>
        {noHeaders
          ? null
          : headerGroups.map(headerGroup => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map(header => {
                  const headerText = getHeaderText(header);
                  const { Th } = header.column.columnDef.meta?.Components ?? {};

                  return Th ? (
                    <Th key={header.id} header={header} />
                  ) : (
                    <GovlyTableTh key={header.id} header={header}>
                      <span className="space-y-2">
                        {header.column.getCanSort() ? (
                          <GovlyTableThSortButton header={header} />
                        ) : (
                          <span>{headerText}</span>
                        )}

                        {header.column.getCanFilter() ? <GovlyTableThFilterInput header={header} /> : null}
                      </span>
                    </GovlyTableTh>
                  );
                })}
              </tr>
            ))}
      </thead>

      <tbody className="divide-y-gray-200 dark:divide-y-gray-800 divide-y">
        {table.getRowModel().rows.map(row => {
          const { className, ...rowProps } = getRowProps?.(row) ?? {};

          return (
            <tr
              key={row.id}
              className={cn('border-b last:border-b-0 border-gray-200 dark:border-gray-800', className, {
                'even:bg-gray-50 odd:bg-white': striped
              })}
              {...rowProps}
            >
              {row.getVisibleCells().map(cell => (
                <td key={cell.id} className="align-middle p-3 first:pl-5 last:pr-5">
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </td>
              ))}
            </tr>
          );
        })}
      </tbody>
    </HTMLTable>
  );
}
