import React from 'react';
import { Contact, ContactProps } from '@/app/molecules/Contact/Contact';
import { Card, CardSection, CardProps } from '@/app/atoms/Card/Card';
import { CardHeadingSmall } from '@/app/atoms/Typography/Typography';

type ContactsCardProps = {
  title?: string;
  contacts?: ContactProps[];
  cardProps?: CardProps;
};

export const ContactsCard = ({ title = 'Contacts', contacts, cardProps }: ContactsCardProps) => {
  if (!contacts || !contacts.length) {
    return null;
  }

  return (
    <Card
      title={title}
      icon="person"
      titleRenderer={CardHeadingSmall}
      {...cardProps}
      collapsible
      collapseProps={cardProps?.collapseProps}
    >
      {contacts.map((contact, i) => (
        <CardSection key={`${contact.email}-${i}`}>
          <Contact {...contact} />
        </CardSection>
      ))}
    </Card>
  );
};
