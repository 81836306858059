import React from 'react';
import { Card, CardBody } from '@/app/atoms/Card/Card';
import { useCreateOppWorkspaceCommentMutation } from '@/api/oppWorkspaceCommentsApi';
import { OppWorkspaceCommentForm } from '@/app/organisms/OppWorkspaceComment/OppWorkspaceCommentForm';

type OppWorkspaceCommentCreateFormProps = {
  oppId: string;
  workspaceId: string;
};

export const OppWorkspaceCommentCreateForm = ({ oppId, workspaceId }: OppWorkspaceCommentCreateFormProps) => {
  const [createOppWorkspaceComment, { isLoading: isCreating }] = useCreateOppWorkspaceCommentMutation();

  return (
    <Card>
      <CardBody className="pt-2 relative">
        <OppWorkspaceCommentForm
          onSubmit={createOppWorkspaceComment}
          oppId={oppId}
          workspaceId={workspaceId}
          isLoading={isCreating}
        />
      </CardBody>
    </Card>
  );
};
