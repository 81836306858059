/**
 * Format a number as a currency string (e.g. $1,000.00)
 */
export const asCurrency = (n: string | number | null | undefined, options?: Intl.NumberFormatOptions) => {
  return Number(n).toLocaleString('en-US', {
    ...{
      style: 'currency',
      currency: 'USD'
    },
    ...options
  });
};

/**
 * Format a number as a compact currency string (e.g. 1.5K, 1.5M, 1.5B)
 */
export const asCurrencyCompact = (n: number | undefined) => {
  if (n === undefined) return '';

  return Intl.NumberFormat('en-US', {
    notation: 'compact',
    maximumFractionDigits: 1,
    currency: 'USD'
  }).format(n);
};

export const localeStringToNumber = (s: string) => {
  if (!s) return '';
  return Math.round(parseFloat(String(s).replace(/[^0-9.-]+/g, '')) * 100) / 100;
};

export const asPercentage = (n: number | undefined) => {
  if (n === undefined) return '';

  return Intl.NumberFormat('en-US', {
    style: 'percent',
    maximumFractionDigits: 2
  }).format(n);
};

/**
 * Rounds a number to the specified number of significant digits.
 * This includes whole numbers. E.g., 1000000001 -> 1000000000
 */
export const roundToSignificant = (num: number, significantDigits = 1) => {
  if (num === 0) return 0;

  const d = Math.ceil(Math.log10(num < 0 ? -num : num));
  const power = significantDigits - d;
  const magnitude = Math.pow(10, power);
  const shifted = Math.round(num * magnitude);
  return Math.floor(shifted / magnitude);
};
