import React, { useRef, useMemo, useLayoutEffect, useCallback } from 'react';
import { useAssistantThreadChannel } from './useAssistantThreadChannel';
import { createAssistantStore } from './useAssistantStore';
import {
  AssistantContext,
  AssistantContextType,
  AssistantTypeConfig,
  CreateThreadHook,
  ThreadHook
} from './assistantContext';
import { useEffectOnce } from '@/app/hooks/useEffectOnce';
import { ErrorBoundary } from '@/app/atoms/ErrorBoundary/ErrorBoundary';
import { CardError } from '@/app/atoms/ErrorFallback/CardError';

type AssistantProps<
  Config extends AssistantTypeConfig,
  Hook extends ThreadHook,
  Mutation extends CreateThreadHook
> = React.PropsWithChildren<
  Config &
    Pick<
      AssistantContextType<Config, Hook, Mutation>,
      | 'placeholder'
      | 'defaultPrompts'
      | 'welcomeMessageFactory'
      | 'useGetThreadQuery'
      | 'getThreadParams'
      | 'useCreateThreadMutation'
      | 'createThreadParams'
    >
>;

export * from './assistantContext';

function AssistantProvider<
  Config extends AssistantTypeConfig,
  Hook extends ThreadHook,
  Mutation extends CreateThreadHook
>({
  placeholder,
  defaultPrompts,
  children,
  welcomeMessageFactory,
  useCreateThreadMutation,
  createThreadParams,
  useGetThreadQuery,
  getThreadParams,
  ...rest
}: AssistantProps<Config, Hook, Mutation>) {
  const typeConfig = { ...rest } as unknown as Config;

  const [createThread, { isLoading: threadIsLoading }] = useCreateThreadMutation();

  const store = useRef(createAssistantStore({ welcomeMessage: welcomeMessageFactory?.standard() })).current;
  const threadId = store.use.threadId();
  useAssistantThreadChannel(store);

  const welcomeMessageFactoryRef = useRef(welcomeMessageFactory);
  const typeConfigRef = useRef(typeConfig);
  const createThreadParamsRef = useRef(createThreadParams);
  useLayoutEffect(() => {
    welcomeMessageFactoryRef.current = welcomeMessageFactory;
    typeConfigRef.current = typeConfig;
    createThreadParamsRef.current = createThreadParams;
  });

  useEffectOnce(() => {
    getNewThread();
  });

  const getNewThread = useCallback(async () => {
    try {
      const result = await createThread(createThreadParamsRef.current).unwrap();
      store.setState({ threadId: result.id });
    } catch (err) {
      console.error('Failed to create thread:', err);
    }
  }, [createThread, store]);

  const context = useMemo<AssistantContextType<Config, Hook, Mutation>>(() => {
    return {
      typeConfig: typeConfigRef.current,
      placeholder,
      defaultPrompts,
      isLoading: threadIsLoading,
      threadId,
      welcomeMessageFactory: welcomeMessageFactoryRef.current,
      store,
      useGetThreadQuery,
      getThreadParams,
      useCreateThreadMutation,
      createThreadParams: createThreadParamsRef.current
    };
  }, [
    useCreateThreadMutation,
    defaultPrompts,
    getThreadParams,
    placeholder,
    useGetThreadQuery,
    store,
    threadId,
    threadIsLoading
  ]);

  return <AssistantContext.Provider value={context}>{children}</AssistantContext.Provider>;
}

function AssistantProviderWithErrorBoundary<
  Config extends AssistantTypeConfig,
  Hook extends ThreadHook,
  Mutation extends CreateThreadHook
>(props: AssistantProps<Config, Hook, Mutation>) {
  return (
    <ErrorBoundary
      action={`${props.type} assistant`}
      fallback={<CardError title={`${props.type} assistant`} entity="assistant" />}
    >
      {/* @ts-expect-error not sure why but the props passing is throwing a fit */}
      <AssistantProvider {...props} />
    </ErrorBoundary>
  );
}

export { AssistantProviderWithErrorBoundary as AssistantProvider };
