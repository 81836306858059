import { rootApi } from './rootApi';

const api = rootApi.enhanceEndpoints({ addTagTypes: ['Chat'] });

type Citation = {
  generatedResponsePart: {
    textResponsePart: {
      text: string;
      span: {
        start: number;
        end: number;
      };
    };
  };
  retrievedReferences: {
    content: {
      text: string;
    };
    location: {
      type: 'S3';
      s3Location: {
        uri: string;
      };
    };
  };
};

type CreateChatResponse = {
  sessionId: string;
  output: {
    text: string;
  };
  citations: Citation[];
};

type CreateChat = {
  params: {
    entityIds?: string[];
    query: string;
    numberOfResults?: number;
    sessionId?: string;
    useCustomPrompt?: boolean;
  };
  result: CreateChatResponse;
};

export const chatsApi = api.injectEndpoints({
  endpoints: build => ({
    createChat: build.mutation<CreateChat['result'], CreateChat['params']>({
      query: body => ({
        url: '/v2/chats',
        method: 'POST',
        body
      }),
      invalidatesTags: ['Chat']
    })
  })
});

export const { useCreateChatMutation } = chatsApi;
