import React from 'react';
import { NonIdealState, NonIdealStateProps } from '@blueprintjs/core';
import { useGovlyTableContext } from '@/app/molecules/GovlyTable/GovlyTableContext';
import { cn } from '@/app/lib/cn';

export const GovlyTableEmptyState = ({ className, ...props }: NonIdealStateProps) => {
  const { table, isLoading } = useGovlyTableContext();
  return !isLoading && table.getRowCount() === 0 ? (
    <NonIdealState className={cn('py-8', className)} icon="heart-broken" title="No results" {...props} />
  ) : null;
};
