import React from 'react';
import { Button, MenuItem, Popover, Menu, ButtonProps } from '@blueprintjs/core';

import { QuoteSourceShow as QuoteSource } from '@/types/__generated__/GovlyApi';
import { useDeleteQuoteMutation } from '@/api/quotesApi';
import { useOppWorkspaceStoreRef } from '@/app/organisms/OppWorkspacePageContents/useOppWorkspaceStore';

type OppWorkspaceQuoteMenuProps = {
  quote: QuoteSource;
  buttonProps?: Partial<ButtonProps>;
};

export function OppWorkspaceQuoteMenu({ quote, buttonProps }: OppWorkspaceQuoteMenuProps) {
  const store = useOppWorkspaceStoreRef();

  const [deleteQuote, deleteQuoteMeta] = useDeleteQuoteMutation();

  const handleDelete = async () => {
    const res = await deleteQuote({ id: quote.id });
    if ('error' in res) {
      throw new Error("We're sorry, something went wrong. Please try again later.");
    }
    store.setState({ shouldPollQuotes: true });
  };

  return (
    <Popover
      interactionKind="click"
      className="max-w-md"
      content={
        <Menu key="menu">
          <MenuItem icon="eye-open" text="View in Browser" onClick={() => store.setState({ fileToView: quote.file })} />
          <MenuItem
            icon="import"
            text="Download"
            href={quote.file?.link}
            target="_blank"
            rel="noopener noreferrer nofollow"
          />
          <MenuItem icon="trash" text="Remove" onClick={handleDelete} />
        </Menu>
      }
    >
      <Button icon="more" loading={deleteQuoteMeta.isLoading} {...buttonProps} />
    </Popover>
  );
}
