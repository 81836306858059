import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from './baseQuery';

const isTest = process.env.NODE_ENV === 'test';

export const rootApi = createApi({
  reducerPath: 'api',
  keepUnusedDataFor: isTest ? 0 : 120,
  baseQuery,
  endpoints: () => ({})
});

export const { usePrefetch } = rootApi;
