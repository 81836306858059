import React from 'react';

import { Main } from '@/app/atoms/Main/Main';
import { MainColumn } from '@/app/atoms/MainColumn/MainColumn';
import { Loading } from '@/app/atoms/Loading/Loading';
import { useGetFeedQuery } from '@/api/feedsApi';
import { SearchableFeedsTableFed } from '@/app/organisms/SearchableFeeds/SearchableFeedsTableFed/SearchableFeedsTableFed';
import { DrawerType } from '@/app/organisms/AppDrawer/constants';

export const showSearchableFeedsFeedDrawer = ({ drawerType, drawerId }: { drawerType: DrawerType; drawerId: string }) =>
  ['/opportunities/searchable_feeds/fed'].includes(window.location.pathname) && drawerType === 'feed' && drawerId;

export type SearchableFeedsFeedDrawerTitleProps = {
  id: string;
};

export const SearchableFeedsFeedDrawerTitle = ({ id }: SearchableFeedsFeedDrawerTitleProps) => {
  const { data: result, isLoading } = useGetFeedQuery({ id });

  return (
    <div>
      {isLoading ? (
        <Loading type="flex-row" />
      ) : (
        <>
          <h2>{result?.name}</h2>
        </>
      )}
    </div>
  );
};

export const SearchableFeedsFeedDrawerBody = () => {
  return (
    <Main className="min-h-screen max-w-full bg-gray-50">
      <MainColumn columnSpan={12} className="order-1">
        <SearchableFeedsTableFed />
      </MainColumn>
    </Main>
  );
};
