import React from 'react';
import Markdown from 'react-markdown';

import { Callout, EntityTitle, Tag } from '@blueprintjs/core';
import { CardListItem, CardListItemProps } from '@/app/molecules/CardList/CardList';
import { CardHeadingSmall, CardSubheading } from '@/app/atoms/Typography/Typography';
import { LlmGeneratedIndicator } from '@/app/molecules/LlmGeneratedIndicator/LlmGeneratedIndicator';
import { LineItem } from '@/app/organisms/OppLineItemsTable/OppLineItemsTable';
import { asCurrency } from '@/app/lib/numbers';
import { OppLineItemsTable } from '@/app/organisms/OppLineItemsTable/OppLineItemsTable';
import { presence } from '@/app/lib/arrays';

type SummaryData = {
  title?: string;
  summary?: string;
  primaryOems?: string[];
  primaryProducts?: string[];
  primaryCompetitors?: string[];
  competingProducts?: string[];
  estimatedContractValue?: string[];
  productLineItems?: LineItem[];
  servicesLineItems?: LineItem[];
};

interface VendorWithWebsite {
  name: string;
  website: number;
}

type Vendor = VendorWithWebsite | string;

const cardListItemProps: CardListItemProps = {
  collapsible: true,
  compact: false,
  defaultIsOpen: false,
  titleRenderer: props => <CardHeadingSmall {...props} />,
  className: 'space-y-2 pt-0'
};

export const OppDetailsAnalysis = ({
  analysis = {},
  isForecasted
}: {
  analysis?: SummaryData;
  isForecasted?: boolean;
}) => {
  const {
    summary,
    primaryOems = [],
    primaryProducts = [],
    primaryCompetitors = [],
    competingProducts = [],
    estimatedContractValue = [],
    productLineItems = [],
    servicesLineItems = []
  } = analysis;

  if (isForecasted && !summary) {
    return;
  }

  if (!summary) {
    return (
      <CardListItem>
        <Callout intent="warning" icon="warning-sign" className="text-gray-900 font-bold">
          This opportunity is in queue for an AI-generated analysis
        </Callout>
      </CardListItem>
    );
  }

  return (
    <>
      <CardListItem
        {...cardListItemProps}
        icon={<LlmGeneratedIndicator icon="generate" />}
        title="AI Summary"
        subtitle={'Content in this section is generated with AI that considers all attachments and amendments.'}
        defaultIsOpen
      >
        <Markdown className="prose prose-sm py-2">{summary}</Markdown>
        <div className="flex gap-y-4 flex-col">
          {presence(primaryOems) && (
            <div className="flex flex-col gap-y-2">
              <EntityTitle heading={CardSubheading} title="Vendors" />
              <div className="flex flex-wrap items-center gap-x-2 gap-y-4">
                {primaryOems.map((vendor: Vendor) => (
                  <Tag key={typeof vendor === 'string' ? vendor : vendor.name} minimal intent="primary">
                    {typeof vendor === 'string' ? vendor : vendor.name}
                  </Tag>
                ))}
              </div>
            </div>
          )}
          {presence(primaryProducts) && (
            <div className="flex flex-col gap-y-2">
              <EntityTitle heading={CardSubheading} title="Products" />
              <div className="flex flex-wrap items-center gap-x-2 gap-y-4">
                {primaryProducts.map((product: string) => (
                  <Tag key={product} minimal>
                    {product}
                  </Tag>
                ))}
              </div>
            </div>
          )}
          {presence(primaryCompetitors) && (
            <div className="flex flex-col gap-y-2">
              <EntityTitle heading={CardSubheading} title="Competing Vendors" />
              <div className="flex flex-wrap items-center gap-x-2 gap-y-4">
                {primaryCompetitors.map((vendor: Vendor) => (
                  <Tag key={typeof vendor === 'string' ? vendor : vendor.name} minimal intent="primary">
                    {typeof vendor === 'string' ? vendor : vendor.name}
                  </Tag>
                ))}
              </div>
            </div>
          )}
          {presence(competingProducts) && (
            <div className="flex flex-col gap-y-2">
              <EntityTitle heading={CardSubheading} title="Competing Products" />
              <div className="flex flex-wrap items-center gap-x-2 gap-y-4">
                {competingProducts.map((product: string) => (
                  <Tag key={product} minimal>
                    {product}
                  </Tag>
                ))}
              </div>
            </div>
          )}
          {presence(estimatedContractValue) && (
            <div className="flex flex-col gap-y-2">
              <EntityTitle heading={CardSubheading} title="Estimated Contract Value" />
              <div className="flex flex-wrap items-center gap-x-2 gap-y-4">
                <Tag minimal intent="success">
                  {estimatedContractValue.map(value => asCurrency(value, {})).join('-')}
                </Tag>
              </div>
            </div>
          )}
        </div>
      </CardListItem>
      {presence(productLineItems) && <OppLineItemsTable data={productLineItems} title="Product Line Items" />}
      {presence(servicesLineItems) && <OppLineItemsTable data={servicesLineItems} title="Services Line Items" />}
    </>
  );
};
