import React, { useState, useEffect } from 'react';
import { Checkbox, CheckboxProps } from '@blueprintjs/core';

import { useToggleDeactivatedFeedsMutation } from '@/api/deactivatedFeedsApi';

import { successToast } from '@/app/lib/toaster';
import { cn } from '@/app/lib/cn';
import { useDeviceWidth } from '@/app/hooks/useDeviceWidth';

type SearchableFeedsTableFedCheckboxProps = Omit<CheckboxProps, 'onChange'> & {
  activated?: boolean;
  partnerFeedId: string;
};

export const SearchableFeedsTableFedCheckbox = ({
  activated = false,
  partnerFeedId,
  ...rest
}: SearchableFeedsTableFedCheckboxProps) => {
  const { isMobile } = useDeviceWidth();
  const [value, setValue] = useState(activated);
  const [toggleDeactivatedFeed, { isLoading }] = useToggleDeactivatedFeedsMutation();

  useEffect(() => {
    setValue(!!activated);
  }, [activated]);

  const label = isMobile ? 'Active?' : 'Active in search dashboard?';

  const toggleActivated = async ({ activated, partnerFeedId }: SearchableFeedsTableFedCheckboxProps) => {
    await toggleDeactivatedFeed({ partnerFeedIds: [partnerFeedId], active: !activated });
    successToast();
  };

  return (
    <Checkbox
      checked={!!value}
      labelElement={<span className={cn('inline-block text-gray-600 dark:text-gray-400')}>{label}</span>}
      className="m-0"
      disabled={isLoading}
      onChange={e => {
        setValue(e.target.checked);

        toggleActivated({ activated, partnerFeedId });
      }}
      {...rest}
    />
  );
};
