import React from 'react';

import { cn } from '@/app/lib/cn';

const spans = {
  12: 'lg:col-span-12',
  10: 'lg:col-span-10',
  9: 'lg:col-span-9',
  8: 'lg:col-span-8',
  7: 'lg:col-span-7',
  6: 'lg:col-span-6',
  5: 'lg:col-span-5',
  4: 'lg:col-span-4',
  3: 'lg:col-span-3',
  2: 'lg:col-span-2',
  0: 'hidden'
};

export type ColumnSpan = 0 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 12;

type MainColumnProps = React.PropsWithChildren<{
  columnSpan?: ColumnSpan;
  mobileMargin?: boolean;
  className?: string;
  tag?: 'aside' | 'section';
  style?: React.CSSProperties;
}>;

export const MainColumn = ({
  columnSpan = 12,
  className,
  tag,
  mobileMargin = false,
  style,
  children,
  ...rest
}: MainColumnProps) => {
  const Tag = (tag ?? columnSpan < 6) ? 'aside' : 'section';
  const classes = cn(
    'col-span-12 space-y-8 divide-transparent',
    { 'mx-4 sm:mx-6 lg:mx-0': mobileMargin },
    spans[columnSpan],
    className
  );
  return (
    <Tag className={classes} style={style} {...rest}>
      {children}
    </Tag>
  );
};
