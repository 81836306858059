import React from 'react';
import { Formik, Form } from 'formik';
import { Dialog, Button, AnchorButton } from '@blueprintjs/core';

import { errorToast } from '@/app/lib/toaster';
import { Card, CardBody, CardFooter } from '@/app/atoms/Card/Card';
import { LabelTagMultiSelectInput } from '@/app/organisms/inputs/LabelTagMultiSelectInput';
import { useUpdateOrganizationLabelTaggingsMutation } from '@/api/organizationLabelTaggingsApi';
import { useEventTracking } from '@/app/hooks/useEventTracking';
import { toLowerCase } from '@/app/lib/strings';

type LabelFormDialogProps = {
  onClose: () => void;
  isOpen: boolean;
  afterSubmit?: (labelIds: string[]) => void;
  cacheTags?: { type: 'Opp'; id: string }[];
  initialValues: {
    organizationLabelIds: string[];
    taggableId: string;
    taggableType: 'Opp' | 'Workspace' | 'Contact' | 'USASpendingAward';
  };
};

export const LabelFormDialog = ({
  isOpen,
  onClose,
  initialValues,
  afterSubmit,
  cacheTags = []
}: LabelFormDialogProps) => {
  const { trackEvent } = useEventTracking();

  const [onSubmit, { isLoading: isSubmitting }] = useUpdateOrganizationLabelTaggingsMutation();

  return (
    <Dialog className="p-0" isOpen={isOpen} onClose={onClose} lazy>
      <Formik
        initialValues={initialValues}
        onSubmit={async values => {
          try {
            await onSubmit({ ...values, cacheTags });
            afterSubmit?.(values.organizationLabelIds);

            trackEvent({
              object: toLowerCase(values.taggableType),
              action: 'label_added',
              properties: { type: values.taggableType, id: values.taggableId, labelIds: values.organizationLabelIds }
            });
            onClose();
          } catch (e) {
            errorToast(e);
          }
        }}
      >
        <Form>
          <Card title="Add Labels" rightElement={<Button minimal icon="cross" onClick={onClose} />}>
            <CardBody>
              <LabelTagMultiSelectInput
                className="m-0 p-0"
                name="organizationLabelIds"
                helperText={
                  <AnchorButton
                    href="/settings/labels"
                    target="_blank"
                    rel="noreferrer"
                    minimal
                    intent="primary"
                    className="mt-2"
                    small
                    icon="edit"
                  >
                    Configure labels for your organizations
                  </AnchorButton>
                }
              />
            </CardBody>
            <CardFooter>
              <Button large type="submit" text="Save" intent="primary" loading={isSubmitting} />
              <Button large text="Cancel" disabled={isSubmitting} onClick={onClose} />
            </CardFooter>
          </Card>
        </Form>
      </Formik>
    </Dialog>
  );
};
