// https://zustand.docs.pmnd.rs/guides/auto-generating-selectors

import { StoreApi, UseBoundStore, useStore } from 'zustand';

type ExtractState<S> = S extends { getState: () => infer T } ? T : never;

// https://zustand.docs.pmnd.rs/guides/auto-generating-selectors#create-the-following-function:-createselectors
type WithSelectors<S> = S extends { getState: () => infer T } ? S & { use: { [K in keyof T]: () => T[K] } } : never;

export const createSelectors = <S extends UseBoundStore<StoreApi<object>>>(_store: S) => {
  const store = _store as WithSelectors<typeof _store>;
  store.use = {};
  for (const k of Object.keys(store.getState())) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (store.use as any)[k] = () => store(s => s[k as keyof typeof s]);
  }

  return store;
};

// https://zustand.docs.pmnd.rs/guides/auto-generating-selectors#vanilla-store
type WithStoreSelectors<S> = S extends { getState: () => infer T }
  ? S & {
      /**
       * Auto-generates selectors for state values
       */
      use: { [K in keyof T]: () => T[K] };
      useStore: <Result>(selector: (state: ExtractState<S>) => Result) => Result;
    }
  : never;

export const createStoreSelectors = <S extends StoreApi<object>>(_store: S) => {
  const store = _store as WithStoreSelectors<typeof _store>;

  // eslint-disable-next-line react-hooks/rules-of-hooks
  store.useStore = <R>(selector: (state: ExtractState<S>) => R) => useStore(_store, selector);

  /**
   * Auto-generates selectors for state values
   */
  store.use = {};
  for (const k of Object.keys(store.getState())) {
    // It's okay to useStore in the callback because it will be used within react components
    // eslint-disable-next-line @typescript-eslint/no-explicit-any, react-hooks/rules-of-hooks
    (store.use as any)[k] = () => useStore(_store, s => s[k as keyof typeof s]);
  }

  return store;
};

export const identity = <T>(x: T) => x;
