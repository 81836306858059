import React from 'react';
import { Formik, Form, FormikConfig } from 'formik';
import { Button } from '@blueprintjs/core';
import { partition, keyBy } from 'lodash-es';

import { H3 } from '@/app/atoms/Typography/Typography';
import { Card, CardBody, CardFooter } from '@/app/atoms/Card/Card';
import { formErrorToast, successToast } from '@/app/lib/toaster';
import { useGetOrganizationUsersQuery } from '@/api/organizationUsersApi';
import { FollowerSelectInput } from '@/app/organisms/FollowerSelectInput/FollowerSelectInput';
import { useCurrentUserAttribute } from '@/api/currentUserApi';
import { WorkspaceFormNotificationsInput } from '@/app/organisms/WorkspaceFormNotificationsInput/WorkspaceFormNotificationsInput';
import { useEventTracking } from '@/app/hooks/useEventTracking';
import { OrganizationUserDisplayable, OrganizationUserCurrentOrg } from '@/types/__generated__/GovlyApi';
import { useCreateWorkspaceMutation, useUpdateWorkspaceMutation } from '@/api/workspacesApi';
import { hasId } from '@/api/utils';
import { LimitedFollow } from '@/app/organisms/WorkspaceForm/useWorkspaceForm';

type FollowsAttributes = Omit<LimitedFollow, 'organizationUser'> & { email?: string };

type InitialValues = {
  id?: string;
  workableType: 'USASpendingAward' | 'Contact' | 'Opp';
  workableId?: string;
  organizationDefault: boolean;
};

type FormValues = InitialValues & {
  teamFollows: LimitedFollow[];
  partnerFollows: LimitedFollow[];
  customNotifyList?: LimitedFollow[];
  notifyGroup: 'notifiables' | 'nobody' | 'select';
  followsAttributes: FollowsAttributes[];
};

type WorkableFormProps = {
  title?: string;
  onCancel: () => void;
  follows: LimitedFollow[];
  initialValues: InitialValues;
  workableType: 'USASpendingAward' | 'Contact' | 'Opp';
  notifyNewFollowersOnly?: boolean;
  shouldTrack?: boolean;
  enableReinitialize?: boolean;
};

export const WorkableForm = ({
  title,
  onCancel,
  follows = [],
  initialValues,
  workableType,
  notifyNewFollowersOnly = true,
  shouldTrack = true,
  enableReinitialize = false,
  ...rest
}: WorkableFormProps) => {
  const currentOrgId = useCurrentUserAttribute('organizationId');
  const { trackEvent } = useEventTracking();
  const [updateWorkspace, { isLoading: isUpdating }] = useUpdateWorkspaceMutation();
  const [createWorkspace, { isLoading: isCreating }] = useCreateWorkspaceMutation();

  const { data: teamData, isLoading: teamLoading } = useGetOrganizationUsersQuery({
    view: 'current_org'
  });
  const team = teamData as OrganizationUserCurrentOrg[];

  const { data: partnersData = [], isLoading: partnersLoading } = useGetOrganizationUsersQuery({
    filter: 'partners',
    view: 'displayable'
  });
  const partners = partnersData as OrganizationUserDisplayable[];

  const [teamFollows, partnerFollows] = partition(
    follows.filter(({ state }) => state === 'following'),
    ({ organizationUser }) => organizationUser?.organizationId === currentOrgId
  );

  const followsByUser = keyBy(
    follows.map(({ organizationUser: _, ...rest }) => rest),
    'organizationUserId'
  );

  const form: FormikConfig<FormValues> = {
    enableReinitialize,
    onSubmit: async ({ teamFollows, partnerFollows, customNotifyList, notifyGroup, ...payload }) => {
      try {
        const updatedFollowsByUser = keyBy(teamFollows.concat(partnerFollows), 'organizationUserId');

        const allFollowers = [...new Set(Object.keys(followsByUser).concat(Object.keys(updatedFollowsByUser)))];

        const payloadFollows = allFollowers.map(ouid => {
          const updatedFollow = updatedFollowsByUser[ouid];
          const originalFollow = followsByUser[ouid];

          if (originalFollow && !updatedFollow) {
            return {
              id: originalFollow.id,
              state: 'unfollowed' as const,
              notifications: 'muted' as const,
              organizationUserId: originalFollow.organizationUserId,
              email: undefined
            };
          }

          return {
            id: originalFollow?.id || updatedFollow.id,
            state: updatedFollow.state,
            notifications: updatedFollow.notifications,
            organizationUserId: updatedFollow.organizationUserId,
            email: updatedFollow.organizationUser?.email
          };
        });

        payload.followsAttributes = payloadFollows;

        let notifyIds: string[] = [];

        if (notifyGroup === 'select' && customNotifyList) {
          notifyIds = customNotifyList.map(({ organizationUserId }) => organizationUserId);
        }

        if (notifyGroup === 'notifiables') {
          let notifiables = [...teamFollows, ...partnerFollows];

          if (notifyNewFollowersOnly) {
            notifiables = notifiables.filter(({ id }) => !id);
          }

          notifyIds = notifiables.map(({ organizationUserId }) => organizationUserId);
        }

        const data = hasId(payload)
          ? await updateWorkspace({ ...payload, notifyIds }).unwrap()
          : await createWorkspace({ ...payload, notifyIds }).unwrap();

        const { followsAttributes, ...remainingData } = payload;
        if (shouldTrack)
          trackEvent({
            object: `${workableType?.toLowerCase() || 'opp'}_workspace` as Lowercase<string>,
            action: 'updated',
            properties: {
              workspaceId: remainingData.id,
              ...remainingData,
              ...data,
              notifyIds,
              follows: followsAttributes?.map(f => f.email),
              teamFollows: teamFollows?.map(f => f.organizationUser?.email),
              partnerFollows: partnerFollows?.map(f => f.organizationUser?.email)
            }
          });

        successToast('Saved Changes');
        onCancel();
      } catch (e) {
        formErrorToast(400);
      }
    },
    initialValues: {
      teamFollows,
      partnerFollows,
      ...initialValues,
      notifyGroup: 'notifiables',
      customNotifyList: [],
      followsAttributes: []
    }
  };

  return (
    <div {...rest}>
      <Formik {...form}>
        <Form>
          <Card title={title} rightElement={<Button minimal icon="cross" onClick={onCancel} />}>
            <CardBody>
              <div className="space-y-4">
                <H3>{`1. Which team members should be added as followers to this workspace?`}</H3>

                <FollowerSelectInput
                  name="teamFollows"
                  defaultButtonText="Add a team member"
                  organizationUsers={team || []}
                  disabled={teamLoading}
                  buttonProps={{ loading: teamLoading }}
                  data-test="workspace-followers-input"
                />
              </div>
              <div className="space-y-4">
                <H3>{`2. Which partners should be added as followers to this workspace?`}</H3>
                <FollowerSelectInput
                  name="partnerFollows"
                  defaultButtonText="Add a partner"
                  organizationUsers={partners || []}
                  disabled={partnersLoading}
                  buttonProps={{ loading: partnersLoading }}
                  data-test="workspace-partners-input"
                />
              </div>
              <WorkspaceFormNotificationsInput notifyNewFollowersOnly={notifyNewFollowersOnly} />
            </CardBody>
            <CardFooter>
              <Button
                large
                type="submit"
                text="Save"
                intent="primary"
                loading={isUpdating || isCreating}
                disabled={teamLoading || isUpdating || isCreating}
                data-test="workspace-submit-button"
              />
              <Button text="Cancel" disabled={isUpdating || isCreating} onClick={onCancel} />
            </CardFooter>
          </Card>
        </Form>
      </Formik>
    </div>
  );
};
