import { fetchBaseQuery } from '@reduxjs/toolkit/query';
import qs from 'qs';

const isTest = process.env.NODE_ENV === 'test';

export const baseQuery = fetchBaseQuery({
  // node env needs a full url
  baseUrl: isTest ? new URL('/api', location.origin).href : '/api',
  paramsSerializer: params => qs.stringify(params, { arrayFormat: 'brackets' })
});

export type BaseQueryFn = typeof baseQuery;
