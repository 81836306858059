import React from 'react';
import { Tag } from '@blueprintjs/core';
import { Card, CardSection } from '@/app/atoms/Card/Card';
import { CardHeadingSmall, CardSubheading } from '@/app/atoms/Typography/Typography';
import { presence } from '@/app/lib/arrays';

type LocationProps = {
  city?: string;
  country?: string;
  place?: string;
  postalCode?: string;
  region?: string;
  street?: string;
  type?: string;
  countryCode?: string;
  className?: string;
  label?: string;
};

export type LocationsCardProps = {
  title?: string;
  locations?: LocationProps[];
};

export const LocationsCard = ({ title = 'Locations', locations, ...rest }: LocationsCardProps) => {
  if (!presence(locations)) {
    return null;
  }

  return (
    <Card title={title} icon="map" titleRenderer={CardHeadingSmall} collapsible {...rest}>
      {locations.map(({ label, postalCode, type }, i) => (
        <CardSection key={`${postalCode}-${i}`} className="flex flex-col gap-y-2">
          <CardSubheading>{label}</CardSubheading>
          <div>{type && <Tag minimal>{type}</Tag>}</div>
        </CardSection>
      ))}
    </Card>
  );
};
