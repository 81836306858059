import React, { useMemo } from 'react';
import Markdown from 'react-markdown';
import { mkConfig, generateCsv, download } from 'export-to-csv';
import { Button } from '@blueprintjs/core';
import { Card, CardBody } from '@/app/atoms/Card/Card';
import { useGetGovernmentEntityQuery } from '@/api/governmentEntitiesApi';
import { Loading } from '@/app/atoms/Loading/Loading';
import { CardHeadingSmall } from '@/app/atoms/Typography/Typography';
import { LlmGeneratedIndicator } from '@/app/molecules/LlmGeneratedIndicator/LlmGeneratedIndicator';

interface BudgetItem {
  name: string;
  amount: string;
  description: string;
}

interface BudgetSummary {
  totalFunding: string;
  items: BudgetItem[];
}

const parseBudgetMarkdown = (markdown: string): BudgetSummary => {
  const items: BudgetItem[] = [];
  let totalFunding = '';

  const totalRegex = /\*\*Total funding requested:\s*(\$[\d,.]+ billion)\*\*/;
  const totalMatch = markdown.match(totalRegex);
  if (totalMatch) {
    totalFunding = totalMatch[1];
  }

  const itemRegex = /- \*\*(.*?)\s*\((\$[\d,.]+ (?:billion|million)\)):\*\*\s*(.*?)(?=(?:\n- \*\*|$))/gs;

  let match;
  while ((match = itemRegex.exec(markdown)) !== null) {
    items.push({
      name: match[1].trim(),
      amount: match[2].trim().replace(')', ''),
      description: match[3].trim()
    });
  }

  return {
    totalFunding,
    items
  };
};

const downloadAsCSV = (budget: BudgetSummary, fy?: string, governmentEntityName?: string) => {
  const csvConfig = mkConfig({
    fieldSeparator: ',',
    decimalSeparator: '.',
    useKeysAsHeaders: true,
    filename: `${governmentEntityName?.toLowerCase().replace(/\s+/g, '-')}-budget-${fy || 'summary'}`
  });

  const formattedData = [
    {
      title: `${governmentEntityName} Budget Summary`,
      amount: '',
      description: ''
    },
    {
      title: fy ? `Fiscal Year ${fy}` : '',
      amount: '',
      description: ''
    },
    {
      title: 'Total Funding Requested',
      amount: budget.totalFunding,
      description: ''
    },
    ...budget.items.map(item => ({
      title: item.name,
      amount: item.amount,
      description: item.description
    }))
  ];

  const csv = generateCsv(csvConfig)(formattedData);
  download(csvConfig)(csv);
};

export const BudgetSummary = ({ id }: { id: string }) => {
  const { data: governmentEntity, isLoading } = useGetGovernmentEntityQuery({ id: id ?? '' }, { skip: !id });
  const budget = useMemo(() => {
    if (!governmentEntity?.latestUpload?.summary?.text) return { totalFunding: '', items: [] };
    return parseBudgetMarkdown(governmentEntity.latestUpload.summary.text);
  }, [governmentEntity?.latestUpload?.summary?.text]);

  if (isLoading) return <Loading type="card" />;
  if (!governmentEntity || !governmentEntity?.latestUpload?.summary?.text) return null;

  const fy = governmentEntity.latestUpload.fiscalYear;

  return (
    <Card
      title={
        <span className="space-x-2">
          <LlmGeneratedIndicator icon="generate" />
          Projected Budget Summary{fy ? ` (FY${fy})` : ''}
        </span>
      }
      titleRenderer={CardHeadingSmall}
      collapsible={true}
    >
      <CardBody>
        <Markdown className="prose prose-sm">{governmentEntity.latestUpload.summary.text}</Markdown>
        {budget.items.length > 0 && (
          <Button small onClick={() => downloadAsCSV(budget, fy?.toString(), governmentEntity.name)}>
            Download as CSV
          </Button>
        )}
      </CardBody>
    </Card>
  );
};
