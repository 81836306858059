import { useCallback } from 'react';
import { P, match } from 'ts-pattern';
import { useGovlyTableContext } from '@/app/molecules/GovlyTable/GovlyTableContext';

const px = (n: number) => `${n}px`;

const percent = (n: number) => `${n * 100}%`;

export const useGetColumnWidth = () => {
  const { isFixedLayout, defaultColumnSize } = useGovlyTableContext();

  return useCallback(
    (size: number) =>
      match({ isFixedLayout, size })
        .with({ isFixedLayout: true, size: -1 }, () => px(defaultColumnSize))
        .with({ isFixedLayout: false, size: -1 }, () => 'auto')
        .with({ size: P.number.lt(1) }, ({ size }) => percent(size))
        .otherwise(({ size }) => px(size)),
    [defaultColumnSize, isFixedLayout]
  );
};
