import React, { useMemo } from 'react';
import { Tag } from '@blueprintjs/core';
import { createColumnHelper } from '@tanstack/react-table';
import { useSearchParams } from 'react-router';

import { SearchableFeedsTableFedCheckbox } from './SearchableFeedsTableFedCheckbox';
import { useGetPartnerFeedsQuery } from '@/api/partnerFeedsApi';
import { useGetCurrentOrganizationQuery } from '@/api/organizationsApi';
import { useGetDeactivatedFeedsQuery } from '@/api/deactivatedFeedsApi';
import { PartnerFeed } from '@/types/__generated__/GovlyApi';

import { DRAWER_PARAMS } from '@/app/organisms/AppDrawer/constants';
import { GovlyTable } from '@/app/molecules/GovlyTable/GovlyTable';
import { LinkTag } from '@/app/atoms/LinkTag/LinkTag';

type PartnerFeedExtended = PartnerFeed & { activated: boolean };

const columnHelper = createColumnHelper<PartnerFeedExtended>();

const columns = [
  columnHelper.accessor('name', {
    header: 'Feed Name',
    sortingFn: 'basic',
    filterFn: 'fuzzyText'
  }),
  columnHelper.accessor('providerName', {
    header: 'Partner',
    sortingFn: 'basic',
    filterFn: 'fuzzyText',
    cell: e =>
      e.row.original.partnershipPeerId ? (
        <LinkTag to={`/partnerships/${e.row.original.partnershipPeerId}`}>{e.row.original.providerName}</LinkTag>
      ) : (
        e.row.original.providerName
      )
  }),
  columnHelper.accessor('completionGroups', {
    header: 'Set-aside(s)',
    enableSorting: false,
    enableColumnFilter: false,
    cell: e =>
      e.row.original.completionGroups ? (
        <ul className="flex flex-col gap-1">
          {e.row.original.completionGroups.map((cg, i) => (
            <li key={i}>
              <Tag minimal intent="primary">
                {cg}
              </Tag>
            </li>
          ))}
        </ul>
      ) : (
        'NA'
      )
  }),
  columnHelper.accessor('activated', {
    header: 'Active?',
    enableSorting: false,
    enableColumnFilter: false,
    cell: e => {
      return (
        <div className="flex">
          <SearchableFeedsTableFedCheckbox activated={e.row.original.activated} partnerFeedId={e.row.original.id} />
        </div>
      );
    }
  })
];

export const SearchableFeedsTableFed = () => {
  const [searchParams] = useSearchParams();
  const feedId = searchParams.get(DRAWER_PARAMS.id);

  const deactivatedFeedsQuery = useGetDeactivatedFeedsQuery();
  const currentOrgQuery = useGetCurrentOrganizationQuery(undefined);
  const partnerFeedsQuery = useGetPartnerFeedsQuery(undefined, {
    selectFromResult: result => ({
      ...result,
      data: feedId ? result.data?.filter(acf => acf.feedId === feedId) : result.data
    })
  });
  const organizationId = currentOrgQuery.data?.id;

  const tableData = useMemo<PartnerFeedExtended[]>(() => {
    if (!organizationId) return [];
    if (partnerFeedsQuery.data?.length === 0) return [];

    const extendedPartnerFeeds =
      partnerFeedsQuery.data?.map(pf => {
        return {
          ...pf,
          activated: !deactivatedFeedsQuery.data?.some(df => df.partnerFeedId === pf.id)
        };
      }) ?? [];

    return extendedPartnerFeeds;
  }, [organizationId, partnerFeedsQuery.data, deactivatedFeedsQuery.data]);

  const queries = [partnerFeedsQuery, currentOrgQuery, deactivatedFeedsQuery];

  return (
    <GovlyTable
      id="searchable_feeds_table"
      columns={columns}
      data={tableData}
      isLoading={queries.some(q => q.isLoading)}
      loadingRowCount={5}
      title="Searchable Feeds"
      subtitle="A list of all the feeds you are able to search in Govly"
      emptyStateProps={{
        icon: 'warning-sign',
        title: 'No feeds configured',
        description: 'You are not receiving any feeds.'
      }}
    />
  );
};
