import ReactChartkick from 'react-chartkick';
import Highcharts from 'highcharts/es-modules/masters/highcharts.src.js';
import 'highcharts/es-modules/masters/modules/accessibility.src.js';

import { graphColors } from './colors';

const highchartsDefaultOptions: Highcharts.Options = {
  time: {
    useUTC: false
  },
  tooltip: {
    shared: true
  },
  colors: graphColors,
  credits: {
    enabled: false
  },
  lang: {
    thousandsSep: ',',
    numericSymbols: ['k', 'M', 'B', 'T']
  }
};

Highcharts.setOptions(highchartsDefaultOptions);

ReactChartkick.addAdapter(Highcharts);
