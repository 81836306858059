import { rootApi } from './rootApi';
import { WorkspaceColumnValue, WorkspaceWorklist } from '@/types/__generated__/GovlyApi';

const api = rootApi.enhanceEndpoints({
  addTagTypes: ['Worklist', 'Workspace', 'Opp', 'WorkspaceColumnValue', 'WorkspaceColumnValues']
});

export type GetWorklist = {
  params: {
    filter: 'active' | 'inactive';
    worklistOwnerId?: string;
  };
  result: WorkspaceWorklist[];
};

export type GetWorklistColumnValues = {
  params: GetWorklist['params'];
  result: WorkspaceColumnValue[];
};

export const worklistApi = api.injectEndpoints({
  endpoints: build => ({
    getWorklist: build.query<GetWorklist['result'], GetWorklist['params']>({
      query: params => ({ url: '/v2/worklist', params }),
      providesTags: result => [
        ...(result
          ? [
              ...result.map(({ id }) => ({ type: 'Workspace' as const, id })),
              ...result.map(({ workableId }) => ({ type: 'Opp' as const, id: workableId }))
            ]
          : []),
        'Worklist'
      ]
    }),
    getWorklistColumnValues: build.query<GetWorklistColumnValues['result'], GetWorklistColumnValues['params']>({
      query: params => ({ url: '/v2/worklist/workspace_column_values', params }),
      providesTags: result => [
        ...(result ? [...result.map(({ id }) => ({ type: 'WorkspaceColumnValue' as const, id }))] : []),
        'WorkspaceColumnValues'
      ]
    })
  })
});

export const { useGetWorklistQuery, useGetWorklistColumnValuesQuery } = worklistApi;
