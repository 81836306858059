export const reactionsWithDescriptions = {
  '👍': 'thumbs up',
  '👎': 'thumbs down',
  '✅': 'check mark',
  '🙌': 'nicely done',
  '👀': 'looking into it',
  '🎉': 'great!'
};

export const availableReactions = Object.keys(reactionsWithDescriptions) as Array<
  keyof typeof reactionsWithDescriptions
>;
