import React from 'react';
import { Icon } from '@blueprintjs/core';
import { cn } from '@/app/lib/cn';
import { TimelineEvent } from '@/app/organisms/OppWorkspaceTimeline/OppWorkspaceTimelineUtils';

type TimelineEventProps = TimelineEvent & {
  component?: React.ComponentType<{ event: TimelineEvent }>;
};

export const TimelineEventListItem = (event: TimelineEventProps) => {
  const {
    message,
    component: Component,
    icon = 'git-commit',
    iconBgClass = 'bg-gray-50',
    iconTextClass = 'text-gray-500',
    onClick
  } = event;

  return (
    <li className={cn('mb-6 ml-6 prose prose-sm', { 'cursor-pointer': !!onClick })} onClick={onClick}>
      <span
        className={cn(
          `absolute flex items-center justify-center w-6 h-6 rounded-full -left-3 ring-gray-50 dark:ring-gray-900 dark:bg-gray-900 ${iconBgClass}`
        )}
      >
        <Icon icon={icon} size={12} className={iconTextClass} />
      </span>
      <div className="block text-sm font-normal text-gray-600 dark:text-gray-500 leading-tight pt-[0.18rem]">
        {Component ? <Component event={event} /> : <span dangerouslySetInnerHTML={{ __html: message }} />}
      </div>
    </li>
  );
};
