import React from 'react';
import { DropzoneOptions, DropzoneInputProps, DropzoneState } from 'react-dropzone';

import { useActiveStorageDropzone, UseUploadArgs } from '@/app/hooks/useActiveStorageDropzone';

export type FileUploaderProps = {
  id: string;
  wrapperElement?: string;
  children?: React.ReactNode | ((props: Omit<DropzoneState, 'getRootProps' | 'getInputProps'>) => React.ReactNode);
  inputProps?: object;
  useUploadArgs?: Omit<DropzoneOptions, 'onError'>;
} & Omit<UseUploadArgs, 'uploaderId'> &
  Omit<DropzoneInputProps, 'children'>;

export const FileUploader = ({
  wrapperElement: Element = 'div',
  id,
  onInitialize,
  onAttach,
  onBulkAttach,
  children,
  inputProps,
  useUploadArgs,
  ...rest
}: FileUploaderProps) => {
  const { getRootProps, getInputProps, ...restProps } = useActiveStorageDropzone({
    uploaderId: id,
    onAttach,
    onInitialize,
    onBulkAttach,
    ...(useUploadArgs || {})
  });

  return (
    <Element {...getRootProps({ ...rest })} {...rest}>
      <input {...getInputProps()} {...(inputProps || {})} />
      {typeof children === 'function' ? children(restProps) : children}
    </Element>
  );
};
