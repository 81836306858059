import React from 'react';
import { Text as BPText, TextProps } from '@blueprintjs/core';

import { cn } from '@/app/lib/cn';

// NEW Goal: Decouple styles from semantic meaning
export const Text = BPText;

export const CardHeading = ({ children, className }: React.PropsWithChildren<{ className?: string }>) => (
  <Text tagName="h2" className={cn('text-xl font-semibold', className)}>
    {children}
  </Text>
);

export const CardHeadingSmall = ({ children, className }: React.PropsWithChildren<{ className?: string }>) => (
  <Text tagName="h3" className={cn('text-base font-semibold px-0', className)}>
    {children}
  </Text>
);

export const CardSubheading = ({ children, className }: React.PropsWithChildren<{ className?: string }>) => (
  <Text tagName="h4" className={cn('text-sm text-gray-600 font-medium', className)}>
    {children}
  </Text>
);

export const PageHeading = ({ children, className, ...rest }: React.PropsWithChildren<TextProps>) => (
  <Text tagName="h1" className={cn('text-2xl font-bold', className)} {...rest}>
    {children}
  </Text>
);

export const TopNavHeading = ({ className, ...props }: React.HTMLAttributes<HTMLHeadingElement>) => {
  return <Text tagName="h1" className={cn('text-lg font-bold dark:text-gray-100', className)} {...props} />;
};

// OLD
export const H1 = ({ children }: React.PropsWithChildren) => (
  <h1 className="text-2xl font-bold leading-normal text-gray-700 dark:text-gray-100 sm:truncate">{children}</h1>
);

export const H2 = ({ children }: React.PropsWithChildren) => (
  <h2 className="text-xl font-semibold leading-normal text-gray-700 dark:text-gray-100">{children}</h2>
);

export const H3 = ({ children, className }: React.PropsWithChildren<{ className?: string }>) => (
  <h3 className={cn('text-lg font-medium leading-normal text-gray-700 dark:text-gray-100', className)}>{children}</h3>
);
