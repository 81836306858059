import DOMPurify from 'dompurify';
import React from 'react';
import { cn } from '@/app/lib/cn';

import { Expander } from '@/app/atoms/Expander/Expander';
import { CardListItem } from '@/app/molecules/CardList/CardList';
import { CardHeadingSmall } from '@/app/atoms/Typography/Typography';

export const OppDetailsDescription = ({
  description,
  isForecasted = false,
  hasAnalysis = false,
  ...rest
}: {
  description?: string;
  isForecasted: boolean;
  hasAnalysis: boolean;
}) => {
  if (!description) return null;

  const expander = <OppDetailsDescriptionExpander description={description} />;

  if (!expander) {
    return null;
  }

  const onlyDescription = isForecasted || !hasAnalysis;
  const title = onlyDescription ? 'Description' : 'Original Description';

  return (
    <CardListItem
      titleRenderer={CardHeadingSmall}
      title={title}
      icon="document"
      defaultIsOpen={onlyDescription}
      collapsible
      {...rest}
    >
      {expander}
    </CardListItem>
  );
};

export const OppDetailsDescriptionExpander = ({ description }: { description: string }) => {
  const sanitized = DOMPurify.sanitize(description).replaceAll(/\n{3,}/g, '\n\n');

  if (!sanitized.length) {
    return null;
  }

  const hasHTML = /<\/?[a-z][\s\S]*>/i.test(sanitized);

  return (
    <Expander minHeight={500} className={cn('prose prose-sm', { 'whitespace-pre-line': !hasHTML })}>
      <div dangerouslySetInnerHTML={{ __html: sanitized }} className="*:mt-0" />
    </Expander>
  );
};
