import { create } from 'zustand';

export type Message = {
  message: string | React.ReactNode;
  user: 'bot' | 'user';
};

type Store = {
  messageHistory: { [key: string]: Message[] };
  sessionHistory: { [key: string]: string };
};

const useChatHistoryStore = create<Store>()(() => {
  return {
    messageHistory: {},
    sessionHistory: {}
  };
});

export const makeAddMessageToId = (id: string) => (message: Message) => {
  useChatHistoryStore.setState(state => ({
    messageHistory: {
      ...state.messageHistory,
      [id]: [...(state.messageHistory[id] || []), message]
    }
  }));
};

export const makeResetMessages = (id: string) => () => {
  useChatHistoryStore.setState(state => ({
    messageHistory: {
      ...state.messageHistory,
      [id]: []
    }
  }));
};

export const makeAddSessionToId = (id: string) => (sessionId: string) => {
  useChatHistoryStore.setState(state => ({
    sessionHistory: {
      ...state.sessionHistory,
      [id]: sessionId
    }
  }));
};

export const makeChatActions = (id: string) => ({
  addMessage: makeAddMessageToId(id),
  resetMessages: makeResetMessages(id),
  addSessionId: makeAddSessionToId(id)
});

export const getChatMessages = (id: string) => (store: Store) => store.messageHistory[id] || [];

export const getChatSessionId = (id: string) => (store: Store) => store.sessionHistory[id];

export const useChatStore = <T>(selector: (store: Store) => T) => {
  return useChatHistoryStore(selector);
};
