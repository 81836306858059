import { useLocation } from 'react-router';
import qs from 'qs';
import { useCallback, useMemo } from 'react';
import { useGetOrganizationsQuery } from '@/api/organizationsApi';
import { useGetCurrentUserQuery } from '@/api/currentUserApi';

export const useSwitchOrganization = () => {
  const { data: currentUser } = useGetCurrentUserQuery();
  const { data = [] } = useGetOrganizationsQuery(undefined, { skip: !currentUser?.multipleOrganizations });

  const { pathname } = useLocation() ?? {};

  const getSwitchUrl = useCallback(
    (organization: (typeof data)[number]) => {
      return `${pathname}?${qs.stringify({
        current_organization_id: organization.id
      })}`;
    },
    [pathname]
  );

  const onSwitch = useCallback(
    (organization: (typeof data)[number]) => {
      window.location.href = getSwitchUrl(organization);
    },
    [getSwitchUrl]
  );

  return useMemo(
    () => ({ onSwitch, getSwitchUrl, organizations: data, currentUser }),
    [currentUser, data, getSwitchUrl, onSwitch]
  );
};
