import React from 'react';

import { cn } from '@/app/lib/cn';

import { LinkTag, LinkTagProps } from '@/app/atoms/LinkTag/LinkTag';
import { Avatar, AvatarProps } from '@/app/molecules/Avatar/Avatar';
import { useDeviceWidth } from '@/app/hooks/useDeviceWidth';
import { OrganizationTeam } from '@/types/__generated__/GovlyApi';
import { TeamTagList } from '@/app/atoms/TeamTagList/TeamTagList';

const textSize: { [k in NonNullable<AvatarProps['size']>]?: Record<string, string> } = {
  '2xl': {
    name: 'text-2xl font-bold',
    email: 'text-lg'
  },
  xl: {
    name: 'text-xl font-bold'
  }
};

export type AvatarWithNameProps = {
  avatarColor?: string;
  linkProps?: LinkTagProps;
  id?: string;
  email?: string;
  name?: string;
  subtext?: string;
  teams?: OrganizationTeam[];
} & AvatarProps;

export const AvatarWithName = ({
  avatarColor,
  linkProps,
  email,
  name,
  teams,
  subtext,
  size = 'md',
  ...rest
}: AvatarWithNameProps) => {
  const { isMobile } = useDeviceWidth();

  let sizeForDevice = size;

  if (size === '2xl' && isMobile) {
    sizeForDevice = 'xl';
  }

  const makeSubtext = (text: string | undefined) => (
    <span className={cn('text-gray-500', textSize[sizeForDevice]?.email)} data-test="avatar-with-name-subtext">
      {text}
    </span>
  );

  const teamsList = teams && teams.length > 0 && (
    <TeamTagList className={cn('mt-1', textSize[sizeForDevice]?.email)} teams={teams} />
  );

  return (
    <div className="flex items-center gap-x-2">
      <Avatar size={sizeForDevice} className={avatarColor} {...rest} />
      <div>
        {linkProps ? (
          <LinkTag {...linkProps}>
            <div className={cn('font-medium', textSize[sizeForDevice]?.name)}>{name}</div>
          </LinkTag>
        ) : (
          <div className={cn('font-medium text-gray-900', textSize[sizeForDevice]?.name)}>{name}</div>
        )}
        {subtext ? (
          <>
            {teamsList}
            {makeSubtext(subtext)}
          </>
        ) : (
          <>
            {makeSubtext(email)}
            {teamsList}
          </>
        )}
      </div>
    </div>
  );
};
