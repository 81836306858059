export const initialFromName = (name: string) => {
  if (!name) {
    return '';
  }

  const rgx = new RegExp(/(\p{L}{1})\p{L}+/gu);
  const matches = [...name.matchAll(rgx)];

  const initials = ((matches.shift()?.[1] || '') + (matches.pop()?.[1] || '')).toUpperCase();

  return initials;
};
