import { TypedUseMutation, TypedUseQuery } from '@reduxjs/toolkit/query/react';
import { createContext, useContext } from 'react';
import { useShallow } from 'zustand/shallow';
import { BaseQueryFn } from '@/api/baseQuery';
import { AssistantStoreRef, getThreadMessages } from '@/app/organisms/Assistant/useAssistantStore';
import { LlmAssistantThreadShow } from '@/types/__generated__/GovlyApi';

export type AssistantTypeConfig = { type: 'keyword' } | { type: 'workspace'; workspaceId: string };

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type ThreadHook<Args = any> = TypedUseQuery<LlmAssistantThreadShow, Args, BaseQueryFn>;
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type CreateThreadHook<Args = any> = TypedUseMutation<LlmAssistantThreadShow, Args, BaseQueryFn>;

export type AssistantContextType<
  Config extends AssistantTypeConfig,
  Hook extends ThreadHook,
  Mutation extends CreateThreadHook
> = {
  store: AssistantStoreRef;
  isLoading: boolean;
  typeConfig: Config;
  useGetThreadQuery: Hook;
  getThreadParams: ({ threadId }: { threadId: string | null }) => Parameters<Hook>;
  useCreateThreadMutation: Mutation;
  createThreadParams: Parameters<ReturnType<Mutation>[0]>[0];
  placeholder?: string;
  defaultPrompts?: string[];
  welcomeMessageFactory: {
    standard: () => string;
    refresh: () => string;
  };
};

export const AssistantContext = createContext<
  AssistantContextType<AssistantTypeConfig, ThreadHook, CreateThreadHook> | undefined
>(undefined);

export const useAssistantContext = <Type extends AssistantTypeConfig['type']>() => {
  const context = useContext(AssistantContext);
  if (context === undefined) {
    throw new Error('useAssistantContext must be used within a AssistantProvider');
  }
  return context as AssistantContextType<Extract<AssistantTypeConfig, { type: Type }>, ThreadHook, CreateThreadHook>;
};

export const useAssistantTypeConfig = <Type extends AssistantTypeConfig['type']>(type: Type) => {
  const context = useAssistantContext<Type>();
  if (context.typeConfig.type !== type) {
    throw new Error('useTypedAssistantContext must be used within a AssistantProvider with the correct assistant type');
  }
  return context.typeConfig;
};

export const useAssistantStoreRef = () => {
  const { store } = useAssistantContext();
  return store;
};

export const useCurrentThreadId = () => {
  return useAssistantStoreRef().use.threadId();
};

export const useCurrentThreadMessages = () => {
  const threadId = useCurrentThreadId();
  const store = useAssistantStoreRef();
  return store.useStore(useShallow(s => getThreadMessages(s, threadId)));
};

export function useCurrentThreadQuery() {
  const threadId = useCurrentThreadId();
  const { useGetThreadQuery, getThreadParams } = useAssistantContext();
  return useGetThreadQuery(...getThreadParams({ threadId }));
}
