import React from 'react';
import { isEmpty, truncate } from 'lodash-es';
import { EntityTitle, Tag } from '@blueprintjs/core';

import { OppSearchResultTags } from '../OppSearchResultTags/OppSearchResultTags';
import { LinkTag } from '@/app/atoms/LinkTag/LinkTag';
import { ImageWithFallback } from '@/app/atoms/ImageWithFallback/ImageWithFallback';
import { formatTime, DATETIME_24_SHORT } from '@/app/lib/dates';
import { asCurrency } from '@/app/lib/numbers';
import { presence } from '@/app/lib/arrays';
import { OppShow } from '@/types/__generated__/GovlyApi';
import { CardSubheading } from '@/app/atoms/Typography/Typography';
import { CardSection } from '@/app/atoms/Card/Card';

export const OppDetailsDescriptionFields = ({ opp }: { opp: OppShow }) => {
  const {
    postedAt,
    awardedAt,
    cancelledAt,
    modifiedAt,
    modified,
    contractVehicle,
    oppAward,
    setAside,
    displayName,
    taggings,
    latestData: sourceData = {}
  } = opp;

  const publicLink = sourceData.externalUrl ?? sourceData.procurementPortalPublicUrl;

  const rows = [
    { label: 'Identifier', value: displayName },
    { label: 'Respond By', value: formatTime(sourceData.respondBy ?? '', DATETIME_24_SHORT) },
    { label: 'Posted At', value: formatTime(postedAt ?? '', DATETIME_24_SHORT) },
    { label: 'Awarded At', value: formatTime(awardedAt ?? '', DATETIME_24_SHORT) },
    { label: 'Awardee', value: oppAward?.awardeeName },
    { label: 'Award Amount', value: oppAward?.amount ? asCurrency(oppAward?.amount) : null },
    { label: 'Cancelled At', value: formatTime(cancelledAt ?? '', DATETIME_24_SHORT) },
    { label: 'Modified At', value: modified && formatTime(modifiedAt ?? '', DATETIME_24_SHORT) },
    {
      label: 'Contract Vehicle',
      value: contractVehicle?.contractName ? (
        <EntityTitle
          title={contractVehicle?.contractName}
          icon={
            contractVehicle?.logoDomain ? (
              <ImageWithFallback
                src={`https://logo.clearbit.com/${contractVehicle.logoDomain}`}
                className="h-8 w-8 object-contain shrink-0 rounded-sm"
                loading="lazy"
              />
            ) : undefined
          }
        />
      ) : null
    },
    { label: 'Question Deadline', value: formatTime(sourceData.questionDeadline ?? '', DATETIME_24_SHORT) },
    { label: 'Cancel Reason', value: sourceData.cancelReason },
    {
      label: 'Notice Type',
      value: presence(sourceData.noticeTypes) && (
        <div className="flex gap-x-2">
          {sourceData.noticeTypes.map(t => (
            <Tag key={t}>{t}</Tag>
          ))}
        </div>
      )
    },
    { label: 'Created By', value: sourceData.createdBy },
    { label: 'Set Aside', value: setAside },
    { label: 'NAICS Codes', value: sourceData.naicsCodes?.map(({ code }) => code).join(', ') },
    { label: 'Number of Recipients', value: sourceData.contractHolderCount },
    { label: 'Latest Modification Reason', value: sourceData.modificationReason },
    { label: 'Response Location', value: sourceData.responseLocation },
    { label: 'Delivery Instructions', value: sourceData.deliveryInstructions },
    {
      label: 'Public Link',
      value: publicLink && (
        <LinkTag target="_blank" rel="noreferrer" className="mt-1 text-sm" tag="a" href={publicLink}>
          {truncate(publicLink.replace(/^http?:\/\//, ''), { length: 45 })}
        </LinkTag>
      )
    },
    {
      label: 'Tags',
      value: !!taggings?.length && (
        <div className="flex flex-wrap items-center gap-x-2 gap-y-4">
          <OppSearchResultTags tags={taggings} />
        </div>
      )
    }
  ];

  return (
    <>
      {rows.map(
        ({ label, value }) =>
          !isEmpty(value) &&
          !isEmpty(label) && (
            <CardSection key={label} className="py-2">
              <div className="flex flex-wrap justify-between gap-y-2">
                <EntityTitle heading={CardSubheading} title={label} />
                {value}
              </div>
            </CardSection>
          )
      )}
    </>
  );
};
