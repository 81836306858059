import React from 'react';

import { cn } from '@/app/lib/cn';

type AvatarButtonProps = {
  ariaLabel: string;
  avatar: React.ReactNode;
};
export const AvatarButton = ({ ariaLabel, avatar }: AvatarButtonProps) => {
  const classes = cn(
    'rounded-full flex focus:outline-none focus:ring-2 focus:ring-offset-2',
    'bg-white focus:ring-gray-400 focus:ring-offset-white',
    'dark:bg-gray-700 dark:focus:ring-white dark:focus:ring-offset-gray-700'
  );
  return (
    <button className={classes}>
      <span className="sr-only">{ariaLabel}</span>
      {avatar}
    </button>
  );
};
