import React from 'react';
import { useSearchParams } from 'react-router';
import { useGetGovernmentEntityQuery } from '@/api/governmentEntitiesApi';
import { Breadcrumbs } from '@/app/molecules/Breadcrumbs/Breadcrumbs';
import { DRAWER_PARAMS } from '@/app/organisms/AppDrawer/constants';

export const GovernmentEntityBreadcrumbs = ({ id }: { id: string }) => {
  const { data: governmentEntity, isLoading } = useGetGovernmentEntityQuery({ id: id ?? '' }, { skip: !id });

  const [searchParams, setSearchParams] = useSearchParams();

  const inDrawer = searchParams.get(DRAWER_PARAMS.id);

  if (isLoading || !governmentEntity) return null;

  const breadcrumbs = inDrawer
    ? [
        { text: 'Agencies', to: '/budgets/agencies' },
        ...(governmentEntity.ancestors?.map(({ id, name }) => ({
          text: name,
          tag: 'button',
          onClick: () => setSearchParams({ ...Object.fromEntries(searchParams), drawerType: 'entity', drawerId: id })
        })) || []),
        {
          text: governmentEntity.name,
          tag: 'button',
          onClick: () =>
            setSearchParams({
              ...Object.fromEntries(searchParams),
              drawerType: 'entity',
              drawerId: governmentEntity.id
            })
        }
      ]
    : [
        { text: 'Agencies', to: '/budgets/agencies' },
        ...(governmentEntity.ancestors?.map(({ id, name }) => ({ text: name, to: `/budgets/agencies/${id}` })) || []),
        { text: governmentEntity.name, to: `/budgets/agencies/${governmentEntity.id}` }
      ];

  return <Breadcrumbs items={breadcrumbs} className="px-0" />;
};
