/**
 * Moves array item from one index to another
 */
export const moveItem = <T>(arr: T[], fromIndex: number, toIndex: number) => {
  if (arr.length < 2) return arr;
  const element = arr[fromIndex];
  arr.splice(fromIndex, 1);
  arr.splice(toIndex, 0, element);
  return arr;
};

export const presence = <T>(arr: T[] | undefined): arr is T[] => {
  return [arr].flat().some(val => {
    if (val) {
      if (Array.isArray(val)) {
        return val.length > 0;
      }

      return true;
    }

    return false;
  });
};
