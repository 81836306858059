import React from 'react';
import { Tag, TagProps } from '@blueprintjs/core';
import { useGovlyTableContext } from '@/app/molecules/GovlyTable/GovlyTableContext';

const MiniSpinner = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    width="12"
    height="12"
    className={'animate-spin'}
  >
    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4" />
    <path
      className="opacity-75"
      fill="currentColor"
      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
    />
  </svg>
);

export const GovlyTableRowCountTag = (props: Partial<TagProps>) => {
  const { table, isLoading } = useGovlyTableContext();
  return (
    <Tag minimal {...props}>
      {isLoading ? <MiniSpinner /> : table.getRowCount()}
    </Tag>
  );
};
