import {
  useReactTable,
  TableOptions,
  FilterFn,
  getCoreRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  getFilteredRowModel
} from '@tanstack/react-table';

import { matchSorter } from 'match-sorter';
import { useCallback, useMemo } from 'react';
import { DateTime } from 'luxon';

export type UseGovlyTableArgs<Data> = Pick<TableOptions<Data>, 'columns' | 'data'> &
  Partial<Omit<TableOptions<Data>, 'columns' | 'data'>>;

declare module '@tanstack/react-table' {
  export interface FilterFns {
    fuzzyText: FilterFn<unknown>;
    dateStringFilter: FilterFn<unknown>;
  }
}

export const useGovlyTable = <Data>({ columns, data, initialState, ...rest }: UseGovlyTableArgs<Data>) => {
  const fuzzyText = useCallback<FilterFn<Data>>((row, id, filterValue) => {
    return Boolean(matchSorter([row], filterValue, { keys: [row => row.getValue(id)] })[0]);
  }, []);

  const dateStringFilter = useCallback<FilterFn<Data>>((row, id, filterValue) => {
    const rowValue = row.getValue(id);

    if (!rowValue) {
      return true;
    }

    const str = DateTime.fromISO(rowValue as string).toLocaleString(DateTime.DATETIME_SHORT);

    return str.includes(filterValue);
  }, []);

  /**
   * Since we "hoist" common table args, we end up explicitly destructuring them as a named key,
   * and a { key: undefined } overrides the default set by the library.
   */
  const sanitizedOptions = useMemo(() => {
    return Object.fromEntries(Object.entries(rest).filter(([, value]) => value !== undefined));
  }, [rest]);

  const table = useReactTable<Data>({
    columns,
    data,
    filterFns: { fuzzyText, dateStringFilter },
    initialState: { ...initialState },
    getCoreRowModel: getCoreRowModel<Data>(),
    getSortedRowModel: getSortedRowModel<Data>(),
    getFilteredRowModel: getFilteredRowModel<Data>(),
    getPaginationRowModel: getPaginationRowModel<Data>(),
    // -1 to identify when we want to cast the width as "auto"
    defaultColumn: { size: -1, minSize: -1 },
    ...sanitizedOptions
  });

  return useMemo(() => {
    return { table };
  }, [table]);
};
