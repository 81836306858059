import { useEffect } from 'react';
import { useCurrentThreadMessages } from '@/app/organisms/Assistant/assistantContext';

export const useNewMessageScrollToBottom = ({ ref }: { ref: React.RefObject<HTMLDivElement> }) => {
  const messages = useCurrentThreadMessages();
  const text = messages.map(m => m.content).join('');

  // Ensure that as new messages are added, the messageField scrolls to the bottom
  useEffect(() => {
    if (ref.current) {
      ref.current.scrollTo({ top: ref.current.scrollHeight, behavior: 'smooth' });
    }
  }, [text, ref]);
};
